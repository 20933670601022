// @flow
import * as React from "react";
import Logo from "../../modules/style-guide/logo";
import {
  SpacerLg,
  SpacerSm,
  SpacerXLg
} from "../../modules/style-guide/spacer";
import { H1, Subtitle } from "../../modules/style-guide/text";
import Card from "../../modules/style-guide/card";
import LoadingBar from "../../modules/style-guide/loading-bar";
import styles from "./AnonymousLayout1.module.css";
import Support from "../../modules/support";

type Props = {|
  title: string,
  subtitle: string,
  children?: ?React.Node
|};

export const PageContent = (props: any) => (
  <div className={styles.content}>
    <Card className={styles.card}>{props.children}</Card>
  </div>
);

export const PageFooter = (props: any) => (
  <div className={styles.content}>{props.children}</div>
);

class AnonymousLayout1 extends React.Component<Props> {
  static renderSkeleton(progress: number, error: ?string) {
    return (
      <AnonymousLayout1 title="" subtitle="">
        <PageContent>
          {progress < 100 ? <LoadingBar progress={progress} /> : null}
          {error}
        </PageContent>
      </AnonymousLayout1>
    );
  }

  renderFooter() {
    const { children } = this.props;
    let footer = <PageFooter />;

    React.Children.forEach(children, child => {
      if (child.type === PageFooter) {
        footer = child;
      }
    });

    return footer;
  }

  renderContent() {
    const { children } = this.props;
    let content = <PageContent>Empty page..</PageContent>;

    React.Children.forEach(children, child => {
      if (child.type === PageContent) {
        content = child;
      }
    });

    return content;
  }

  render() {
    const { title, subtitle } = this.props;

    return (
      <div className={styles.page}>
        <Logo />
        <SpacerXLg />
        <div className={styles.contentContainer}>
          <div className={styles.center}>
            <H1>{title}</H1>
            <SpacerSm />
            <Subtitle>{subtitle}</Subtitle>
          </div>
          <SpacerLg />
          {this.renderContent()}
          <SpacerLg />
          {this.renderFooter()}
        </div>
        <Support />
      </div>
    );
  }
}

export default AnonymousLayout1;
