// @flow
import makeRequest from "../make-request";
import type { Task } from "../queue";

export type Attempt = {|
  id: string,
  ide_id: string,
  user_uuid: string,
  test_case_set_id: string,
  assignment_id: ?string,
  created_at: number,
  did_pass: ?boolean
|};

export type Result = {|
  id: string,
  result_set_id: string,
  label: string,
  order: number,
  did_pass: boolean,
  payload: ?{
    type: string
  }
|};

export type ResultSet = {|
  id: string,
  attempt_id: string,
  test_case_id: string,
  status: string,
  logs: string[],
  did_pass: boolean,
  results: Result[]
|};

export async function fetchUserAttempts(
  testCaseSetID: ?string
): Promise<Attempt[]> {
  const url = `/api/attempts?${
    testCaseSetID ? `test_case_set_id=${testCaseSetID}` : ""
  }`;

  return makeRequest(url);
}

export async function fetchResultSets(attemptID: string): Promise<ResultSet[]> {
  const url = `/api/attempts/${attemptID}/result-sets`;

  return makeRequest(url);
}

export async function fetchAssignmentAttempts(
  assignmentID: string
): Promise<Attempt[]> {
  const url = `/api/assignments/${assignmentID}/attempts`;

  return makeRequest(url);
}

export async function downloadSubmissions(assignmentID: string): Promise<Task> {
  const url = `/api/assignments/${assignmentID}/download-submissions`;

  return makeRequest(url, { method: "POST" });
}
