// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import { Spacer, SpacerSm } from "../../modules/style-guide/spacer";
import Input from "../../modules/style-guide/input";
import Button from "../../modules/style-guide/button";
import { Error, RunningText } from "../../modules/style-guide/text";
import styles from "./SignupVerify.module.css";

type Props = {|
  email: string,

  verifying: boolean,
  verifyError: ?string,

  resendingCode: boolean,
  resendCodeError: ?string,

  onVerify: (verificationCode: string) => any,
  onResendCode: () => any
|};

type State = {|
  verificationCode: string
|};

class SignupVerifyForm extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      verificationCode: ""
    };
  }

  handleVerify = () => {
    const { verifying, onVerify } = this.props;
    const { verificationCode } = this.state;

    if (verifying) {
      return;
    }

    onVerify(verificationCode);
  };

  handleResendCode = () => {
    const { resendingCode, onResendCode } = this.props;

    if (resendingCode) {
      return;
    }

    onResendCode();
  };

  handleVerificationCodeChange = (verificationCode: string) =>
    this.setState({ verificationCode });

  render() {
    const {
      email,
      verifying,
      verifyError,
      resendingCode,
      resendCodeError
    } = this.props;
    const { verificationCode } = this.state;

    return (
      <>
        <RunningText className={styles.center}>
          We have sent a verification code to your email
          <br />
          <strong>{email}</strong>.
        </RunningText>
        <Spacer />
        <Input
          label="Verification Code"
          value={verificationCode}
          onChange={this.handleVerificationCodeChange}
          onEnter={this.handleVerify}
        />
        <SpacerSm />
        <div className={styles.resendCode}>
          <Link to="#" onClick={this.handleResendCode}>
            {resendingCode
              ? "Resending Code.."
              : resendCodeError
              ? "Failed to resend code. Try again?"
              : "Resend Code"}
          </Link>
        </div>

        <Spacer />
        <Button onClick={this.handleVerify}>
          {verifying ? "Verifying.." : "Verify"}
        </Button>
        {verifyError ? (
          <div>
            <Spacer />
            <Error>{verifyError}</Error>
          </div>
        ) : null}
      </>
    );
  }
}

export default SignupVerifyForm;
