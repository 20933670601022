// @flow
import makeRequest from "../make-request";

export async function downloadBlob(blobPath: string): Promise<string> {
  const url = `/api/blobs/${blobPath}`;

  return makeRequest(url, {
    headers: {
      Range: "bytes=0-64000" // first 64 kb
    }
  });
}
