// @flow
import * as React from "react";
import styles from "./Table.module.css";

export type TableColumn = {|
  accessor: string,
  label?: string,
  left?: boolean,
  right?: boolean,
  compact?: boolean,
  highlightCell?: boolean,
  width?: string,
  subColumns?: TableColumn[]
|};

export type TableRow = {
  key: string,
  data: {
    [string]: string | React.Node
  },
  asciiData?: { [string]: string }
};

type Props = {|
  listBorder?: boolean,
  columns: TableColumn[],
  rows: TableRow[]
|};

class Table extends React.Component<Props> {
  renderSubCols() {
    const { columns } = this.props;
    const anyColHasSub =
      columns.filter(col => col.subColumns && col.subColumns.length).length > 0;

    if (!anyColHasSub) {
      return null;
    }

    let ths: React.Node[] = [];
    let colSpan = 0;

    for (let i = 0; i < columns.length; i++) {
      const { subColumns } = columns[i];

      if (!subColumns || !subColumns.length) {
        colSpan++;
        continue;
      }

      if (colSpan > 0) {
        ths.push(
          <th key={`empty-${i}`} className={styles.empty} colSpan={colSpan} />
        );
        colSpan = 0;
      }

      ths = ths.concat(
        subColumns.map((subCol, j) => (
          <th key={`${i}-${j}-${subCol.accessor}`}>{subCol.label}</th>
        ))
      );
    }

    if (colSpan > 0) {
      ths.push(
        <th key={`empty-last`} className={styles.empty} colSpan={colSpan} />
      );
    }

    return <tr>{ths}</tr>;
  }

  render() {
    const { rows, columns, listBorder } = this.props;
    const hasColLabels = Boolean(columns.find(col => col.label));

    return (
      <table
        className={`${styles.table} ${listBorder ? styles.listBorder : ""}`}
      >
        {hasColLabels ? (
          <thead>
            <tr>
              {columns.map((col, i) => (
                <th
                  key={`${i}-${col.accessor}`}
                  colSpan={col.subColumns ? col.subColumns.length : 1}
                  className={[
                    col.left ? styles.left : "",
                    col.right ? styles.right : "",
                    col.compact ? styles.compact : ""
                  ].join(" ")}
                  style={{ width: col.width || "auto" }}
                >
                  {col.label}
                </th>
              ))}
            </tr>
            {this.renderSubCols()}
          </thead>
        ) : null}
        <tbody>
          {rows.map((row, i) => {
            return (
              <tr key={`${i}-${row.key}`}>
                {columns.map((col, j) =>
                  !col.subColumns || !col.subColumns.length ? (
                    <td
                      key={`${i}-${j}`}
                      className={[
                        col.left ? styles.left : "",
                        col.right ? styles.right : "",
                        col.compact ? styles.compact : "",
                        col.highlightCell ? styles.highlight : ""
                      ].join(" ")}
                      style={{ width: col.width || "auto" }}
                    >
                      {row.data[col.accessor]}
                    </td>
                  ) : (
                    col.subColumns.map((subCol, k) => (
                      <td
                        key={`${i}-${j}-${k}`}
                        className={[
                          col.left ? styles.left : "",
                          col.right ? styles.right : "",
                          col.compact ? styles.compact : "",
                          col.highlightCell ? styles.highlight : ""
                        ].join(" ")}
                      >
                        {row.data[subCol.accessor]}
                      </td>
                    ))
                  )
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default Table;
