// @flow
import * as React from "react";
import { HelpIcon } from "../style-guide/icons";
import { Spacer, SpacerSm, SpacerXsm } from "../style-guide/spacer";
import StatefulModal from "../style-guide/modal/stateful";
import { Error, H3, Subtitle, Success } from "../style-guide/text";
import TextArea from "../style-guide/text-area";
import Button from "../style-guide/button";
import styles from "./Support.module.css";

import * as SupportAPI from "../../lib/api/support";
import AppDataCacheCtx from "../../lib/data-cache";

type Props = {|
  defaultProblem?: string,
  renderOpener?: (isOpen: boolean, open: () => any) => any
|};

type State = {|
  problem: string,
  sending: boolean,
  sendSuccess: string,
  sendError: ?string
|};

class Support extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      problem: this.props.defaultProblem || "",
      sending: false,
      sendSuccess: "",
      sendError: null
    };
  }

  handleSend = async () => {
    this.setState({ sending: true, sendSuccess: "", sendError: null });
    try {
      await SupportAPI.sendSupportRequest(this.state.problem);
      this.setState({
        problem: "",
        sending: false,
        sendSuccess: "Your support request has been sent!"
      });
    } catch (e) {
      this.setState({
        sending: false,
        sendError: "Failed to send support request"
      });
    }
  };

  handleProblemChange = (problem: string) => this.setState({ problem });

  renderOpener = (isOpen: boolean, open: () => any) => (
    <div className={styles.support} onClick={open}>
      <HelpIcon />
      <SpacerXsm horizontal />
      Support
    </div>
  );

  renderModal = () => {
    const { problem, sending, sendSuccess, sendError } = this.state;
    return (
      <div className={styles.centered}>
        <H3>Support</H3>
        <SpacerSm />
        <Subtitle>
          Tell us what you need help with and we'll reach out to you as soon as
          we can.
        </Subtitle>
        <Spacer />
        <TextArea
          label="How can we help"
          value={problem}
          onChange={this.handleProblemChange}
        />
        <Spacer />
        <Button small onClick={this.handleSend}>
          {sending ? "Sending.." : "Send"}
        </Button>
        <SpacerSm />
        <Error>{sendError}</Error>
        <Success>{sendSuccess}</Success>
      </div>
    );
  };

  render() {
    const { renderOpener } = this.props;
    return (
      <AppDataCacheCtx.Consumer>
        {({ cache }) => {
          if (!cache.session) {
            return null;
          }

          return (
            <StatefulModal
              renderOpener={renderOpener || this.renderOpener}
              renderModal={this.renderModal}
            />
          );
        }}
      </AppDataCacheCtx.Consumer>
    );
  }
}

export default Support;
