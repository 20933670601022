// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import { getSessionProfile } from "../../lib/session";
import { BadRequestError } from "../../lib/api/make-request";
import AppDataCacheCtx from "../../lib/data-cache";
import AnonymousLayout1, {
  PageContent,
  PageFooter
} from "../anonymous-layout-1";
import BaseDataPage from "../base-data";
import SignupVerifyForm from "./signup-verify-form";
import styles from "../signup/Signup.module.css";

import * as AuthAPI from "../../lib/api/auth";
import * as CoursesAPI from "../../lib/api/courses";

type Props = {||};

type State = {|
  verifying: boolean,
  verifyError: ?string,

  resendingCode: boolean,
  resendCodeError: ?string
|};

class SignupVerifyPage extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      verifying: false,
      verifyError: null,

      resendingCode: false,
      resendCodeError: null
    };
  }

  fetchData = async () => {};

  handleLogout = async () => {
    // $FlowFixMe: add defs for react-router
    const { history } = this.props;

    try {
      await AuthAPI.logout();
    } catch (e) {
    } finally {
      history.push("/login");
    }
  };

  handleVerify = (role: "INSTRUCTOR" | "STUDENT") => async (
    verificationCode: string
  ) => {
    // $FlowFixMe: add defs for react-router
    const { history } = this.props;

    try {
      this.setState({ verifying: true, verifyError: null });

      await AuthAPI.verify(verificationCode);

      if (role === "INSTRUCTOR") {
        await CoursesAPI.createCourse({
          name: "Demo Course",
          start_on: "2020-01-01",
          end_on: "2099-01-01",
          tier: "BASIC",
          payment_method: "NONE"
        });
      }

      if (role === "STUDENT") {
        history.push("/courses/enroll");
        return;
      }

      history.push("/signup/survey");
    } catch (e) {
      if (e instanceof BadRequestError) {
        this.setState({
          verifying: false,
          verifyError: "Invalid verification code"
        });
      } else {
        this.setState({
          verifying: false,
          verifyError: "Failed to verify email"
        });
      }
    }
  };

  handleResendCode = async () => {
    try {
      this.setState({ resendingCode: true, resendCodeError: null });

      await AuthAPI.resendVerificationCode();

      this.setState({ resendingCode: false });
    } catch (e) {
      this.setState({
        resendingCode: false,
        resendCodeError: "Failed to resend code"
      });
    }
  };

  render() {
    return (
      <BaseDataPage
        allowUnverified
        fetchData={this.fetchData}
        renderSkeleton={AnonymousLayout1.renderSkeleton}
      >
        <AppDataCacheCtx.Consumer>
          {({ cache }) => {
            const profile = getSessionProfile(cache);

            return (
              <AnonymousLayout1
                title="Welcome to AutoGradr"
                subtitle="Verify your email"
              >
                <PageContent>
                  <SignupVerifyForm
                    {...this.state}
                    email={profile.email}
                    onResendCode={this.handleResendCode}
                    onVerify={this.handleVerify(profile.role)}
                  />
                </PageContent>

                <PageFooter>
                  <div className={styles.center}>
                    <Link to="#" onClick={this.handleLogout}>
                      Logout
                    </Link>
                  </div>
                </PageFooter>
              </AnonymousLayout1>
            );
          }}
        </AppDataCacheCtx.Consumer>
      </BaseDataPage>
    );
  }
}

export default SignupVerifyPage;
