// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { keyBy, values } from "lodash";
import AppDataCacheCtx from "../../lib/data-cache";
import { H3, Subtitle } from "../../modules/style-guide/text";
import {
  Spacer,
  SpacerLg,
  SpacerSm,
  SpacerXsm
} from "../../modules/style-guide/spacer";
import Table from "../../modules/style-guide/table";
import {
  DeleteIcon,
  EditIcon,
  ListIcon
} from "../../modules/style-guide/icons";
import ConfirmAction from "../../modules/style-guide/confirm-action";
import Button from "../../modules/style-guide/button";
import Input from "../../modules/style-guide/input";
import Checkbox from "../../modules/style-guide/checkbox";
import StatefulModal from "../../modules/style-guide/modal/stateful";
import NullStateCard from "../../modules/style-guide/null-state-card";
import NestedNav from "../../modules/style-guide/nested-nav";
import ManHoldingFile from "../../modules/illustrations/man-holding-file";
import BaseDataPage from "../base-data";
import BaseLayoutPage, {
  renderSkeleton,
  PageAsideContent,
  PageContent
} from "../base-layout";
import styles from "./ExerciseSets.module.css";

import * as ExerciseSetsAPI from "../../lib/api/exercise-sets";

import type {
  AppDataCache,
  updateAppDataCacheFunc
} from "../../lib/data-cache";
import type { ExerciseSet } from "../../lib/api/exercise-sets";
import type { Exercise } from "../../lib/api/exercises";

type Props = {||};

type State = {|
  selectedExerciseSetIDs: string[],

  createTitle: string,
  creating: boolean,
  createError: ?string
|};

class ExerciseSetsPage extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      selectedExerciseSetIDs: [],
      createTitle: "",
      creating: false,
      createError: null
    };
  }

  get isInCreateAssignment(): boolean {
    // $FlowFixMe: add defs for react-router
    const { location } = this.props;
    const { create_assignment } = queryString.parse(location.search);

    return create_assignment && create_assignment === "true";
  }

  get createAssignmentParams() {
    // $FlowFixMe: add defs for react-router
    const { location } = this.props;
    const { assignment_id, course_id, name } = queryString.parse(
      location.search
    );

    return {
      assignmentID: assignment_id,
      courseID: course_id,
      name
    };
  }

  get createAssignmentLink(): string {
    const { selectedExerciseSetIDs } = this.state;
    const p = this.createAssignmentParams;

    if (p.assignmentID) {
      return `/courses/${p.courseID}/assignments/${
        p.assignmentID
      }/edit?exercise_sets=${selectedExerciseSetIDs.join(",")}`;
    }

    return `/courses/${p.courseID}/assignments/create?name=${
      p.name
    }&exercise_sets=${selectedExerciseSetIDs.join(",")}`;
  }

  fetchData = async () => {
    const [
      exerciseSets,
      exercises
    ] = await ExerciseSetsAPI.fetchMyExerciseSetsWithExercises();

    return {
      exerciseSets: keyBy(exerciseSets, es => es.id),
      exercises: keyBy(exercises, e => e.id)
    };
  };

  handleDeleteExerciseSet = (
    exerciseSetID: string,
    updateCache: updateAppDataCacheFunc
  ) => async () => {
    await ExerciseSetsAPI.deleteExerciseSet(exerciseSetID);

    updateCache({
      exerciseSets: {
        [exerciseSetID]: {
          _deleted: true
        }
      }
    });
  };

  handleCreateExerciseSet = async () => {
    // $FlowFixMe: add defs for react-router
    const { history } = this.props;
    const { createTitle } = this.state;

    this.setState({ creating: true, createError: null });

    try {
      const exerciseSet = await ExerciseSetsAPI.createExerciseSet(createTitle);

      history.push(`/questions/${exerciseSet.id}/edit`);
    } catch (e) {
      this.setState({
        creating: false,
        createError: "Failed to create question"
      });
    }
  };

  handleCreateTitleChange = (createTitle: string) =>
    this.setState({ createTitle });

  handleSelectExerciseSetID = (exerciseSetID: string) => (checked: boolean) => {
    if (checked) {
      this.setState(s => ({
        selectedExerciseSetIDs: [...s.selectedExerciseSetIDs, exerciseSetID]
      }));
      return;
    }

    this.setState(s => ({
      selectedExerciseSetIDs: s.selectedExerciseSetIDs.filter(
        id => id !== exerciseSetID
      )
    }));
  };

  renderCreatedByMe(cache: AppDataCache, updateCache: updateAppDataCacheFunc) {
    if (!cache.session) {
      throw new Error("session is undefined");
    }

    const { selectedExerciseSetIDs } = this.state;
    const { profileID } = cache.session;
    const allExerciseSets: ExerciseSet[] = values(cache.exerciseSets);
    const allExercises: Exercise[] = values(cache.exercises);
    const myExerciseSets = allExerciseSets.filter(
      es => es.created_by === profileID
    );

    if (!myExerciseSets.length) {
      return (
        <>
          <H3>Created by me</H3>
          <Spacer />
          <NullStateCard
            backgroundEl={ManHoldingFile}
            title="Create a question"
            content="You can migrate your existing questions, upload PDFs, and make your questions autograde-able with test cases"
            cta={this.renderCreateExerciseSet((isOpen, open) => (
              <Button onClick={open}>Create Question</Button>
            ))}
          />
          <SpacerLg />
        </>
      );
    }

    return (
      <>
        <div className={styles.actionTitle}>
          <H3>Created by me</H3>
          {!this.isInCreateAssignment &&
            this.renderCreateExerciseSet((isOpen, open) => (
              <Button small secondary onClick={open}>
                Create New
              </Button>
            ))}
        </div>
        <Spacer />
        <Table
          listBorder
          columns={[
            { accessor: "title", left: true, compact: true, width: "40%" },
            { accessor: "exercise_count", compact: true },
            { accessor: "edit", right: true, compact: true }
          ]}
          rows={myExerciseSets.map(es => {
            const exercises = allExercises.filter(
              e => e.exercise_set_id === es.id
            );

            return {
              key: es.id,
              data: {
                title: (
                  <>
                    {this.isInCreateAssignment ? (
                      <Checkbox
                        checked={selectedExerciseSetIDs.indexOf(es.id) >= 0}
                        onChange={this.handleSelectExerciseSetID(es.id)}
                      />
                    ) : null}
                    <SpacerSm horizontal />
                    <Link to={`/questions/${es.id}`}>{es.title}</Link>
                  </>
                ),
                exercise_count: (
                  <span className={styles.exercisesCount}>
                    <ListIcon />
                    <SpacerXsm />
                    {`${exercises.length} exercise${
                      exercises.length === 1 ? "" : "s"
                    }`}
                  </span>
                ),
                edit: this.isInCreateAssignment ? null : (
                  <div>
                    <Link to={`/questions/${es.id}/edit`}>
                      <EditIcon />
                    </Link>
                    <Spacer horizontal />
                    <ConfirmAction
                      wide
                      title="Delete Question"
                      message="This question will be deleted from all past, current, and future assignments. This action cannot be undone."
                      placement="RIGHT"
                      onConfirm={this.handleDeleteExerciseSet(
                        es.id,
                        updateCache
                      )}
                    >
                      <DeleteIcon />
                    </ConfirmAction>
                  </div>
                )
              }
            };
          })}
        />
        <SpacerLg />
      </>
    );
  }

  renderCreateExerciseSet(
    renderOpener: (isOpen: boolean, open: () => any) => React.Node
  ) {
    const { createTitle, creating, createError } = this.state;
    return (
      <StatefulModal
        renderOpener={renderOpener}
        renderModal={() => (
          <div className={styles.centered}>
            <H3>New Question</H3>
            <SpacerSm />
            <Subtitle>
              Name your new question to get started. Students will be able to
              see this question when you assign it.
            </Subtitle>
            <Spacer />
            <Input
              label="Question name"
              value={createTitle}
              onEnter={this.handleCreateExerciseSet}
              onChange={this.handleCreateTitleChange}
            />
            <Spacer />
            <Button small onClick={this.handleCreateExerciseSet}>
              {creating ? "Creating Question.." : "Create Question"}
            </Button>
            <SpacerSm />
            <span>{createError}</span>
          </div>
        )}
      />
    );
  }

  renderCreateAssignment() {
    const { selectedExerciseSetIDs } = this.state;

    if (!this.isInCreateAssignment) {
      return null;
    }

    return (
      <Button
        small
        disabled={!selectedExerciseSetIDs.length}
        el={Link}
        to={this.createAssignmentLink}
      >
        Save
      </Button>
    );
  }

  renderCreateAssignmentNotification() {
    if (!this.isInCreateAssignment) {
      return null;
    }

    const p = this.createAssignmentParams;

    return (
      <>
        <NestedNav to={this.createAssignmentLink}>
          {p.name ? p.name : "Create Assignment"}
        </NestedNav>
        <Spacer />
      </>
    );
  }

  render() {
    return (
      <BaseDataPage fetchData={this.fetchData} renderSkeleton={renderSkeleton}>
        <AppDataCacheCtx.Consumer>
          {({ cache, updateCache }) => {
            return (
              <BaseLayoutPage>
                <PageContent
                  title="My Questions"
                  actions={[this.renderCreateAssignment()]}
                >
                  {this.renderCreateAssignmentNotification()}
                  {this.renderCreatedByMe(cache, updateCache)}
                </PageContent>
                <PageAsideContent />
              </BaseLayoutPage>
            );
          }}
        </AppDataCacheCtx.Consumer>
      </BaseDataPage>
    );
  }
}

export default ExerciseSetsPage;
