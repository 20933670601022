// @flow
import * as React from "react";
import queryString from "query-string";
import Workspace from "../../modules/workspace";
import CodeEditor from "../../modules/workspace/code-editor";

class WorkspacePage extends React.Component<{||}> {
  get isReadOnly() {
    let { read_only } = queryString.parse(
      // $FlowFixMe: add defs for react-router
      this.props.location.search
    );
    return read_only && read_only === "true";
  }

  render() {
    // $FlowFixMe: add defs for react-router
    const { workspaceID } = this.props.match.params;

    return (
      <Workspace
        workspaceID={workspaceID}
        renderComponent={props => (
          <CodeEditor readOnly={this.isReadOnly} {...props} />
        )}
      />
    );
  }
}

export default WorkspacePage;
