// @flow
import * as React from "react";
import FileBrowser from "./file-browser";
import Editor from "./editor";
import { debounce } from "lodash";

import type { WorkspaceRenderProps } from "../index";

import styles from "./CodeEditor.module.css";

type Props = {|
  ...WorkspaceRenderProps,

  readOnly?: boolean,
  selectedFilePath: ?string,
  selectFile: (path: ?string) => void,

  toolbarEndEnhancer?: () => React.Node
|};

const FILE_SAVE_WAIT = 3000;

class CodeEditor extends React.Component<Props> {
  debouncedHandleSaveFile: ?() => Promise<void>;

  componentDidUpdate(prevProps: Props): void {
    // save deleted files
    prevProps.files
      .filter(f => !f.deleted)
      .filter(f => {
        const file = this.props.files.find(({ path }) => path === f.path);
        return file && file.deleted && !file.saved && !file.saving;
      })
      .forEach(file => this.props.saveFile(file.path));
  }

  handleSelectFile = (path: string) => {
    const { selectFile, saveFile } = this.props;
    selectFile(path);

    this.debouncedHandleSaveFile = debounce(
      () => saveFile(path),
      FILE_SAVE_WAIT
    );
  };

  handleDeleteFile = async (path: string) => {
    await this.props.deleteFile(path);

    if (path === this.props.selectedFilePath) {
      this.props.selectFile(null);
    }
  };

  handleNewFile = (path: string) => {
    this.props.newFile(path, "");
    this.handleSelectFile(path);
  };

  handleDataChange = (data: string) => {
    const { selectedFilePath, updateFileData } = this.props;
    const saveFile = this.debouncedHandleSaveFile;

    if (!selectedFilePath || !saveFile) {
      return;
    }

    updateFileData(selectedFilePath, data);
    saveFile();
  };

  render() {
    const {
      readOnly,
      files,
      selectedFilePath,
      toolbarEndEnhancer
    } = this.props;
    const selectedFile = files.find(f => f.path === selectedFilePath);

    return (
      <div className={styles.container}>
        <FileBrowser
          readOnly={readOnly}
          paths={files.filter(f => !f.deleted).map(f => f.path)}
          onSelectFile={this.handleSelectFile}
          onDeleteFile={this.handleDeleteFile}
          onNewFile={this.handleNewFile}
        />
        <Editor
          readOnly={readOnly}
          file={selectedFile}
          onDataChange={this.handleDataChange}
          toolbarEndEnhancer={toolbarEndEnhancer}
        />
      </div>
    );
  }
}

export default CodeEditor;
