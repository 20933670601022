// @flow
import * as React from "react";
import Input from "../../modules/style-guide/input";
import { SpacerSm } from "../../modules/style-guide/spacer";
import Button from "../../modules/style-guide/button";
import styles from "./CourseManage.module.css";

type Props = {|
  name: string,
  onRename: (name: string) => Promise<any>
|};

type State = {|
  name: string,
  renaming: boolean,
  renameError: ?string
|};

class RenameCourse extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      name: this.props.name,
      renaming: false,
      renameError: null
    };
  }

  handleRename = async () => {
    if (!this.state.name) {
      this.setState({ renameError: "Course name cannot be empty" });
      return;
    }

    try {
      this.setState({ renaming: true, renameError: null });
      await this.props.onRename(this.state.name);
      this.setState({ renaming: false });
    } catch (e) {
      this.setState({
        renaming: false,
        renameError: "Failed to rename course"
      });
    }
  };

  handleNameChange = (name: string) => this.setState({ name });

  render() {
    const { name, renaming, renameError } = this.state;
    return (
      <div className={styles.renameContainer}>
        <Input
          label="Course name"
          value={name}
          onEnter={this.handleRename}
          onChange={this.handleNameChange}
        />
        <SpacerSm />
        <Button small secondary onClick={this.handleRename}>
          {renaming ? "Renaming" : "Rename"}
        </Button>
        <SpacerSm />
        <span>{renameError}</span>
      </div>
    );
  }
}

export default RenameCourse;
