// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import styles from "./NestedNav.module.css";

type Props = {|
  to: string,
  contained?: boolean,
  children: React.Node
|};

class NestedNav extends React.Component<Props> {
  render() {
    const { to, contained, children } = this.props;

    return (
      <div className={`${styles.nav} ${contained ? styles.contained : ""}`}>
        <Link to={to}>
          <FaChevronLeft />
          {children}
        </Link>
      </div>
    );
  }
}

export default NestedNav;
