// @flow
import * as React from "react";
import Select from "../style-guide/select";

import type { SelectItem } from "../style-guide/select";
import type { Exercise } from "../../lib/api/exercises";

type Props = {|
  exercises: Exercise[],
  selectedExerciseID: string,
  renderLabel?: (exercise: Exercise) => React.Node,

  onChange?: (exercise: Exercise) => void
|};

class ExercisePicker extends React.Component<Props> {
  handleSelectExerciseItem = (item: SelectItem) => {
    const { exercises, onChange } = this.props;

    if (!onChange) {
      return;
    }

    const exercise = exercises.find(e => e.id === item.id);

    if (!exercise) {
      throw Error("selected exercise is undefined");
    }

    onChange(exercise);
  };

  renderSelectedItem = (item: SelectItem, index: number) => {
    const { exercises } = this.props;

    return `Exercise ${index + 1} of ${exercises.length}`;
  };

  render() {
    const { exercises, selectedExerciseID, renderLabel } = this.props;
    const selectedExercise = exercises.find(e => e.id === selectedExerciseID);

    if (!selectedExercise) {
      throw Error("selected exercise is undefined");
    }

    return (
      <Select
        compact
        items={exercises.map(e => ({
          id: e.id,
          label: renderLabel ? renderLabel(e) : e.title
        }))}
        value={selectedExercise.id}
        renderSelectedItem={this.renderSelectedItem}
        onSelect={this.handleSelectExerciseItem}
      />
    );
  }
}

export default ExercisePicker;
