// flow
import * as React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import Pre from "../style-guide/pre";
import { Spacer, SpacerSm } from "../style-guide/spacer";
import ExternalLink from "../style-guide/external-link";
import styles from "./ResultSet.module.css";

import type { Result, ResultSet as ResultSetT } from "../../lib/api/attempts";

type Props = {|
  resultSet: ResultSetT
|};

class ResultSet extends React.Component<Props> {
  renderFailedResult(result: Result) {
    const { resultSet } = this.props;
    let actualOutput: React.Node = null;

    switch (result.payload && result.payload.type) {
      case "LOG":
        actualOutput = (
          <Pre>
            {result.payload.log.split("\n").map((l, i) => (
              <div key={i}>{l}</div>
            ))}
          </Pre>
        );
        break;
      case "LINE":
        actualOutput = (
          <Pre initialLineNum={result.payload.line_num || 1}>
            <div>{result.payload.line}</div>
          </Pre>
        );
        break;
      case "LOG_FILE":
        {
          if (!result.payload.path) {
            break;
          }

          const path = resultSet.logs.find(l =>
            l.endsWith(result.payload.path)
          );

          if (!path) {
            break;
          }

          const link = `/api/blobs/${path}`;
          actualOutput = (
            <ExternalLink href={link}>{result.payload.path}</ExternalLink>
          );
        }
        break;
      default:
        actualOutput = null;
    }

    return (
      <>
        <pre className={styles.result}>
          <FaTimes className={styles.failedIcon} /> {result.label}
        </pre>
        <SpacerSm />
        <div className={styles.actualOutput}>{actualOutput}</div>
      </>
    );
  }

  render() {
    const { resultSet } = this.props;

    switch (resultSet.status) {
      case "COMPLETED":
        return (
          <div>
            {resultSet.results.map(r => (
              <div key={r.order}>
                {r.did_pass ? (
                  <pre className={styles.result}>
                    <FaCheck className={styles.successIcon} /> {r.label}
                  </pre>
                ) : (
                  this.renderFailedResult(r)
                )}
                <Spacer />
              </div>
            ))}
          </div>
        );
      case "TIMED_OUT":
      case "RUN_NON_ZERO_EXIT_CODE":
      case "FAILED":
        return (
          <div>
            Failed to run submission for this test case. Check logs for more
            details.
          </div>
        );
      default:
        return <div>Running..</div>;
    }
  }
}

export default ResultSet;
