// @flow
import makeRequest from "../make-request";

export type TestCaseTemplate = {|
  id: string,
  workspace_id: string,
  ui_widget: string,
  name: string
|};

export async function fetchAll(): Promise<TestCaseTemplate[]> {
  const url = `/api/test-case-templates`;

  return makeRequest(url);
}
