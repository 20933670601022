// @flow
import * as React from "react";
import queryString from "query-string";
import { keyBy } from "lodash";
import { FaExternalLinkAlt } from "react-icons/fa";
import AppDataCacheCtx from "../../lib/data-cache";
import { H3 } from "../../modules/style-guide/text";
import { Spacer, SpacerLg, SpacerXLg } from "../../modules/style-guide/spacer";
import Accordion from "../../modules/style-guide/accordion";
import Panel from "../../modules/style-guide/accordion/panel";
import Button from "../../modules/style-guide/button";
import Card from "../../modules/style-guide/card";
import NestedNav from "../../modules/style-guide/nested-nav";
import CounterPill from "../../modules/style-guide/counter-pill";
import HelpSection from "../../modules/style-guide/help-section";
import BaseDataPage from "../base-data";
import TitleDescriptionEditor from "./title-description-editor";
import StacksEditor from "./stacks-editor";
import TestSuiteEditor from "./test-suite-editor";
import BaseLayoutPage, {
  renderSkeleton,
  PageContent,
  PageAsideContent
} from "../base-layout";

import * as ExerciseSetsAPI from "../../lib/api/exercise-sets";
import * as ExercisesAPI from "../../lib/api/exercises";
import * as StacksAPI from "../../lib/api/stacks";
import * as TestCaseTemplatesAPI from "../../lib/api/test-case-templates";
import * as TestCasesAPI from "../../lib/api/test-cases";

const MAX_STEP_INDEX = 3;

class EditExercisePage extends React.Component<{}> {
  getCurrentStep(): ?number {
    // $FlowFixMe: add defs for react-router
    const { location } = this.props;
    const queryStep = queryString.parse(location.search).step;

    if (queryStep === undefined) {
      return null;
    }

    return Math.max(0, Math.min(MAX_STEP_INDEX, parseInt(queryStep)));
  }

  handleStepSelect = (step: number) => {
    // $FlowFixMe: add defs for react-router
    const { history, location } = this.props;

    history.push({
      search: `?${queryString.stringify({
        ...queryString.parse(location.search),
        step
      })}`
    });
  };

  handleNextStep = () => {
    const currentStep =
      this.getCurrentStep() === null ? -1 : this.getCurrentStep();
    this.handleStepSelect(currentStep + 1);
  };

  fetchData = async () => {
    // $FlowFixMe: add defs for react-router
    const { exerciseSetID, exerciseID } = this.props.match.params;

    const [
      exerciseSet,
      exercise,
      exerciseStacks,
      allStacks,
      allTestCaseTemplates
    ] = await Promise.all([
      ExerciseSetsAPI.fetchExerciseSet(exerciseSetID),
      ExercisesAPI.fetchExercise(exerciseID),
      ExercisesAPI.fetchExerciseStacks(exerciseID),
      StacksAPI.fetchAll(),
      TestCaseTemplatesAPI.fetchAll()
    ]);

    const testCases = await TestCasesAPI.fetchTestCases(
      exercise.test_case_set_id
    );

    return {
      exerciseSets: {
        [exerciseSet.id]: exerciseSet
      },
      exercises: {
        [exercise.id]: exercise
      },
      stacks: keyBy(allStacks, s => s.id),
      testCases: keyBy(testCases, tc => tc.id),
      testCaseTemplates: keyBy(allTestCaseTemplates, t => t.id),
      stackIDsForExercise: {
        [exercise.id]: exerciseStacks.map(s => s.id)
      }
    };
  };

  renderHelp() {
    switch (this.getCurrentStep()) {
      case 0:
        return (
          <HelpSection title="Title & Description">
            Use this space to describe what the students are expected to do.
            Explain the problem in detail and give meaningful context.
            <br />
            <br />
            You can use various formatting options and link to external content.
          </HelpSection>
        );
      case 1:
        return (
          <>
            <SpacerXLg />
            <HelpSection title="Stacks">
              Choose all the stacks that the exercise is compatible with.
              Different stacks give you the options to use different grading
              types. For example, you can use output matching grading type with
              all command line stacks.
              <br />
              <br />
              Some of the stacks may not be available to you immediately.
              Request to unlock them. You can also request for new stacks by
              contacting us via support.
            </HelpSection>
          </>
        );
      case 2:
        return (
          <>
            <HelpSection title="Test cases">
              Create one or more test cases to run against each student
              submission. Students get instant feedback on the test case results
              after making a submission.
              <br />
              <br />
              You can pick between different grading types depending on your use
              case.
            </HelpSection>
            <SpacerLg />
            <HelpSection title="Output matching">
              Use the output matching grading type to run student submissions as
              a command line application and test the console output against
              expected output as defined by you. You can also add input files as
              well as expected output files to make the test more comprehensive.
            </HelpSection>
            <SpacerLg />
            <HelpSection title="JUnit tests">
              JUnit tests are only compatible with the Java 8 stack. You can
              write your own JUnit 4 tests to test your students' code.
            </HelpSection>
            <SpacerLg />
            <HelpSection title="pytest">
              pytest is only compatible with the Python 2.7 and 3 stacks. You
              can write your own pytest unit tests to test your students' code.
            </HelpSection>
            <SpacerLg />
            <HelpSection title="gotest">
              gotest is only compatible with the Go stack. You can write your
              own Go unit tests to test your students' code.
            </HelpSection>
            <SpacerLg />
            <HelpSection title="Web UI tests">
              Test a web app UI to ensure the pages contain the necessary
              elements and function as expected.
            </HelpSection>
            <SpacerLg />
            <HelpSection title="Custom script">
              If you have a special use case, you can write your own Python
              script to test the submission.
            </HelpSection>
          </>
        );
      case 3:
        return (
          <>
            <SpacerXLg />
            <SpacerXLg />
            <HelpSection title="Preview">
              Congratulations! You have finished all the steps to setup this
              exercise. Preview to ensure that everything works as expected.
              <br />
              <br />
              You can go back to add more exercises to this question. Once
              ready, assign it to your students.
            </HelpSection>
          </>
        );
      default:
        return (
          <HelpSection title="Setup exercise">
            Go through each step to setup the exercise. Once ready, remember to
            test your setup before assigning it to your students.
            <br /> <br />
            As you go through the steps, you can find more help here.
          </HelpSection>
        );
    }
  }

  render() {
    // $FlowFixMe: add defs for react-router
    const { exerciseSetID, exerciseID } = this.props.match.params;

    return (
      <BaseDataPage fetchData={this.fetchData} renderSkeleton={renderSkeleton}>
        <AppDataCacheCtx.Consumer>
          {({ cache }) => {
            const exerciseSet = cache.exerciseSets[exerciseSetID];
            const exercise = cache.exercises[exerciseID];

            return (
              <BaseLayoutPage>
                <PageContent title={exerciseSet.title}>
                  <H3>{exercise.title}</H3>
                  <Spacer />
                  <Card>
                    <NestedNav contained to="../edit">
                      Exercises
                    </NestedNav>
                    <Accordion
                      selected={this.getCurrentStep()}
                      onSelect={this.handleStepSelect}
                    >
                      <Panel
                        startEnhancer={<CounterPill>1</CounterPill>}
                        title="Title & description"
                        subtitle="What are students expected to do?"
                        renderSelectButton={(open, onSelect) =>
                          open ? null : (
                            <Button small secondary onClick={onSelect}>
                              {exercise.description ? "Edit" : "Write"}
                            </Button>
                          )
                        }
                      >
                        <TitleDescriptionEditor
                          exerciseID={exercise.id}
                          next={this.handleNextStep}
                        />
                      </Panel>

                      <Panel
                        startEnhancer={<CounterPill>2</CounterPill>}
                        title="Stacks"
                        subtitle="How will the code be written and run?"
                        renderSelectButton={(open, onSelect) =>
                          open ? null : (
                            <Button small secondary onClick={onSelect}>
                              Choose
                            </Button>
                          )
                        }
                      >
                        <StacksEditor
                          exerciseID={exercise.id}
                          next={this.handleNextStep}
                        />
                      </Panel>

                      <Panel
                        startEnhancer={<CounterPill>3</CounterPill>}
                        title="Test cases"
                        subtitle="How will the submissions be tested?"
                        renderSelectButton={(open, onSelect) =>
                          open ? null : (
                            <Button small secondary onClick={onSelect}>
                              Setup
                            </Button>
                          )
                        }
                      >
                        <TestSuiteEditor
                          testCaseSetID={exercise.test_case_set_id}
                          next={this.handleNextStep}
                        />
                      </Panel>

                      <Panel
                        startEnhancer={<CounterPill>4</CounterPill>}
                        title="Preview"
                        subtitle="Make sure everything looks good for students"
                        renderSelectButton={() => (
                          <Button
                            small
                            secondary
                            el="a"
                            href={`../${exercise.id}`}
                            target="_blank"
                          >
                            <FaExternalLinkAlt /> View as student
                          </Button>
                        )}
                      />
                    </Accordion>
                  </Card>
                </PageContent>
                <PageAsideContent>
                  <SpacerXLg />
                  {this.renderHelp()}
                  <SpacerXLg />
                </PageAsideContent>
              </BaseLayoutPage>
            );
          }}
        </AppDataCacheCtx.Consumer>
      </BaseDataPage>
    );
  }
}

export default EditExercisePage;
