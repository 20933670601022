// @flow
import * as React from "react";
import styles from "./CreateCourseForm.module.css";
import { Error, H3, Subtitle } from "../style-guide/text";
import { Spacer, SpacerLg, SpacerSm, SpacerXsm } from "../style-guide/spacer";
import Button from "../style-guide/button";
import type { Course } from "../../lib/api/courses";
import * as CoursesAPI from "../../lib/api/courses";
import {
  getCourseDurationLabel,
  getCoursePricePerStudent
} from "../../lib/course";

type Props = {
  course: $Shape<Course>,
  onNext: (paymentMethod: "NONE" | "UPFRONT") => void,
  onCreateCourse: (id: string) => void
};

const PaymentMethodEditor = (props: Props) => {
  const [paymentMethod, setPaymentMethod] = React.useState(
    props.course.payment_method || "UPFRONT"
  );
  const [isCreating, setIsCreating] = React.useState(false);
  const [createErr, setIsCreateErr] = React.useState<?string>(null);

  const onSubmit = React.useCallback(
    async () => {
      if (paymentMethod !== "ON_ENROLL") {
        props.onNext(paymentMethod);
        return;
      }

      setIsCreating(true);

      try {
        const course = await CoursesAPI.createCourse({
          ...props.course,
          payment_method: paymentMethod
        });

        props.onCreateCourse(course.id);
      } catch (e) {
        setIsCreateErr(
          "Failed to create course. Please try again later or contact support."
        );
        setIsCreating(false);
      }
    },
    [paymentMethod]
  );

  return (
    <div className={styles.content}>
      <H3>{props.course.name}</H3>
      <SpacerSm />
      <Subtitle>
        AutoGradr costs $4 / student / month.
        <br />
        Choose the payment option that works best for your course.
      </Subtitle>
      <SpacerLg />

      <div className={styles.form}>
        <div>
          <label>
            <input
              type="radio"
              name="paymentMethod"
              value="UPFRONT"
              checked={paymentMethod === "UPFRONT"}
              onChange={() => setPaymentMethod("UPFRONT")}
            />
            <SpacerXsm horizontal />
            Pay now
          </label>
          <div className={styles.subtitle}>
            ${getCoursePricePerStudent(props.course)} per student for{" "}
            {getCourseDurationLabel(props.course)}
          </div>
        </div>
        <Spacer />
        <div>
          <label>
            <input
              type="radio"
              name="paymentMethod"
              value="ON_ENROLL"
              checked={paymentMethod === "ON_ENROLL"}
              onChange={() => setPaymentMethod("ON_ENROLL")}
            />
            <SpacerXsm horizontal />
            Students pay for their own seats
          </label>
          <div className={styles.subtitle}>
            Students will pay ${getCoursePricePerStudent(props.course)} when
            they enroll
          </div>
        </div>
        <Spacer />
      </div>
      <SpacerLg />
      <div className={styles.actionBar}>
        <Button wide disabled={isCreating} onClick={onSubmit}>
          {paymentMethod !== "ON_ENROLL"
            ? "Next"
            : isCreating
            ? "Creating Course.."
            : "Create Course"}
        </Button>
        {createErr && (
          <>
            <Spacer />
            <Error>{createErr}</Error>
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentMethodEditor;
