// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import AnonymousLayout1, {
  PageContent,
  PageFooter
} from "../anonymous-layout-1";
import BaseDataPage from "../base-data";
import styles from "./CourseEnroll.module.css";

import * as AuthAPI from "../../lib/api/auth";
import CourseEnrollForm from "./enroll-form";
import CourseEnrollPaymentForm from "./payment-form";

const Step = {
  Enroll: 1,
  Pay: 2
};

const CourseEnrollPage = (props: {}) => {
  // $FlowFixMe: add defs for react-router
  const { history } = props;

  const [step, setStep] = React.useState(Step.Enroll);
  const [courseID, setCourseID] = React.useState<?string>(null);

  const handleLogout = async () => {
    try {
      await AuthAPI.logout();
    } catch (e) {
    } finally {
      history.push("/login");
    }
  };

  return (
    <BaseDataPage
      fetchData={async () => {}}
      renderSkeleton={AnonymousLayout1.renderSkeleton}
    >
      <AnonymousLayout1
        title="Welcome to AutoGradr"
        subtitle="Enroll in a course"
      >
        <PageContent>
          {step === Step.Enroll && (
            <CourseEnrollForm
              onRequiresPayment={courseID => {
                setCourseID(courseID);
                setStep(Step.Pay);
              }}
              onEnroll={courseID =>
                history.push(`/courses/${courseID}/assignments`)
              }
            />
          )}

          {step === Step.Pay && courseID ? (
            <CourseEnrollPaymentForm
              courseID={courseID}
              onEnroll={courseID =>
                history.push(`/courses/${courseID}/assignments`)
              }
            />
          ) : null}
        </PageContent>

        <PageFooter>
          <div className={styles.center}>
            <Link to="#" onClick={handleLogout}>
              Logout
            </Link>
          </div>
        </PageFooter>
      </AnonymousLayout1>
    </BaseDataPage>
  );
};

export default CourseEnrollPage;
