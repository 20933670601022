// @flow
import makeRequest from "../make-request";

import type { ExerciseSet } from "../exercise-sets";

export type Assignment = {|
  id: string,
  name: string,
  available_at: number,
  due_at: number,
  cutoff_at: number,
  course_id: string,
  is_available: boolean,
  is_past_due: boolean,
  is_past_cutoff: boolean,
  exercise_set_ids: string[],
  session_started_at: ?number,
  session_time_limit: ?number
|};

export type UpdateAssignmentParams = {|
  exerciseSetIDs: string[],
  name: string,
  availableAt: number,
  dueAt: number,
  cutoffAt: number,
  sessionTimeLimit: ?number
|};

export async function fetchAssignmentWithExerciseSets(
  assignmentID: string
): Promise<[Assignment, ExerciseSet[]]> {
  const url = `/api/assignments/${assignmentID}`;
  let { exercise_sets, ...assignment } = await makeRequest(url);

  assignment.exercise_set_ids = exercise_sets.map(es => es.id);

  return [assignment, exercise_sets];
}

export async function createAssignment(
  courseID: string,
  p: UpdateAssignmentParams
): Promise<[Assignment, ExerciseSet[]]> {
  const url = `/api/assignments`;
  const data = JSON.stringify({
    course_id: courseID,
    exercise_set_ids: p.exerciseSetIDs,
    name: p.name,
    available_at: p.availableAt,
    due_at: p.dueAt,
    cutoff_at: p.cutoffAt,
    session_time_limit: p.sessionTimeLimit
  });
  let { exercise_sets, ...assignment } = await makeRequest(url, {
    method: "POST",
    body: data
  });

  assignment.exercise_set_ids = exercise_sets.map(es => es.id);

  return [assignment, exercise_sets];
}

export async function deleteAssignment(assignmentID: string): Promise<any> {
  const url = `/api/assignments/${assignmentID}`;

  return await makeRequest(url, { method: "DELETE" });
}

export async function beginAssignmentSession(
  assignmentID: string
): Promise<any> {
  const url = `/api/assignments/${assignmentID}/begin-session`;

  return await makeRequest(url, { method: "POST" });
}

export async function updateAssignment(
  assignmentID: string,
  p: UpdateAssignmentParams
): Promise<any> {
  const url = `/api/assignments/${assignmentID}`;
  const data = JSON.stringify({
    exercise_set_ids: p.exerciseSetIDs,
    name: p.name,
    available_at: p.availableAt,
    due_at: p.dueAt,
    cutoff_at: p.cutoffAt,
    session_time_limit: p.sessionTimeLimit
  });

  return await makeRequest(url, {
    method: "POST",
    body: data
  });
}

export async function fetchExerciseSets(
  assignmentID: string
): Promise<ExerciseSet[]> {
  const url = `/api/assignments/${assignmentID}/exercise-sets`;

  return makeRequest(url);
}
