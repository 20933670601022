// @flow
import * as React from "react";
import { values, keyBy } from "lodash";
import { Link } from "react-router-dom";
import { FaEye, FaPen, FaRegTrashAlt } from "react-icons/fa";
import AppDataCacheCtx from "../../lib/data-cache";
import BaseLayoutPage, {
  renderSkeleton,
  PageContent,
  PageAsideContent
} from "../base-layout";
import BaseDataPage from "../base-data";
import { H3 } from "../../modules/style-guide/text";
import { Spacer, SpacerLg } from "../../modules/style-guide/spacer";
import List from "../../modules/style-guide/list";
import ConfirmAction from "../../modules/style-guide/confirm-action";
import CreateExercise from "./create-exercise";
import MetadataEditor from "./metadata-editor";
import styles from "./ExerciseSet.module.css";

import * as ExerciseSetsAPI from "../../lib/api/exercise-sets";
import * as ExercisesAPI from "../../lib/api/exercises";

import type { Exercise } from "../../lib/api/exercises";
import type { updateAppDataCacheFunc } from "../../lib/data-cache";
import HelpSection from "../../modules/style-guide/help-section";

type Props = {||};

type State = {|
  savingMetadata: boolean,
  saveMetadataError: ?string,

  creatingExercise: boolean,
  createExerciseError: ?string
|};

class EditExerciseSetPage extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      savingMetadata: false,
      saveMetadataError: null,
      creatingExercise: false,
      createExerciseError: null
    };
  }

  fetchData = async () => {
    // $FlowFixMe: add defs for react-router
    const { exerciseSetID } = this.props.match.params;

    const [exerciseSet, exercises] = await Promise.all([
      ExerciseSetsAPI.fetchExerciseSet(exerciseSetID),
      ExercisesAPI.fetchExercises(exerciseSetID)
    ]);

    return {
      exerciseSets: {
        [exerciseSet.id]: exerciseSet
      },
      exercises: keyBy(exercises, e => e.id)
    };
  };

  handleMetadataSave = (updateCache: updateAppDataCacheFunc) => async (
    title: string
  ) => {
    // $FlowFixMe: add defs for react-router
    const { exerciseSetID } = this.props.match.params;

    this.setState({ savingMetadata: true, saveMetadataError: null });

    try {
      await ExerciseSetsAPI.updateExerciseSet(exerciseSetID, title);

      this.setState({ savingMetadata: false });

      updateCache({
        exerciseSets: {
          [exerciseSetID]: {
            title
          }
        }
      });
    } catch (e) {
      this.setState({
        savingMetadata: false,
        saveMetadataError: "Failed to save title"
      });
    }
  };

  handleDeleteExercise = (
    updateCache: updateAppDataCacheFunc,
    exerciseID: string
  ) => async () => {
    try {
      await ExercisesAPI.deleteExercise(exerciseID);

      updateCache({
        exercises: {
          [exerciseID]: {
            _deleted: true
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleCreateExercise = () => async (title: string) => {
    // $FlowFixMe: add defs for react-router
    const { history, match } = this.props;
    const { exerciseSetID } = match.params;

    this.setState({ creatingExercise: true, createExerciseError: null });

    try {
      const exercise = await ExerciseSetsAPI.createExercise(
        exerciseSetID,
        title
      );

      history.push(`${exercise.id}/edit`);
    } catch (e) {
      this.setState({
        creatingExercise: false,
        createExerciseError: "Failed to create exercise"
      });
    }
  };

  render() {
    // $FlowFixMe: add defs for react-router
    const { exerciseSetID } = this.props.match.params;
    const {
      savingMetadata,
      saveMetadataError,
      creatingExercise,
      createExerciseError
    } = this.state;

    return (
      <BaseDataPage fetchData={this.fetchData} renderSkeleton={renderSkeleton}>
        <AppDataCacheCtx.Consumer>
          {({ cache, updateCache }) => {
            const allExercises: Exercise[] = values(cache.exercises);
            const exerciseSet = cache.exerciseSets[exerciseSetID];
            const exercises = allExercises.filter(
              e => e.exercise_set_id === exerciseSet.id
            );

            return (
              <BaseLayoutPage>
                <PageContent title={exerciseSet.title}>
                  <MetadataEditor
                    exerciseSet={exerciseSet}
                    onSave={this.handleMetadataSave(updateCache)}
                    saving={savingMetadata}
                    saveError={saveMetadataError}
                  />
                  <Spacer />
                  <H3>Exercises</H3>
                  <Spacer />
                  <CreateExercise
                    creating={creatingExercise}
                    createError={createExerciseError}
                    onCreate={this.handleCreateExercise()}
                  />
                  <Spacer />
                  <List
                    items={exercises.map(e => ({
                      id: e.id,
                      label: e.title,
                      actions: [
                        () => (
                          <Link to={`${e.id}/edit`}>
                            <FaPen className={styles.action} />
                          </Link>
                        ),
                        () => (
                          <ConfirmAction
                            title="Delete exercise"
                            message="This action cannot be undone."
                            placement="RIGHT"
                            onConfirm={this.handleDeleteExercise(
                              updateCache,
                              e.id
                            )}
                          >
                            <FaRegTrashAlt className={styles.deleteAction} />
                          </ConfirmAction>
                        )
                      ]
                    }))}
                  />
                </PageContent>
                <PageAsideContent>
                  <HelpSection icon={FaEye} title="Public questions">
                    Share your material with the world by making your questions
                    public. Public questions can be used by other instructors on
                    AutoGradr in their courses (coming soon).
                  </HelpSection>
                  <SpacerLg />
                  <HelpSection icon={FaPen} title="Create exercises">
                    A single question can have one or more exercises.
                  </HelpSection>
                </PageAsideContent>
              </BaseLayoutPage>
            );
          }}
        </AppDataCacheCtx.Consumer>
      </BaseDataPage>
    );
  }
}

export default EditExerciseSetPage;
