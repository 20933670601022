// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import ReactResizeDetector from "react-resize-detector";
import { H3 } from "../../modules/style-guide/text";
import ExercisePicker from "../../modules/exercise-picker";
import Workspace from "../../modules/workspace";
import CodeEditor from "../../modules/workspace/code-editor";
import TestCaseSet from "../../modules/test-case-set";
import Button from "../../modules/style-guide/button";
import { SpacerSm, SpacerXsm } from "../../modules/style-guide/spacer";
import Logo from "../../modules/style-guide/logo";
import styles from "./AttemptPage.module.css";

import * as IDEsAPI from "../../lib/api/ides";

import type { ExerciseSet } from "../../lib/api/exercise-sets";
import type { Exercise } from "../../lib/api/exercises";
import type { IDE } from "../../lib/api/ides";
import RichHTML from "../../modules/style-guide/rich-html";
import ViewToggles from "./view-toggles";
import type { Assignment } from "../../lib/api/assignments";

const HEADER_HEIGHT_PX = 60;
const TEST_CASES_COLLAPSED_HEIGHT_PX = 50;
const TEST_CASES_HEIGHT_PX = 300;
const SIDEBAR_WIDTH_PX = 440;

type Props = {|
  ide: IDE,
  exerciseSet: ExerciseSet,
  exercise: Exercise,
  readOnly?: boolean,
  runningTests: boolean,
  assignment: ?Assignment,

  runTests: (testCaseSetID: string, stackID: string) => any
|};

type State = {|
  workspaceContainerHeight: string,
  testCasesContainerWidth: string,

  runTestsError: ?string,

  collapsedTestCases: boolean,
  collapsedExerciseOverview: boolean
|};

class AttemptPage extends React.Component<Props, State> {
  workspace: ?Workspace;
  containerEl: ?HTMLDivElement;

  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      workspaceContainerHeight: "100%",
      testCasesContainerWidth: "100%",

      runTestsError: null,

      collapsedTestCases: false,
      collapsedExerciseOverview: false
    };
  }

  handleUploadFile = (path: string, data: string | Blob) => {
    return IDEsAPI.uploadFile(this.props.ide.id, path, data);
  };

  handleDeleteFile = (path: string) => {
    return IDEsAPI.deleteFile(this.props.ide.id, path);
  };

  handleResize = () => {
    const { collapsedTestCases, collapsedExerciseOverview } = this.state;

    if (!this.containerEl) {
      this.setState({
        testCasesContainerWidth: "100%",
        workspaceContainerHeight: "100%"
      });
      return;
    }

    const containerHeight = this.containerEl.clientHeight;
    const containerWidth = this.containerEl.clientWidth;
    const sidebarWidth = collapsedExerciseOverview ? 0 : SIDEBAR_WIDTH_PX;
    const workspaceHeight =
      containerHeight -
      HEADER_HEIGHT_PX -
      (collapsedTestCases
        ? TEST_CASES_COLLAPSED_HEIGHT_PX
        : TEST_CASES_HEIGHT_PX);
    const testCasesContainerWidth = containerWidth - sidebarWidth;

    this.setState({
      testCasesContainerWidth: `${testCasesContainerWidth}px`,
      workspaceContainerHeight: `${workspaceHeight}px`
    });
  };

  handleRunTests = async () => {
    const { ide, assignment, runTests } = this.props;

    try {
      this.setState({ runTestsError: null });
      if (this.workspace) {
        await this.workspace.save();
      }

      await runTests(ide.test_case_set_id, ide.stack_id);
    } catch (e) {
      // We assume that the failure reason is the assignment not being available anymore.
      // Ideally, we would check for the failure reason and act accordingly.
      if (assignment) {
        window.location = `/courses/${assignment.course_id}/assignments`;
      } else {
        this.setState({
          runTestsError: "Failed to run tests"
        });
      }
    }
  };

  handleCollapseTestCasesToggle = () => {
    this.setState(
      s => ({
        collapsedTestCases: !s.collapsedTestCases
      }),
      () => this.handleResize()
    );
  };

  handleCollapseExerciseOverviewToggle = () =>
    this.setState(
      s => ({ collapsedExerciseOverview: !s.collapsedExerciseOverview }),
      this.handleResize
    );

  renderViewToggles() {
    return (
      <ViewToggles
        right={!this.state.collapsedExerciseOverview}
        bottom={!this.state.collapsedTestCases}
        onToggleRight={this.handleCollapseExerciseOverviewToggle}
        onToggleBottom={this.handleCollapseTestCasesToggle}
      />
    );
  }

  render() {
    const { exerciseSet, exercise, ide, readOnly, runningTests } = this.props;
    const {
      runTestsError,
      testCasesContainerWidth,
      workspaceContainerHeight,
      collapsedTestCases,
      collapsedExerciseOverview
    } = this.state;

    return (
      <ReactResizeDetector
        handleWidth
        handleHeight
        onResize={this.handleResize}
      >
        <div className={styles.container} ref={r => (this.containerEl = r)}>
          <div className={styles.header}>
            <Link to="/">
              <Logo noText small />
            </Link>
            <SpacerXsm />
            <div>
              <H3>
                {exerciseSet.title} / {exercise.title}
              </H3>
              <SpacerSm horizontal />
              {readOnly ? null : (
                <ExercisePicker
                  exerciseSetID={exerciseSet.id}
                  exerciseID={exercise.id}
                  renderLabel={e => (
                    <Link to={`../../questions/${exerciseSet.id}/${e.id}`}>
                      {e.title}
                    </Link>
                  )}
                />
              )}
            </div>
          </div>
          <div className={styles.split}>
            <div className={styles.workspaceTestCasesContainer}>
              <div
                className={styles.workspaceContainer}
                style={{
                  height: workspaceContainerHeight
                }}
              >
                <Workspace
                  ref={r => (this.workspace = r)}
                  workspaceID={ide.workspace_id}
                  renderComponent={props => (
                    <CodeEditor
                      readOnly={readOnly || false}
                      toolbarEndEnhancer={() =>
                        collapsedExerciseOverview
                          ? this.renderViewToggles()
                          : null
                      }
                      {...props}
                    />
                  )}
                  uploadFile={this.handleUploadFile}
                  deleteFile={this.handleDeleteFile}
                />

                {!readOnly && (
                  <div className={styles.runTestsContainer}>
                    <Button
                      disabled={runningTests}
                      danger={!runningTests && Boolean(runTestsError)}
                      onClick={this.handleRunTests}
                    >
                      {runningTests
                        ? "Running.."
                        : runTestsError
                        ? "Try again"
                        : "Run"}
                    </Button>
                  </div>
                )}
              </div>
              <div
                className={styles.testCasesContainer}
                style={{ width: testCasesContainerWidth }}
              >
                <TestCaseSet
                  docked
                  collapsed={collapsedTestCases}
                  testCaseSetID={exercise.test_case_set_id}
                />
              </div>
            </div>
            {!collapsedExerciseOverview && (
              <div className={styles.exerciseOverviewContainer}>
                <div className={styles.exerciseOverviewToolbar}>
                  {this.renderViewToggles()}
                </div>

                <div className={styles.exerciseOverview}>
                  <H3>{exercise.title}</H3>
                  <SpacerSm />
                  <RichHTML>{exercise.description}</RichHTML>
                  <SpacerSm />
                </div>
              </div>
            )}
          </div>
        </div>
      </ReactResizeDetector>
    );
  }
}

export default AttemptPage;
