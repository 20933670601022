// @flow
import * as React from "react";
import styles from "./AddressForm.module.css";
import { Spacer, SpacerSm } from "../spacer";
import Input from "../input";
import Select from "../select";
import Countries from "../../../lib/countries.json";

export type Address = {
  name: string,
  line1: string,
  line2: string,
  city: string,
  state: string,
  zipCode: string,
  countryISO2: string
};

type Props = {
  address: Address,
  business?: ?boolean,
  onChange: (address: Address) => void
};

const AddressForm = (props: Props) => {
  return (
    <div>
      <Input
        wide
        label="Billing Address"
        placeholder={props.business ? "Institution Name" : "Full Name"}
        value={props.address.name}
        onChange={name => props.onChange({ ...props.address, name })}
      />
      <SpacerSm />
      <Input
        wide
        placeholder="Street Address"
        value={props.address.line1}
        onChange={line1 => props.onChange({ ...props.address, line1 })}
      />
      <SpacerSm />
      <Input
        wide
        placeholder={
          props.business
            ? "Suite, Building (Optional)"
            : "Apt, Building (Optional)"
        }
        value={props.address.line2}
        onChange={line2 => props.onChange({ ...props.address, line2 })}
      />
      <SpacerSm />
      <Input
        placeholder="City"
        value={props.address.city}
        onChange={city => props.onChange({ ...props.address, city })}
      />
      <SpacerSm />
      <Input
        placeholder="State"
        value={props.address.state}
        onChange={state => props.onChange({ ...props.address, state })}
      />
      <SpacerSm />
      <div className={styles.formFlex}>
        <Input
          compact
          placeholder="Zip Code"
          value={props.address.zipCode}
          onChange={zipCode => props.onChange({ ...props.address, zipCode })}
        />
        <SpacerSm />
        <Select
          verticalPlacement={"TOP"}
          items={Countries.map(c => ({ id: c.iso2, label: c.name }))}
          value={props.address.countryISO2}
          onSelect={c =>
            props.onChange({ ...props.address, countryISO2: c.id })
          }
        />
      </div>

      <Spacer />
    </div>
  );
};

export default AddressForm;
