// @flow
import * as React from "react";
import queryString from "query-string";
import { keyBy } from "lodash";
import BaseDataPage from "../base-data";
import sortAttempts from "../../lib/sort-attempts";
import AppDataCacheCtx from "../../lib/data-cache";
import ExerciseSetOverview from "../../modules/exercise-set-overview";
import IDEButton from "../../modules/ide-button";
import { Spacer, SpacerLg } from "../../modules/style-guide/spacer";
import ActionBar from "../../modules/style-guide/action-bar";
import Select from "../../modules/style-guide/select";
import TestCaseSet from "../../modules/test-case-set";
import BaseLayoutPage, {
  renderSkeleton,
  PageContent,
  PageAsideContent
} from "../base-layout";
import styles from "./ExerciseOverviewPage.module.css";

import * as ExerciseSetsAPI from "../../lib/api/exercise-sets";
import * as ExercisesAPI from "../../lib/api/exercises";
import * as TestCasesAPI from "../../lib/api/test-cases";
import * as AttemptsAPI from "../../lib/api/attempts";
import * as IDEsAPI from "../../lib/api/ides";
import * as StacksAPI from "../../lib/api/stacks";

import type { SelectItem } from "../../modules/style-guide/select";

type Props = {||};

type State = {|
  stackID: ?string
|};

class ExerciseOverviewPage extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      stackID: null
    };
  }

  fetchData = async () => {
    // $FlowFixMe: add defs for react-router
    const { history, location, match } = this.props;
    const { exerciseSetID, exerciseID, assignmentID } = match.params;

    const [exerciseSet, exercises, stacks] = await Promise.all([
      ExerciseSetsAPI.fetchExerciseSet(exerciseSetID),
      ExercisesAPI.fetchExercises(exerciseSetID),
      StacksAPI.fetchAll()
    ]);

    const exercise = exercises.find(e => e.id === exerciseID);
    if (!exercise) {
      throw Error("exercise is undefined");
    }

    const [attempts, testCases, ides] = await Promise.all([
      AttemptsAPI.fetchUserAttempts(exercise.test_case_set_id),
      TestCasesAPI.fetchTestCases(exercise.test_case_set_id),
      IDEsAPI.fetchIDEs(exercise.test_case_set_id)
    ]);

    const assignmentAttempts = assignmentID
      ? attempts.filter(a => a.assignment_id === assignmentID)
      : attempts.filter(a => !a.assignment_id);

    let resultSets = [];
    if (assignmentAttempts.length) {
      const latestAttempt = sortAttempts(assignmentAttempts)[0];

      resultSets = await AttemptsAPI.fetchResultSets(latestAttempt.id);

      history.push({
        search: `?${queryString.stringify({
          ...queryString.parse(location.search),
          attemptID: latestAttempt.id
        })}`
      });
    }

    return {
      exerciseSets: {
        [exerciseSetID]: exerciseSet
      },
      attempts: keyBy(attempts, a => a.id),
      resultSets: keyBy(resultSets, rs => rs.id),
      exercises: keyBy(exercises, e => e.id),
      testCases: keyBy(testCases, tc => tc.id),
      ides: keyBy(ides, ide => ide.id),
      stacks: keyBy(stacks, s => s.id)
    };
  };

  handleStackIDChange = (item: SelectItem) =>
    this.setState({ stackID: item.id === "NONE" ? null : item.id });

  render() {
    // $FlowFixMe: add defs for react-router
    const { exerciseID, assignmentID } = this.props.match.params;
    const { stackID } = this.state;

    return (
      <BaseDataPage fetchData={this.fetchData} renderSkeleton={renderSkeleton}>
        <AppDataCacheCtx.Consumer>
          {({ cache }) => {
            const exercise = cache.exercises[exerciseID];
            const exerciseSet = cache.exerciseSets[exercise.exercise_set_id];

            if (!exercise) {
              throw Error("exercise is undefined");
            }

            return (
              <BaseLayoutPage>
                <PageContent title={exerciseSet.title}>
                  <ExerciseSetOverview />
                  <SpacerLg />
                  <div className={styles.testCaseSetContainer}>
                    <TestCaseSet testCaseSetID={exercise.test_case_set_id} />
                  </div>
                  <Spacer />
                  <ActionBar error={null}>
                    <Select
                      items={[{ id: "NONE", label: "Select a stack" }].concat(
                        exercise.stack_ids.map(id => ({
                          id,
                          label: cache.stacks[id].name
                        }))
                      )}
                      value={stackID ? stackID : "NONE"}
                      onSelect={this.handleStackIDChange}
                      verticalPlacement="TOP"
                    />
                    <Spacer horizontal />
                    <IDEButton
                      stackID={stackID}
                      exerciseID={exercise.id}
                      assignmentID={assignmentID}
                    />
                  </ActionBar>
                </PageContent>
                <PageAsideContent />
              </BaseLayoutPage>
            );
          }}
        </AppDataCacheCtx.Consumer>
      </BaseDataPage>
    );
  }
}

export default ExerciseOverviewPage;
