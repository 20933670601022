// @flow
import * as React from "react";
import Notification from "../style-guide/notification";
import { Spacer } from "../style-guide/spacer";
import { displayDateTime } from "../../lib/dates";

const startTS = null;
const endTS = null;

const MaintenanceNotification = () => {
  if (!startTS || !endTS) {
    return null;
  }

  return (
    <>
      <Notification>
        AutoGradr will go under scheduled maintenance from{" "}
        {displayDateTime(startTS)} until {displayDateTime(endTS)}. Please plan
        your coursework accordingly.
      </Notification>
      <Spacer />
    </>
  );
};

export default MaintenanceNotification;
