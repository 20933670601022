// @flow
import * as React from "react";
import { NavLink as Link } from "react-router-dom";
import styles from "./BaseLayout.module.css";

type Props = {
  indentLevel: number
};

class NavLink extends React.Component<Props> {
  static defaultProps = {
    indentLevel: 0
  };

  render() {
    const { indentLevel, ...linkProps } = this.props;
    const indentPadding = 20;
    return (
      <Link
        {...linkProps}
        className={styles.navLink}
        activeClassName={styles.activeNavLink}
        style={{ paddingLeft: indentPadding * indentLevel }}
      />
    );
  }
}

export default NavLink;
