// @flow
import * as React from "react";

import "react-quill/dist/quill.snow.css";

type Props = {|
  children: string
|};

class RichHTML extends React.Component<Props> {
  render() {
    return (
      <div className="ql-snow">
        <div
          className="ql-editor"
          style={{ padding: 0 }}
          dangerouslySetInnerHTML={{ __html: this.props.children }}
        />
      </div>
    );
  }
}

export default RichHTML;
