// @flow
import * as React from "react";
import styles from "./Indicators.module.css";

export const NeutralIndicator = () => (
  <span className={`${styles.indicator} ${styles.neutral}`} />
);

export const FailedIndicator = () => (
  <span className={`${styles.indicator} ${styles.failed}`} />
);

export const SuccessIndicator = () => (
  <span className={`${styles.indicator} ${styles.success}`} />
);

export const InProgressIndicator = () => (
  <span className={`${styles.indicator} ${styles.inProgress}`} />
);
