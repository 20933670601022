// @flow
import * as React from "react";
import styles from "./CreateCourseForm.module.css";
import RocketLaunch from "../illustrations/rocket-launch";
import { Spacer, SpacerLg, SpacerSm } from "../style-guide/spacer";
import { H3, Subtitle } from "../style-guide/text";
import type { Course } from "../../lib/api/courses";
import Button from "../style-guide/button";
import { Link } from "react-router-dom";

type Props = {
  course: Course
};

const PaymentComplete = (props: Props) => {
  return (
    <div className={styles.content}>
      <RocketLaunch />
      <Spacer />
      <H3>{props.course.name} is ready to go!</H3>
      <SpacerSm />

      {props.course.payment_method === "UPFRONT" && (
        <Subtitle>
          Your confirmation number is #{props.course.payment_id}.
          <br />
          We’ll email you a receipt shortly.
        </Subtitle>
      )}

      {props.course.payment_method === "ON_ENROLL" && (
        <Subtitle>
          Students will be prompted to pay when they enroll into your course.
          They can pay using a credit or debit card.
        </Subtitle>
      )}

      {props.course.payment_method === "INVOICE" && (
        <Subtitle>
          The invoice is on it's way! Your students will be able to enroll once
          the invoice has been paid. In the meantime, you can continue to set up
          your course.
        </Subtitle>
      )}

      <SpacerLg />
      <div className={styles.actionBar}>
        <Button el={Link} to={`/courses/${props.course.id}/roster`}>
          Go To My Course
        </Button>
      </div>
    </div>
  );
};

export default PaymentComplete;
