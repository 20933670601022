// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import Button from "../style-guide/button";
import type { IDE } from "../../lib/api/ides";

type Props = {|
  ideID: ?string,
  disabled?: boolean,

  onCreateIDE: () => Promise<?IDE>
|};

const IDEButton = (props: Props) =>
  props.ideID ? (
    <Button small el={Link} to={`./../../ides/${props.ideID}/attempt`}>
      Launch IDE
    </Button>
  ) : (
    <Button
      small
      disabled={props.disabled}
      onClick={async () => {
        const ide = await props.onCreateIDE();

        if (!ide) {
          return;
        }

        window.location = `./../../ides/${ide.id}/attempt`;
      }}
    >
      Create IDE
    </Button>
  );

export default IDEButton;
