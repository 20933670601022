// @flow
import makeRequest from "../make-request";

export async function login(email: string, password: string) {
  const url = `/api/auth/email/login`;
  const data = JSON.stringify({
    email,
    password
  });

  const resp = await makeRequest(url, { method: "POST", body: data });

  window.localStorage.setItem(
    "session",
    `${resp.user_uuid}:${resp.session_token}`
  );

  return resp;
}

export async function logout() {
  window.localStorage.removeItem("session");

  return Promise.resolve(true);
}

export async function resetPassword(email: string) {
  const url = `/api/auth/email/reset-password`;
  const data = JSON.stringify({
    email
  });

  return makeRequest(url, { method: "POST", body: data });
}

export async function resendVerificationCode() {
  const url = `/api/auth/email/resend-verification-code`;

  return makeRequest(url, { method: "POST" });
}

export async function signup(email: string, password: string) {
  const url = `/api/auth/email/signup`;
  const data = JSON.stringify({
    email,
    password
  });

  const resp = await makeRequest(url, { method: "POST", body: data });

  window.localStorage.setItem(
    "session",
    `${resp.user_uuid}:${resp.session_token}`
  );

  return resp;
}

export async function verify(verificationCode: string) {
  const url = `/api/auth/email/verify`;
  const data = JSON.stringify({
    verification_code: verificationCode
  });

  return makeRequest(url, { method: "POST", body: data });
}

export async function changePassword(
  email: string,
  oldPassword: string,
  newPassword: string
) {
  const url = `/api/auth/email/change-password`;
  const data = JSON.stringify({
    email,
    old_password: oldPassword,
    new_password: newPassword
  });

  return makeRequest(url, { method: "POST", body: data });
}
