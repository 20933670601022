// @flow
import * as React from "react";
import { uniqueId } from "lodash";
import {
  FaArrowLeft,
  FaArrowRight,
  FaCog,
  FaMinus,
  FaPen,
  FaPlusCircle
} from "react-icons/fa";
import Pre from "../style-guide/pre";
import Popover from "../style-guide/popover";
import Select from "../style-guide/select";
import { SpacerXsm } from "../style-guide/spacer";
import Label from "../style-guide/label";
import CodeEditor from "../style-guide/code-editor";
import styles from "./ConsoleOutput.module.css";

type StdoutLineTestOperator = "IGNORE" | "INPUT" | "EQUALS" | "CONTAINS";

export type StdoutLine = {|
  line_num: number,
  raw_line?: string,

  test_operator: StdoutLineTestOperator,
  test_operand: string
|};

type Props = {|
  stdout: StdoutLine[],
  editable?: boolean,
  onChange: (stdout: StdoutLine[]) => any
|};

type State = {|
  consoleOutputMode: "EDIT" | "CONFIGURE"
|};

const CONFIGURE_LINE_OPTS = [
  {
    id: "INPUT",
    label: (
      <>
        <FaArrowRight /> Set line as user input (stdin)
      </>
    )
  },
  {
    id: "EQUALS",
    label: (
      <>
        <FaArrowLeft /> Require entire line as output
      </>
    )
  },
  {
    id: "IGNORE",
    label: (
      <>
        <FaMinus /> Ignore line
      </>
    )
  }
];

class ConsoleOutput extends React.Component<Props, State> {
  static defaultProps = {
    onChange: () => {}
  };

  id: string;

  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      consoleOutputMode: this.props.stdout.length > 0 ? "CONFIGURE" : "EDIT"
    };
  }

  componentWillMount() {
    this.id = uniqueId("console-output-");
  }

  handleLineSelect = (
    line: StdoutLine,
    test_operator: StdoutLineTestOperator
  ) => {
    const { stdout, onChange } = this.props;
    onChange(
      stdout
        .filter(l => l.line_num !== line.line_num)
        .concat([{ ...line, test_operator }])
    );
  };

  handleToggleMode = () => {
    this.setState(s => ({
      consoleOutputMode:
        s.consoleOutputMode === "CONFIGURE" ? "EDIT" : "CONFIGURE"
    }));
  };

  handleRawStdoutChange = (raw: string) => {
    const stdout: StdoutLine[] = raw.split("\n").map((line, index) => ({
      line_num: index + 1,
      test_operator: "EQUALS",
      test_operand: line
    }));

    this.props.onChange(stdout);
  };

  renderStdoutLine(line: StdoutLine) {
    const displayLine = line.raw_line || line.test_operand;

    if (line.test_operator === "IGNORE") {
      return <span className={styles.stdoutIgnore}>{displayLine}</span>;
    }

    if (line.test_operator === "EQUALS") {
      return <span className={styles.stdoutEquals}>{displayLine}</span>;
    }

    if (line.test_operator === "INPUT") {
      return (
        <Popover
          popoverEl={
            <div className={styles.popover}>
              Line {line.line_num} will be input via stdin
            </div>
          }
        >
          <span className={styles.stdoutInput}>{displayLine}</span>
        </Popover>
      );
    }

    if (line.test_operator === "CONTAINS") {
      const testOpStart = displayLine.indexOf(line.test_operand);
      const testOpLen = line.test_operand.length;
      const displayLinePart1 = displayLine.substring(0, testOpStart);
      const displayLinePart2 = displayLine.substr(testOpStart, testOpLen);
      const displayLinePart3 = displayLine.substring(testOpStart + testOpLen);

      return (
        <span>
          {displayLinePart1}
          <Popover
            popoverEl={
              <div className={styles.popover}>
                Line {line.line_num + 1} must contain "{line.test_operand}"
              </div>
            }
          >
            <span className={styles.stdoutContains}>{displayLinePart2}</span>
          </Popover>
          {displayLinePart3}
        </span>
      );
    }

    return <span>{displayLine}</span>;
  }

  renderEditableConsoleOutput() {
    const lines = this.props.stdout
      .map(l => l.raw_line || l.test_operand)
      .join("\n");

    return (
      <CodeEditor
        code={lines}
        language="plain_text"
        onCodeChange={this.handleRawStdoutChange}
      />
    );
  }

  renderConfigurableConsoleOutput() {
    const { stdout, editable } = this.props;
    return (
      <Pre id={this.id} className={editable ? styles.editableContainer : ""}>
        {stdout.map(l => (
          <div key={l.line_num} className={styles.line}>
            {editable && (
              <span className={styles.configureLine}>
                <Select
                  verticalPlacement={l.line_num <= 8 ? "BOTTOM" : "TOP"}
                  renderSelectedItem={<FaPlusCircle />}
                  items={CONFIGURE_LINE_OPTS}
                  value={"INPUT"}
                  // $FlowFixMe: select item id is a stdout line test operator
                  onSelect={item => this.handleLineSelect(l, item.id)}
                  compact
                  noChevron
                />
              </span>
            )}
            {this.renderStdoutLine(l)}
          </div>
        ))}
      </Pre>
    );
  }

  render() {
    const { editable } = this.props;

    return (
      <>
        <div className={styles.title}>
          <Label htmlFor={this.id}>Console Output</Label>
          {editable && (
            <div className={styles.toggleMode} onClick={this.handleToggleMode}>
              {this.state.consoleOutputMode === "EDIT" ? (
                <>
                  <FaCog /> Setup line-by-line tests
                </>
              ) : (
                <>
                  <FaPen /> Edit raw output
                </>
              )}
            </div>
          )}
        </div>
        <SpacerXsm />
        {editable && this.state.consoleOutputMode === "EDIT"
          ? this.renderEditableConsoleOutput()
          : this.renderConfigurableConsoleOutput()}
      </>
    );
  }
}

export default ConsoleOutput;
