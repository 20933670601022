// @flow
import * as React from "react";
import { Spacer } from "../../style-guide/spacer";
import Input from "../../style-guide/input";
import Button from "../../style-guide/button";
import styles from "./WebUITests.module.css";

type Props = {|
  onCreate: (name: string) => any
|};

type State = {|
  name: string
|};

class CreateSequence extends React.Component<Props, State> {
  state = {
    name: ""
  };

  reset() {
    this.setState({
      name: ""
    });
  }

  handleNameChange = (name: string) => this.setState({ name });

  handleCreate = () => {
    const { onCreate } = this.props;
    const { name } = this.state;
    if (!name) {
      return;
    }
    onCreate(name);
    this.reset();
  };

  render() {
    const { name } = this.state;
    return (
      <>
        <div className={styles.newSequence}>
          <Input
            label="Sequence name"
            placeholder="User sign up flow"
            value={name}
            onChange={this.handleNameChange}
            onEnter={this.handleCreate}
          />
          <Spacer />
          <Button small secondary onClick={this.handleCreate}>
            Create
          </Button>
        </div>
        <Spacer />
      </>
    );
  }
}

export default CreateSequence;
