// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import { FaPen, FaRegTrashAlt } from "react-icons/fa";
import List from "../../../modules/style-guide/list";
import styles from "./TestSuiteEditor.module.css";

import type { TestCase } from "../../../lib/api/test-cases";
import ConfirmAction from "../../../modules/style-guide/confirm-action";

type Props = {|
  testCases: {
    ...TestCase,
    deleting: boolean,
    deleteError: ?string
  }[],

  onDelete: (testCaseID: string) => Promise<any>
|};

class TestSuiteEditor extends React.Component<Props> {
  render() {
    const { testCases, onDelete } = this.props;

    return (
      <List
        items={testCases.map(tc => ({
          id: tc.id,
          label: tc.deleteError ? `${tc.name} (${tc.deleteError})` : tc.name,
          actions: [
            () => (
              <Link to={`${tc.id}/edit`}>
                <FaPen className={styles.action} />
              </Link>
            ),
            () => (
              <ConfirmAction
                title="Delete test case"
                message="All attempt results for this test case will also be lost. This action cannot be undone."
                placement="RIGHT"
                onConfirm={() => {
                  if (!tc.deleting) {
                    return onDelete(tc.id);
                  }
                  return Promise.resolve();
                }}
              >
                <FaRegTrashAlt
                  className={`
                    ${styles.deleteAction}
                    ${tc.deleting ? styles.disabled : ""}
                  `}
                />
              </ConfirmAction>
            )
          ]
        }))}
      />
    );
  }
}

export default TestSuiteEditor;
