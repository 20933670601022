// @flow
import * as React from "react";
import Button from "../../modules/style-guide/button";
import * as AssignmentsAPI from "../../lib/api/assignments";
import AppDataCacheCtx from "../../lib/data-cache";

type Props = {
  assignmentID: string
};

const StartAssignmentButton = (props: Props) => {
  const { updateCache } = React.useContext(AppDataCacheCtx);
  const [isStarting, setIsStarting] = React.useState(false);
  const [error, setError] = React.useState(false);

  const onStartAssignment = async () => {
    setIsStarting(true);
    setError(false);

    try {
      await AssignmentsAPI.beginAssignmentSession(props.assignmentID);

      const [assignment] = await AssignmentsAPI.fetchAssignmentWithExerciseSets(
        props.assignmentID
      );

      updateCache({
        assignments: {
          [assignment.id]: assignment
        }
      });
    } catch (e) {
      setError(true);
    }
  };

  return (
    <Button
      small
      secondary
      danger={error}
      disabled={isStarting}
      onClick={onStartAssignment}
    >
      {error ? "Try Again" : isStarting ? "Starting now.." : "Start now"}
    </Button>
  );
};

export default StartAssignmentButton;
