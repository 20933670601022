// @flow
import { merge } from "lodash";

type MakeRequestOpts = {|
  method?: "GET" | "POST" | "DELETE" | "PUT",
  body?: string | Blob,
  headers?: {
    [string]: string
  }
|};

const defaultOpts: MakeRequestOpts = {
  method: "GET"
};

export class UnauthorizedError extends Error {}

export class BadRequestError extends Error {}

async function makeRequest(
  url: string,
  optOverrides: ?MakeRequestOpts
): Promise<any> {
  const opts: MakeRequestOpts = optOverrides
    ? { ...defaultOpts, ...optOverrides }
    : defaultOpts;

  const session = window.localStorage.getItem("session");
  const headers = session
    ? { Authorization: `Basic ${window.btoa(session)}` }
    : {};

  const resp = await fetch(url, merge({ headers }, opts));

  if (resp.status === 400) {
    const { error } = await resp.json();
    throw new BadRequestError(error);
  }

  if (resp.status === 401) {
    throw new UnauthorizedError();
  }

  if (!resp.ok) {
    throw new Error(`Status Code ${resp.status}`);
  }

  const contentType = resp.headers.get("Content-Type") || "";

  if (contentType.startsWith("text/")) {
    return resp.text();
  } else if (contentType === "application/json") {
    return resp.json();
  } else {
    return resp.blob();
  }
}

export default makeRequest;
