// @flow
import * as React from "react";
import { H3 } from "../../modules/style-guide/text";
import { Spacer, SpacerSm } from "../../modules/style-guide/spacer";
import Card from "../../modules/style-guide/card";
import Input from "../../modules/style-guide/input";
import Select from "../../modules/style-guide/select";
import ActionBar from "../../modules/style-guide/action-bar";
import Button from "../../modules/style-guide/button";
import styles from "./Profile.module.css";

type Props = {|
  name: string,
  mossUserID: ?string,
  role: "INSTRUCTOR" | "STUDENT",
  onSave: (name: string, mossUserID: ?string) => Promise<any>
|};

type State = {|
  name: string,
  mossUserID: ?string,

  saving: boolean,
  saveError: ?string
|};

class UpdateProfile extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      name: this.props.name,
      mossUserID: this.props.mossUserID,

      saving: false,
      saveError: null
    };
  }

  handleSave = async () => {
    this.setState({ saving: true, saveError: null });
    try {
      await this.props.onSave(this.state.name, this.state.mossUserID || null);
      this.setState({ saving: false });
    } catch (e) {
      this.setState({ saving: false, saveError: "Failed to save profile" });
    }
  };

  handleNameChange = (name: string) => this.setState({ name });

  handleMossUserIDChange = (mossUserID: string) =>
    this.setState({ mossUserID });

  render() {
    const { role } = this.props;
    const { name, mossUserID, saving, saveError } = this.state;

    return (
      <>
        <H3>Update</H3>
        <Spacer />
        <Card className={styles.padded}>
          <Input
            label="Name"
            value={name}
            onEnter={this.handleSave}
            onChange={this.handleNameChange}
          />
          <SpacerSm />
          <Select
            disabled
            label="Role"
            items={[
              { id: "INSTRUCTOR", label: "Instructor" },
              { id: "STUDENT", label: "Student" }
            ]}
            value={role}
            onSelect={() => {}}
          />
          <SpacerSm />
          <Input
            label="Moss User ID"
            value={mossUserID}
            onEnter={this.handleSave}
            onChange={this.handleMossUserIDChange}
          />
          <SpacerSm />
          <ActionBar error={saveError}>
            <Button small onClick={this.handleSave}>
              {saving ? "Saving Changes.." : "Save Changes"}
            </Button>
          </ActionBar>
        </Card>
      </>
    );
  }
}

export default UpdateProfile;
