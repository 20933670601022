// @flow
import * as React from "react";
import { Route } from "react-router-dom";
import { values } from "lodash";
import { getSessionProfile } from "../../lib/session";
import { withAppDataCacheCtx } from "../../lib/data-cache";
import { Spacer } from "../../modules/style-guide/spacer";
import NavLink from "./nav-link";

import type { AppDataCache } from "../../lib/data-cache";
import type { Course } from "../../lib/api/courses";

type Props = {|
  cache: AppDataCache
|};

class CourseSubNav extends React.Component<Props> {
  render() {
    const { cache } = this.props;
    const courses: Course[] = values(cache.courses);

    return (
      <>
        {courses.length ? null : "No courses"}
        {courses.map((c, i) => (
          <React.Fragment key={c.id}>
            <NavLink to={`/courses/${c.id}/assignments`}>{c.name}</NavLink>
            <Route
              path={`/courses/${c.id}`}
              render={() => (
                <>
                  <NavLink indentLevel={1} to={`/courses/${c.id}/assignments`}>
                    Assignments
                  </NavLink>
                  {getSessionProfile(cache).role === "INSTRUCTOR" ? (
                    <>
                      <NavLink indentLevel={1} to={`/courses/${c.id}/roster`}>
                        Roster
                      </NavLink>
                      <NavLink indentLevel={1} to={`/courses/${c.id}/manage`}>
                        Manage
                      </NavLink>
                    </>
                  ) : null}
                  {i < courses.length - 1 ? <Spacer /> : null}
                </>
              )}
            />
          </React.Fragment>
        ))}
      </>
    );
  }
}

export default withAppDataCacheCtx(CourseSubNav);
