// @flow
import * as React from "react";
import styles from "./CreateCourseForm.module.css";
import { H3, Subtitle } from "../style-guide/text";
import { SpacerLg, SpacerSm, SpacerXLg } from "../style-guide/spacer";
import Input from "../style-guide/input";
import DatePicker from "../style-guide/date-picker";
import Button from "../style-guide/button";
import type { Course } from "../../lib/api/courses";
import { apiDate, parseDate } from "../../lib/dates";

type Props = {|
  course: $Shape<Course>,
  onNext: (name: string, startOn: string, endOn: string) => void
|};

const MetadataEditor = (props: Props) => {
  const [courseName, setCourseName] = React.useState(props.course.name || "");
  const [startOn, setStartOn] = React.useState(
    props.course.start_on ? parseDate(props.course.start_on) : new Date()
  );
  const [endOn, setEndOn] = React.useState(
    props.course.end_on ? parseDate(props.course.end_on) : new Date()
  );

  const isValid = React.useCallback(
    () => courseName.length > 0 && endOn > startOn,
    [courseName, startOn, endOn]
  );

  return (
    <div className={styles.content}>
      <H3>Create new course</H3>
      <SpacerSm />
      <Subtitle>
        Name your new course to get started. Students will be able to enroll in
        this course using an invite code.
      </Subtitle>
      <SpacerLg />
      <div className={styles.form}>
        <Input
          wide
          label="Course name"
          value={courseName}
          onChange={setCourseName}
        />
        <SpacerSm />
        <div className={styles.flex}>
          <DatePicker
            label="Start date"
            selected={startOn}
            onChange={setStartOn}
            hideTimeSelect
          />
          <DatePicker
            label="End date"
            selected={endOn}
            onChange={setEndOn}
            hideTimeSelect
          />
        </div>
      </div>
      <SpacerXLg />
      <div className={styles.actionBar}>
        <Button
          wide
          disabled={!isValid()}
          onClick={() =>
            props.onNext(courseName, apiDate(startOn), apiDate(endOn))
          }
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default MetadataEditor;
