// @flow
import makeRequest from "../make-request";

export type Stack = {|
  id: string,
  name: string,
  timeout: number,
  workspace_id: string,
  tags: string[]
|};

export async function fetchAll(): Promise<Stack[]> {
  const url = `/api/stacks`;

  return makeRequest(url);
}
