// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import AnonymousLayout1, {
  PageContent,
  PageFooter
} from "../anonymous-layout-1";
import ResetPasswordForm from "./reset-password-form";
import styles from "../signup/Signup.module.css";

import * as AuthAPI from "../../lib/api/auth";

type Props = {||};

type State = {|
  resettingPassword: boolean,
  resetPasswordError: ?string
|};

class ResetPasswordPage extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      resettingPassword: false,
      resetPasswordError: null
    };
  }

  handleResetPassword = async (email: string) => {
    try {
      this.setState({ resettingPassword: true, resetPasswordError: null });

      await AuthAPI.resetPassword(email);

      this.setState({ resettingPassword: false });
    } catch (e) {
      this.setState({
        resettingPassword: false,
        resetPasswordError: "Failed to reset password"
      });
    }
  };

  render() {
    return (
      <AnonymousLayout1
        title="Welcome to AutoGradr"
        subtitle="Reset your password"
      >
        <PageContent>
          <ResetPasswordForm
            {...this.state}
            onResetPassword={this.handleResetPassword}
          />
        </PageContent>

        <PageFooter>
          <div className={styles.center}>
            <Link to="/login">Login</Link>
          </div>
        </PageFooter>
      </AnonymousLayout1>
    );
  }
}

export default ResetPasswordPage;
