// @flow
import * as React from "react";
import { Spacer } from "../../modules/style-guide/spacer";
import Button from "../../modules/style-guide/button";
import { Error, RunningText } from "../../modules/style-guide/text";
import styles from "./SignupSurvey.module.css";
import TextArea from "../../modules/style-guide/text-area";

type Props = {|
  sending: boolean,
  sendError: ?string,

  onSend: (text: string) => any
|};

type State = {|
  text: string
|};

class SignupSurveyForm extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      text: ""
    };
  }

  handleSend = () => {
    const { sending, onSend } = this.props;
    const { text } = this.state;

    if (sending) {
      return;
    }

    onSend(text);
  };

  handleTextChange = (text: string) => this.setState({ text });

  render() {
    const { sending, sendError } = this.props;
    const { text } = this.state;

    return (
      <>
        <RunningText className={styles.center}>
          Tell us a little about the courses you teach and the assignments you
          wish to automate on AutoGradr. If you have links to a public course
          website, please share if you can.
        </RunningText>
        <Spacer />
        <TextArea value={text} onChange={this.handleTextChange} />

        <Spacer />
        <Button onClick={this.handleSend}>
          {sending ? "Sending.." : "Next"}
        </Button>
        {sendError ? (
          <div>
            <Spacer />
            <Error>{sendError}</Error>
          </div>
        ) : null}
      </>
    );
  }
}

export default SignupSurveyForm;
