// @flow
import * as React from "react";
import BaseDataPage from "../base-data";
import BaseLayoutPage, {
  renderSkeleton,
  PageAsideContent,
  PageContent
} from "../base-layout";
import AppDataCacheCtx from "../../lib/data-cache";
import { Spacer } from "../../modules/style-guide/spacer";
import UpdateProfile from "./update-profile";
import ChangePassword from "./change-password";

import * as ProfileAPI from "../../lib/api/profile";
import * as AuthAPI from "../../lib/api/auth";

import type {
  AppDataCache,
  updateAppDataCacheFunc
} from "../../lib/data-cache";

type Props = {||};

class ProfilePage extends React.Component<Props> {
  fetchData = async () => {
    return {};
  };

  handleProfileSave = (
    cache: AppDataCache,
    updateCache: updateAppDataCacheFunc
  ) => async (name: string, mossUserID: ?string) => {
    await ProfileAPI.updateProfile(name, mossUserID);

    if (!cache.session) {
      return;
    }

    updateCache({
      profiles: {
        [cache.session.profileID]: {
          name
        }
      }
    });
  };

  handleChangePassword = (email: string) => async (
    oldPassword: string,
    newPassword: string
  ): Promise<any> => {
    return AuthAPI.changePassword(email, oldPassword, newPassword);
  };

  render() {
    return (
      <BaseDataPage fetchData={this.fetchData} renderSkeleton={renderSkeleton}>
        <AppDataCacheCtx.Consumer>
          {({ cache, updateCache }) => {
            if (!cache.session) {
              throw new Error("session is undefined");
            }

            const profile = cache.profiles[cache.session.profileID];

            return (
              <BaseLayoutPage>
                <PageContent title="Profile">
                  <UpdateProfile
                    name={profile.name}
                    role={profile.role}
                    mossUserID={profile.moss_user_id}
                    onSave={this.handleProfileSave(cache, updateCache)}
                  />
                  <Spacer />
                  <ChangePassword
                    onChangePassword={this.handleChangePassword(profile.email)}
                  />
                </PageContent>
                <PageAsideContent />
              </BaseLayoutPage>
            );
          }}
        </AppDataCacheCtx.Consumer>
      </BaseDataPage>
    );
  }
}

export default ProfilePage;
