// @flow
import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import queryString from "query-string";
import { Tab, Tabs, TabList, TabPanel } from "../style-guide/tabs";
import TestCase from "../test-case";
import ResultSet from "../result-set";
import ResultSetLogs from "../result-set-logs";
import ResultSetIndicator from "../result-set-indicator";
import styles from "./TestCaseSet.module.css";

import type { TestCase as TestCaseT } from "../../lib/api/test-cases";
import type { ResultSet as ResultSetT } from "../../lib/api/attempts";

import "react-tabs/style/react-tabs.css";

type Props = {|
  testCases: TestCaseT[],
  resultSets: ResultSetT[],
  selectedTestCaseID: string,
  view: "test_case" | "my_results" | "logs",
  docked?: boolean,
  collapsed?: boolean,

  onTestCaseChange: (testCase: TestCaseT) => any
|};

class TestCaseSet extends React.Component<Props> {
  handleSelect = (index: number) => {
    const { testCases, onTestCaseChange } = this.props;
    const selectedTestCase = testCases[index];

    if (!selectedTestCase) {
      throw Error("selected test case is undefined");
    }

    onTestCaseChange(selectedTestCase);
  };

  renderTabPanel(testCase: TestCaseT) {
    const { resultSets, view } = this.props;

    switch (view) {
      case "my_results": {
        const resultSet = resultSets.find(
          rs => rs.test_case_id === testCase.id
        );

        if (testCase.ui_widget === "HIDDEN") {
          return <div>The results for this test case are hidden</div>;
        }

        if (!resultSet) {
          return (
            <div>
              No results found for this test case. Try making a new attempt
            </div>
          );
        }

        return <ResultSet resultSetID={resultSet.id} />;
      }
      case "logs": {
        const resultSet = resultSets.find(
          rs => rs.test_case_id === testCase.id
        );

        if (testCase.ui_widget === "HIDDEN") {
          return <div>The logs for this test case are hidden</div>;
        }

        if (!resultSet) {
          return (
            <div>
              No logs found for this test case. Try making a new attempt
            </div>
          );
        }

        return <ResultSetLogs resultSetID={resultSet.id} />;
      }
      case "test_case":
      default:
        return <TestCase testCaseID={testCase.id} />;
    }
  }

  renderViewPicker() {
    // $FlowFixMe: add defs for react-router
    const { location, view, resultSets } = this.props;

    const link = (view: string) =>
      `${location.pathname}?${queryString.stringify({
        ...queryString.parse(location.search),
        view
      })}`;

    const className = (forView: string) =>
      forView === view ? styles.activeView : styles.inactiveView;

    if (!resultSets.length) {
      return null;
    }

    return (
      <span>
        <Link replace to={link("test_case")} className={className("test_case")}>
          Test Case
        </Link>{" "}
        |{" "}
        <Link
          replace
          to={link("my_results")}
          className={className("my_results")}
        >
          My Results
        </Link>{" "}
        |{" "}
        <Link replace to={link("logs")} className={className("logs")}>
          Logs
        </Link>
      </span>
    );
  }

  render() {
    const {
      testCases,
      resultSets,
      selectedTestCaseID,
      docked,
      collapsed
    } = this.props;

    let selectedTestCaseIndex = Math.max(
      testCases.findIndex(tc => tc.id === selectedTestCaseID),
      0
    );

    return (
      <Tabs selectedIndex={selectedTestCaseIndex} onSelect={this.handleSelect}>
        <div className={styles.tabListContainer}>
          <TabList>
            {testCases.map(tc => {
              const resultSet = resultSets.find(
                rs => rs.test_case_id === tc.id
              );

              if (!resultSet) {
                return <Tab key={tc.id}>{tc.name}</Tab>;
              }

              return (
                <Tab key={tc.id}>
                  <ResultSetIndicator resultSetID={resultSet.id} /> {tc.name}
                </Tab>
              );
            })}
          </TabList>
          <div>{this.renderViewPicker()}</div>
        </div>

        {testCases.map(tc => (
          <TabPanel key={tc.id}>
            <div
              className={[
                styles.tabPanelContainer,
                docked ? styles.docked : "",
                collapsed ? styles.collapsed : ""
              ].join(" ")}
            >
              {this.renderTabPanel(tc)}
            </div>
          </TabPanel>
        ))}
      </Tabs>
    );
  }
}

export default withRouter(TestCaseSet);
