// @flow
import * as React from "react";
import HTMLEditor from "../../../modules/style-guide/html-editor";
import Button from "../../../modules/style-guide/button";
import Input from "../../../modules/style-guide/input";
import { Spacer } from "../../../modules/style-guide/spacer";
import styles from "./../EditExercise.module.css";

type Props = {|
  title: string,
  description: string,
  onSave: (title: string, description: string) => any,
  saving: boolean,
  saveError: ?string
|};

type State = {|
  title: string,
  description: string
|};

class TitleDescriptionEditor extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      title: this.props.title,
      description: this.props.description
    };
  }

  handleTitleChange = (title: string) => this.setState({ title });

  handleChange = (html: string) => this.setState({ description: html });

  handleSave = () =>
    this.props.onSave(this.state.title, this.state.description);

  render() {
    const { saving, saveError } = this.props;
    const { title, description } = this.state;

    return (
      <div>
        <Input label="Title" value={title} onChange={this.handleTitleChange} />
        <Spacer />
        <HTMLEditor html={description} onChange={this.handleChange} />
        <Spacer />

        <div className={styles.actionBar}>
          <div>{saveError}</div>
          <Button small onClick={this.handleSave}>
            {saving ? "Saving.." : "Save & Next"}
          </Button>
        </div>
      </div>
    );
  }
}

export default TitleDescriptionEditor;
