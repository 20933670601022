// @flow
import * as React from "react";
import { uniqueId } from "lodash";
import Label from "../label";
import styles from "./TextArea.module.css";

type Props = {
  label?: string,
  onChange?: (val: string) => any
};

class TextArea extends React.Component<Props> {
  id: string;

  componentWillMount() {
    this.id = uniqueId("frm-text-area-");
  }

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.props.onChange && this.props.onChange(e.target.value);
  };

  render() {
    const { label, ...textAreaProps } = this.props;
    return (
      <div className={styles.container}>
        {label && <Label htmlFor={this.id}>{label}</Label>}
        <textarea
          {...textAreaProps}
          id={this.id}
          className={styles.text}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

export default TextArea;
