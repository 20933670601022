// @flow
import * as React from "react";
import styles from "./Label.module.css";

type Props = {|
  htmlFor: string,
  children: string
|};

class Label extends React.Component<Props> {
  render() {
    const { children, htmlFor } = this.props;
    return (
      <label htmlFor={htmlFor} className={styles.label}>
        {children}
      </label>
    );
  }
}

export default Label;
