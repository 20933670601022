import React from "react";

const Logo = props => {
  return (
    <svg width={53} height={58} {...props}>
      <defs>
        <path id="prefix__b" d="M5.713 8.77h28.008v9.48H5.713z" />
        <filter
          x="-46.4%"
          y="-116%"
          width="192.8%"
          height="374.3%"
          filterUnits="objectBoundingBox"
          id="prefix__a"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={4}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M37.377 26.156l-9.529-9.53-9.497 9.498-17.693-.03L26.394 0l26.203 26.182-15.22-.026z"
          fill="#0B35CA"
        />
        <g fill="#0B35CA">
          <path d="M7.134 19.39L.431 26.093l6.703 6.704 6.703-6.704zM26.524 0l-6.703 6.703 6.703 6.703 6.703-6.703z" />
        </g>
        <path
          fill="#0B35CA"
          d="M39.403 12.927l6.703 6.703-6.703 6.703-6.704-6.703z"
        />
        <path
          fill="#0B35CA"
          d="M32.94 6.463l6.702 6.704-6.703 6.703-6.703-6.703z"
        />
        <path
          fill="#0B35CA"
          d="M26.476 0l6.703 6.703-6.703 6.703-6.703-6.703z"
        />
        <path
          fill="#8B94EE"
          d="M20.06 6.463l6.704 6.704-6.703 6.703-6.703-6.703z"
        />
        <g>
          <path
            fill="#8B94EE"
            d="M13.597 26.333L6.894 19.63l6.703-6.703 6.704 6.703z"
          />
          <path
            fill="#0B35CA"
            d="M26.524 52.138l-6.703-6.703 6.703-6.703 6.703 6.703z"
          />
        </g>
        <g>
          <g transform="scale(1 -1) rotate(45 89.741 3.08)">
            <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
            <use fill="#8B94EE" xlinkHref="#prefix__b" />
          </g>
          <path
            fill="#FFF"
            d="M45.866 32.748l6.703-6.703-6.703-6.703-6.703 6.703z"
          />
          <path
            fill="#0B35CA"
            d="M39.403 26.285l6.703-6.704-6.703-6.703-6.704 6.703z"
          />
        </g>
        <path
          fill="#8B94EE"
          d="M6.83 19.516L.125 26.22 26.33 52.423l6.704-6.703z"
        />
      </g>
    </svg>
  );
};

export default Logo;
