// @flow
import * as React from "react";
import { sortBy } from "lodash";
import OutputMatching from "./output-matching-component";

import type { WorkspaceRenderProps } from "../../workspace";
import type { StdoutLine } from "../../console-output";

type Props = {|
  ...WorkspaceRenderProps,
  editable?: boolean
|};

const argsFilePath = "/args";
const stdoutFilePath = "/stdout.json";
const stdinFilePath = "/stdin";

class OutputMatchingContainer extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    if (prevProps.loading && !this.props.loading && this.props.files.length) {
      this.loadArgs();
      this.loadStdout();
    }
  }

  loadArgs() {
    const { files, loadFile } = this.props;
    const argsFile = files.find(p => p.path === argsFilePath);

    if (!argsFile) {
      return;
    }

    loadFile(argsFile.path);
  }

  loadStdout() {
    const { files, loadFile } = this.props;
    const stdoutFile = files.find(p => p.path === stdoutFilePath);

    if (!stdoutFile) {
      return;
    }

    loadFile(stdoutFile.path);
  }

  handleArgsChange = (args: string) => {
    const file = this.props.files.find(p => p.path === argsFilePath);

    if (!file) {
      this.props.newFile(argsFilePath, args);
      return;
    }

    this.props.updateFileData(argsFilePath, args);
  };

  handleStdoutChange = (stdout: StdoutLine[]) => {
    const sortedStdout: StdoutLine[] = sortBy(stdout, l => l.line_num);
    const inputLines = sortedStdout
      .filter(l => l.test_operator === "INPUT")
      .map(l => l.test_operand)
      .join("\n");

    this.props.updateFileData(stdinFilePath, inputLines + "\n");
    this.props.updateFileData(
      stdoutFilePath,
      JSON.stringify(sortedStdout, null, 2)
    );
  };

  handleInputFileDelete = (path: string) => {
    this.props.deleteFile(`/in_files/${path}`);
  };

  getFilePath = (f: File): string => {
    // $FlowFixMe
    const path: string = f.path || f.name;
    if (path.indexOf("/") === 0) {
      return path;
    }
    return "/" + path;
  };

  handleInputFilesUpload = (files: File[]) => {
    files.forEach(f => {
      const path = "/in_files" + this.getFilePath(f);
      this.props.newFile(path, f);
    });
  };

  handleOutputFileDelete = (path: string) => {
    this.props.deleteFile(`/out_files/${path}`);
  };

  handleOutputFilesUpload = (files: File[]) => {
    files.forEach(f => {
      const path = "/out_files" + this.getFilePath(f);
      this.props.newFile(path, f);
    });
  };

  handleSave = () => {
    if (this.getIsSaving()) {
      return;
    }

    this.props.files
      .filter(f => !f.saved)
      .forEach(f => this.props.saveFile(f.path));
  };

  getArgs = (): string => {
    const file = this.props.files.find(p => p.path === argsFilePath);

    if (!file || file.loading || file.loadError) {
      return "";
    }

    return typeof file.data === "string" ? file.data : "";
  };

  getStdout = (): StdoutLine[] => {
    const file = this.props.files.find(p => p.path === stdoutFilePath);

    if (!file || file.loading || file.loadError) {
      return [];
    }

    const { data } = file;

    if (!data || typeof data !== "string") {
      return [];
    }

    return sortBy(JSON.parse(data), l => l.line_num);
  };

  getInputFileLinks = (): string[] =>
    this.props.files
      .filter(f => !f.deleted)
      .map(f => f.path)
      .filter(f => f.startsWith("/in_files/"))
      .map(f => `/api/blobs/${this.props.workspaceID}${f}`);

  getOutputFileLinks = (): string[] =>
    this.props.files
      .filter(f => !f.deleted)
      .map(f => f.path)
      .filter(f => f.startsWith("/out_files/"))
      .map(f => `/api/blobs/${this.props.workspaceID}${f}`);

  getIsLoading = () => {
    const { loading, files } = this.props;
    const argsFile = files.find(f => f.path === argsFilePath);
    const stdoutFile = files.find(f => f.path === stdoutFilePath);

    return (
      loading ||
      !stdoutFile ||
      !stdoutFile.data ||
      stdoutFile.loading ||
      (argsFile && argsFile.loading)
    );
  };

  getIsSaving = () => this.props.files.filter(f => f.saving).length > 0;

  getSaveError = (): ?string => {
    return this.props.files.filter(f => f.saveError).length > 0
      ? "Failed to save some changes"
      : null;
  };

  render() {
    const { editable } = this.props;

    if (this.getIsLoading()) {
      return <div>Loading..</div>;
    }

    return (
      <OutputMatching
        inputFileLinks={this.getInputFileLinks()}
        outputFileLinks={this.getOutputFileLinks()}
        args={this.getArgs()}
        stdout={this.getStdout()}
        editable={editable}
        onArgsChange={this.handleArgsChange}
        onStdoutChange={this.handleStdoutChange}
        onInputFileDelete={this.handleInputFileDelete}
        onInputFilesUpload={this.handleInputFilesUpload}
        onOutputFileDelete={this.handleOutputFileDelete}
        onOutputFilesUpload={this.handleOutputFilesUpload}
        onSave={this.handleSave}
        saving={this.getIsSaving()}
        saveError={this.getSaveError()}
      />
    );
  }
}

export default OutputMatchingContainer;
