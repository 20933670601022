// @flow
import * as React from "react";
import { uniqueId } from "lodash";
import Popover from "../../style-guide/popover";
import Label from "../label";
import { FaChevronDown } from "react-icons/fa";

import styles from "./Select.module.css";

export type SelectItem = {|
  id: string,
  label: React.Node
|};

type Props = {|
  items: SelectItem[],
  value: string,
  verticalPlacement?: "TOP" | "BOTTOM",
  onSelect: (item: SelectItem) => any,
  renderSelectedItem?: React.Node | ((item: SelectItem, index: number) => any),

  label?: string,
  compact?: boolean,
  noChevron?: boolean,
  disabled?: boolean
|};

class Select extends React.Component<Props> {
  popover: ?Popover;
  id: string;

  componentWillMount() {
    this.id = uniqueId("frm-select-");
  }

  handleItemSelect = (item: SelectItem) => {
    this.props.onSelect(item);

    if (this.popover) {
      this.popover.hide();
    }
  };

  renderItems = () => (
    <div className={styles.itemsContainer}>
      {this.props.items.map(i => (
        <div
          key={i.id}
          className={styles.item}
          onClick={() => this.handleItemSelect(i)}
        >
          {i.label}
        </div>
      ))}
    </div>
  );

  render() {
    const {
      label,
      items,
      value,
      compact,
      noChevron,
      disabled,
      renderSelectedItem,
      verticalPlacement
    } = this.props;
    const selectedValue = items.find(i => i.id === value);
    const selectedValueIndex = items.findIndex(i => i.id === value);

    if (!selectedValue) {
      throw Error("Selected value is undefined");
    }

    return (
      <Popover
        ref={r => (this.popover = r)}
        disabled={disabled}
        popoverEl={this.renderItems()}
        verticalPlacement={verticalPlacement}
      >
        {label && <Label htmlFor={this.id}>{label}</Label>}
        <div
          id={this.id}
          className={`
            ${compact ? styles.compactLabel : styles.label}
            ${disabled ? styles.disabled : ""}
          `.trim()}
        >
          {renderSelectedItem
            ? typeof renderSelectedItem === "function"
              ? renderSelectedItem(selectedValue, selectedValueIndex)
              : renderSelectedItem
            : selectedValue.label}{" "}
          {!noChevron && (
            <FaChevronDown
              className={compact ? styles.compactIcon : styles.icon}
            />
          )}
        </div>
      </Popover>
    );
  }
}

export default Select;
