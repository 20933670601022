// @flow
import * as React from "react";
import ReactDropzone from "react-dropzone";
import { FaCloudUploadAlt, FaTrashAlt } from "react-icons/fa";
import styles from "./Dropzone.module.css";

type Props = {|
  paths: string[],
  onDelete: (path: string) => any,
  onUpload: (files: File[]) => any
|};

class Dropzone extends React.Component<Props> {
  handleDrop = (acceptedFiles: File[]) => {
    this.props.onUpload(acceptedFiles);
  };

  handleDelete = (path: string) => (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    this.props.onDelete(path);
  };

  renderEmptyState() {
    return (
      <>
        <FaCloudUploadAlt className={styles.uploadIcon} />
        Upload files
      </>
    );
  }

  renderUploadFiles() {
    const { paths } = this.props;
    return (
      <>
        <div className={styles.fileList}>
          {paths.map(p => (
            <div key={p} className={styles.fileItem}>
              {p}{" "}
              <FaTrashAlt
                className={styles.trashIcon}
                onClick={this.handleDelete(p)}
              />
            </div>
          ))}
        </div>
        <div className={styles.uploadBar}>
          <FaCloudUploadAlt />
          Upload files
        </div>
      </>
    );
  }

  render() {
    const { paths } = this.props;
    return (
      <ReactDropzone onDrop={this.handleDrop}>
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              className={`
              ${styles.dropzone}
              ${isDragActive ? styles.active : ""}
              ${paths.length ? "" : styles.empty}`}
            >
              <input {...getInputProps()} />
              {paths.length
                ? this.renderUploadFiles()
                : this.renderEmptyState()}
            </div>
          );
        }}
      </ReactDropzone>
    );
  }
}

export default Dropzone;
