// @flow
import { flatten } from "lodash";
import makeRequest from "../make-request";

import type { Assignment } from "../assignments";
import type { ExerciseSet } from "../exercise-sets";
import type { Profile } from "../profile";

export type Course = {|
  id: string,
  name: string,
  instructor_profile_ids?: string[],
  student_profile_ids?: string[],
  tier: "BASIC" | "PRO",
  start_on: string,
  end_on: string,
  is_active: boolean,
  payment_method: "NONE" | "UPFRONT" | "ON_ENROLL",
  seats: number,
  payment_id?: string
|};

export async function enrollInCourse(
  courseID: string,
  paymentID: ?string
): Promise<any> {
  const url = `/api/courses/${courseID}/enroll`;
  const data = JSON.stringify({
    payment_id: paymentID
  });

  return makeRequest(url, { method: "POST", body: data });
}

export async function enrollInstructor(
  courseID: string,
  email: string
): Promise<Profile> {
  const url = `/api/courses/${courseID}/enroll-instructor`;
  const data = JSON.stringify({ email });

  return makeRequest(url, { method: "POST", body: data });
}

export async function createCourse(course: $Shape<Course>): Promise<Course> {
  const url = `/api/courses`;
  const data = JSON.stringify(course);

  return makeRequest(url, { method: "POST", body: data });
}

export async function fetchCourse(courseID: string): Promise<Course> {
  const url = `/api/courses/${courseID}`;

  return makeRequest(url);
}

export async function updateCourse(
  courseID: string,
  name: string
): Promise<Course> {
  const url = `/api/courses/${courseID}`;
  const data = JSON.stringify({
    name
  });

  return makeRequest(url, { method: "POST", body: data });
}

export async function deleteCourse(courseID: string): Promise<Course> {
  const url = `/api/courses/${courseID}`;

  return makeRequest(url, { method: "DELETE" });
}

export async function fetchCourses(): Promise<Course[]> {
  const url = `/api/courses`;

  return makeRequest(url);
}

export async function fetchCourseRoster(courseID: string): Promise<Profile[]> {
  const url = `/api/courses/${courseID}/roster`;

  return makeRequest(url);
}

export async function fetchCourseInstructors(
  courseID: string
): Promise<Profile[]> {
  const url = `/api/courses/${courseID}/instructors`;

  return makeRequest(url);
}

export async function fetchAssignmentsWithExerciseSets(
  courseID: string
): Promise<[Assignment[], ExerciseSet[]]> {
  const url = `/api/courses/${courseID}/assignments`;
  let assignments = await makeRequest(url);

  const exerciseSets = flatten(assignments.map(a => a.exercise_sets));

  assignments = assignments.map(({ exercise_sets, ...a }) => ({
    ...a,
    exercise_set_ids: exercise_sets.map(es => es.id)
  }));

  return [assignments, exerciseSets];
}
