// @flow
import * as React from "react";
import type { Course } from "../../lib/api/courses";
import Input from "../style-guide/input";
import styles from "./CreateCourseForm.module.css";
import { Subtitle } from "../style-guide/text";
import { displayDate, parseDate } from "../../lib/dates";
import { getCourseDurationLabel, getCourseTotalPrice } from "../../lib/course";

type Props = {
  course: $Shape<Course>,
  onChange: (seats: number) => void
};

const StudentSeatsEditor = (props: Props) => {
  const [seats, setSeats] = React.useState(
    (props.course.seats || 0).toString()
  );

  const totalPrice = React.useCallback(
    () =>
      getCourseTotalPrice({
        ...props.course,
        seats: parseInt(seats, 10) || 0
      }),
    [seats]
  );

  const startOn = props.course.start_on;

  if (!startOn) {
    throw new Error("course start on must be set");
  }

  return (
    <div className={styles.seatsEditor}>
      <div>
        <div>Student seat</div>
        <Subtitle className={styles.fontSm}>
          {getCourseDurationLabel(props.course)} starting{" "}
          {displayDate(parseDate(startOn))}
        </Subtitle>
      </div>

      <div>
        <Input
          compact
          className={styles.studentsInput}
          label="Students"
          value={seats}
          onChange={val => {
            setSeats(val);
            props.onChange(parseInt(val, 10) || 0);
          }}
        />
      </div>

      <div className={styles.totalPrice}>${totalPrice()}</div>
    </div>
  );
};

export default StudentSeatsEditor;
