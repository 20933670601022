import React from "react";

const GirlOnDesktop = props => (
  <svg viewBox="0 0 1055 902" {...props}>
    <title>{"illustrations/assignment"}</title>
    <g transform="matrix(-1 0 0 1 1040 0)" fill="none" fillRule="evenodd">
      <path stroke="#DFDFDF" strokeWidth={4} d="M103.45 263.05H74v239.62" />
      <path
        fill="#F3F3F3"
        fillRule="nonzero"
        d="M159.28 233.49H421.7v505.38H159.28z"
      />
      <path
        fill="#DFDFDF"
        fillRule="nonzero"
        d="M98.96 233.49H160v505.38H98.96z"
      />
      <path
        d="M160.94 754C101.71 754 .65 663.61.65 588.54c0-5.71 2.71-16.49 8.69-16.49 32.33-.05 151.6 96.32 151.6 181.95z"
        fill="#18EBD7"
        fillRule="nonzero"
      />
      <path stroke="#DFDFDF" strokeWidth={4} d="M553.57 96.71v329.85h18.93" />
      <path
        d="M865.87 788.54L800.89 679.2M894.05 788.54L829.07 679.2M686.62 788.54l64.97-109.34"
        stroke="#FB8080"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="#FB8080"
        fillRule="nonzero"
        d="M836 448.8h96.07l-64.52 233.83H703.87v-8.33h121.39z"
      />
      <path
        fill="#DE8E68"
        fillRule="nonzero"
        d="M674.978 782.932l18.211-2.832 2.531 16.274-18.211 2.832zM570.511 759.767l15.457 10.038-8.97 13.812-15.457-10.037z"
      />
      <path
        d="M646.58 602.26c-13.45 0-80.58 156.29-80.58 162 0 5.71 14.57 12.19 16.72 12.19 3.35-3.11 67.61-96 74.3-102.48 6.69-6.48-10.44-71.71-10.44-71.71z"
        fill="#EEF4FB"
        fillRule="nonzero"
      />
      <path
        d="M753.4 487.49c-4.42 1.39-26 19.88-29.77 42.09-3.84-.46-26.88-1.42-36.48-1.81-3.14-4.89-9.75-8.28-17.42-8.28-10.69 0-19.36 6.57-19.36 14.67v.53h38.65v-.53a11.65 11.65 0 0 0-1.6-5.82l33.58 5.35 39.89 4.15L765 503l-11.6-15.51z"
        fill="#D37C59"
        fillRule="nonzero"
      />
      <path
        d="M786.52 462.74c0-2.81-7-6-12.14-6-13.72 0-36.93 25-36.93 28.14 0 3.14 16.71 17.12 29.55 17.96 12.84.84 19.52-40.1 19.52-40.1z"
        fill="#FFCC9B"
        fillRule="nonzero"
      />
      <path
        d="M820.6 442.7c77.55 0 59.83 175.19 50 182.59-9.83 7.4-96 8.08-101.61.68-5.61-7.4-40.42-183.27 51.61-183.27z"
        fill="#FFD7B6"
        fillRule="nonzero"
      />
      <path
        d="M834.23 409.19h-31v41.53c0 6.59 31 6.64 31 0v-41.53z"
        fill="#D37C59"
        fillRule="nonzero"
      />
      <ellipse
        fill="#DE8E68"
        fillRule="nonzero"
        transform="rotate(-80.94 812.798 388.633)"
        cx={812.798}
        cy={388.633}
        rx={45.55}
        ry={30.64}
      />
      <path
        d="M885.5 321.5c15 0 30 8 49 8 15 0 29-6 47-6 32 0 42 21 42 34 0 33-27 46-47 46-32 0-36-22-74-22-37 0-32-30-40-30-4 0-6.5.5-8 2s-9-4-9-6 10-26 40-26z"
        fill="#0F212E"
        fillRule="nonzero"
      />
      <path
        d="M818.5 332.5c-35.37 0-42.77 26.91-42.77 34.68s4.91 13.53 7.42 13.72c1.56-9.29 9-22.11 9.35-22.4 2 2 9.41 8.42 17 9a37.69 37.69 0 0 1 17.85 5.56 19.09 19.09 0 0 0 2.86 7.24 17.38 17.38 0 0 0 8.78 7.15 21.64 21.64 0 0 1 1.51 7c0 10.68-1 15-2 18 16.3 0 24-16.79 24-38 0-34.92-30.9-41.95-44-41.95z"
        fill="#0F212E"
        fillRule="nonzero"
      />
      <circle
        fill="#DE8E68"
        fillRule="nonzero"
        cx={840.45}
        cy={394.66}
        r={8.64}
      />
      <path
        d="M811.5 507.37c-3.49.84-17.48 9.51-25.87 24.47-8.25-1.54-41.81-9.93-71.6-9.93-2.8 0-10.07-2.3-13-2.3-17.47 0-21.12 9.44-21.12 12.23 0 2.79.6 2.87 1.14 2.87 60 0 75.09 25.31 108.84 25.31 10.92 0 33.43-7.91 47-28.17-4.76-16.14-25.39-24.48-25.39-24.48z"
        fill="#DE8E68"
        fillRule="nonzero"
      />
      <path
        d="M870.48 473.27c18.74 18.19-28.65 68.54-36.25 67.8-7.6-.74-35-29.59-35.26-38.36-.26-8.77 53.4-47.03 71.51-29.44z"
        fill="#FFCC9B"
        fillRule="nonzero"
      />
      <path
        fill="#FA9696"
        fillRule="nonzero"
        d="M485.77 536.95H909v13.66H485.77z"
      />
      <path
        d="M870.43 634c0-4.3.75-9.28-1.93-10.39-63.62-26.23-156.76-29.63-171.11-29.63-14.35 0-56.31 1.46-56.31 13.47 0 43.38 24.17 176.93 29.08 181.92 2.33 2.35 22.65.78 25.18-1.76-.17-11 6.22-121.49.39-134.87 53.47 19.6 114.19 28.88 130.19 28.88 46.37-.05 44.51-42.52 44.51-47.62z"
        fill="#DDEAF8"
        fillRule="nonzero"
      />
      <path
        fill="#0B35CA"
        fillRule="nonzero"
        d="M578.52 781.56l-16.14-10.76-43.44-9.1-4.13 6.82L566 800.17zM697.23 792.08l-19.15 3.01-38.13 22.72 1.58 7.83 59.1-11.38z"
      />
      <path
        d="M886.26 754.33V546.79M534.61 754.33V546.79M856.54 795.92V546.79M503.21 795.92V546.79"
        stroke="#FA9696"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M715.53 788.54L780.5 679.2"
        stroke="#FB8080"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="#84B3FF"
        fillRule="nonzero"
        d="M641.08 534.44h70.27v2.51h-70.27z"
      />
      <path
        fill="#BFCAF1"
        fillRule="nonzero"
        d="M563.13 370.85h153.42v111.41H563.13z"
      />
      <path
        fill="#84B3FF"
        fillRule="nonzero"
        d="M618.29 461.31l-21.16 75.64h37.09l20.54-75.64z"
      />
      <path
        d="M648.19 102.09c15.184-14.853 17.829-38.336 6.328-56.194-11.5-17.858-33.974-25.165-53.778-17.486a62 62 0 0 0-109 9.67c-17.734-7.172-38.066-2.273-50.587 12.19-12.52 14.462-14.46 35.285-4.823 51.81l211.86.01z"
        fill="#DFDFDF"
        fillRule="nonzero"
      />
      <path
        stroke="#DFDFDF"
        strokeWidth={4}
        d="M537.95 93.62v71.72H317.88v68.15"
      />
      <path
        d="M194.48 795.92c5.85 0 17.25-19.82 17.25-58.26 0-28.11-19.7-46.29-25.55-74.14-9-42.71-53.57-168.17-123.16-168.17-8 0-18.74 16.81-18.74 51.44 0 42 40.68 85.49 40.68 94.51.04 102.99 90.87 154.62 109.52 154.62z"
        fill="#4FFFEF"
        fillRule="nonzero"
      />
      <path
        d="M194.93 795.92c0-5.63-8.14-43.4-12.37-52.84-4.23-9.44-20.84-56.65-23.44-69.68-2.6-13.03-50.79-140.32-96.05-172.56M174.16 720.85l-75.92-15.46M177.98 731.4l-69.53-5.79M156.1 663.56L85.53 642.2M162.82 686.95l21.54-15.66M122.04 584.06l21.28-24.13M115.39 571.21l18.11-24.57"
        stroke="#18EBD7"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="#DFDFDF"
        fillRule="nonzero"
        d="M181.02 265.46h218.93v42.2H181.02z"
      />
      <circle
        fill="#F3F3F3"
        fillRule="nonzero"
        cx={384.23}
        cy={281.26}
        r={7.4}
      />
      <path
        d="M256.62 281.26H370.6"
        stroke="#F3F3F3"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="#DFDFDF"
        fillRule="nonzero"
        d="M181.02 336.8h218.93V379H181.02z"
      />
      <circle
        fill="#F3F3F3"
        fillRule="nonzero"
        cx={384.23}
        cy={352.6}
        r={7.4}
      />
      <path
        d="M256.62 352.6H370.6"
        stroke="#F3F3F3"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="#DFDFDF"
        fillRule="nonzero"
        d="M181.02 408.13h218.93v42.2H181.02z"
      />
      <circle
        fill="#F3F3F3"
        fillRule="nonzero"
        cx={384.23}
        cy={423.93}
        r={7.4}
      />
      <path
        d="M256.62 423.93H370.6"
        stroke="#F3F3F3"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="#DFDFDF"
        fillRule="nonzero"
        d="M181.02 479.47h218.93v42.2H181.02z"
      />
      <circle
        fill="#F3F3F3"
        fillRule="nonzero"
        cx={384.23}
        cy={495.27}
        r={7.4}
      />
      <path
        d="M256.62 495.27H370.6"
        stroke="#F3F3F3"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="#DFDFDF"
        fillRule="nonzero"
        d="M181.02 550.8h218.93V593H181.02z"
      />
      <circle
        fill="#F3F3F3"
        fillRule="nonzero"
        cx={384.23}
        cy={566.61}
        r={7.4}
      />
      <path
        d="M256.62 566.61H370.6"
        stroke="#F3F3F3"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.06 295.82h32.84M113.06 304.32h32.84M113.06 353.65h32.84M113.06 362.15h32.84M113.06 423.93h32.84M113.06 432.44h32.84M113.06 491.93h32.84M113.06 500.44h32.84"
        stroke="#F3F3F3"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default GirlOnDesktop;
