// @flow
import * as React from "react";
import MultiSelector from "../../../modules/style-guide/multi-selector";
import Button from "../../../modules/style-guide/button";
import Support from "../../../modules/support";

import type { MultiSelectorItem } from "../../../modules/style-guide/multi-selector";
import type { Stack } from "./stack";

type Props = {|
  allStacks: Stack[],
  selectedStackIDs: string[],
  onChange: (stackIDs: string[]) => any
|};

class StacksEditor extends React.Component<Props> {
  handleChange = (items: MultiSelectorItem[]) =>
    this.props.onChange(items.map(i => i.id));

  renderUnlock = (stack: Stack) => (
    <Support
      defaultProblem={`I would like to unlock the '${stack.name}' stack.`}
      renderOpener={(isOpen, open) => (
        <Button small link onClick={open}>
          Unlock
        </Button>
      )}
    />
  );

  render() {
    const { allStacks, selectedStackIDs } = this.props;

    return (
      <MultiSelector
        items={allStacks.map(s => ({
          id: s.id,
          label: s.name,
          disabled: s.unavailable,
          accessory: s.unavailable ? this.renderUnlock(s) : null
        }))}
        selectedItemIDs={selectedStackIDs}
        onChange={this.handleChange}
      />
    );
  }
}

export default StacksEditor;
