// @flow
import * as React from "react";
import AceEditor from "react-ace";

import "brace/mode/plain_text";
import "brace/mode/python";
import "brace/mode/json";
import "brace/mode/java";
import "brace/mode/c_cpp";
import "brace/mode/csharp";
import "brace/mode/vbscript";
import "brace/mode/swift";
import "brace/mode/sql";
import "brace/mode/makefile";
import "brace/mode/assembly_x86";
import "brace/mode/javascript";
import "brace/mode/html";
import "brace/mode/dockerfile";
import "brace/mode/css";
import "brace/mode/r";
import "brace/mode/golang";
import "brace/mode/matlab";
import "brace/theme/tomorrow";
import ReactResizeDetector from "react-resize-detector";

export type Language =
  | "plain_text"
  | "python"
  | "json"
  | "java"
  | "c_cpp"
  | "csharp"
  | "vbscript"
  | "swift"
  | "sql"
  | "makefile"
  | "assembly_x86"
  | "javascript"
  | "html"
  | "dockerfile"
  | "css"
  | "r"
  | "golang"
  | "matlab";

const hardTabsLanguages: Language[] = ["makefile"];

type Props = {|
  readOnly?: boolean,
  code: string,
  language: Language,
  onCodeChange: (code: string) => any
|};

class CodeEditor extends React.Component<Props> {
  handleChange = (code: string) => {
    this.props.onCodeChange(code);
  };

  render() {
    const { code, language, readOnly } = this.props;

    return (
      <ReactResizeDetector handleHeight>
        {({ height }) => (
          <AceEditor
            mode={language}
            theme="tomorrow"
            name="ws_editor_1"
            width="100%"
            height={`${height || 300}px`}
            fontSize={14}
            style={{ flex: 1 }}
            editorProps={{ $blockScrolling: Infinity }}
            setOptions={{
              useSoftTabs: hardTabsLanguages.indexOf(language) === -1
            }}
            showPrintMargin={false}
            value={code}
            readOnly={readOnly}
            onChange={this.handleChange}
          />
        )}
      </ReactResizeDetector>
    );
  }
}

export default CodeEditor;
