// @flow
import * as React from "react";
import Input from "../../modules/style-guide/input";
import Button from "../../modules/style-guide/button";
import styles from "./ExerciseSet.module.css";

type Props = {|
  onCreate: (name: string) => any,
  creating: boolean,
  createError: ?string
|};

type State = {|
  name: string
|};

class CreateExercise extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      name: ""
    };
  }

  handleNameChange = (name: string) => {
    this.setState({
      name
    });
  };

  handleCreate = () => {
    const { onCreate, creating } = this.props;

    if (creating) {
      return;
    }

    onCreate(this.state.name);
  };

  render() {
    const { creating, createError } = this.props;
    const { name } = this.state;

    return (
      <div className={styles.createExercise}>
        <Input
          label="New exercise"
          placeholder="EX 1"
          value={name}
          onEnter={this.handleCreate}
          onChange={this.handleNameChange}
        />
        <Button small secondary onClick={this.handleCreate}>
          {creating ? "Creating" : "Create"}
        </Button>
        <div>{createError}</div>
      </div>
    );
  }
}

export default CreateExercise;
