// @flow

import type { AppDataCache } from "./data-cache";
import type { Profile } from "./api/profile";

export function getSessionProfile(cache: AppDataCache): Profile {
  if (!cache.session) {
    throw new Error("session is undefined");
  }
  return cache.profiles[cache.session.profileID];
}
