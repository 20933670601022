// @flow
import * as React from "react";
import { Redirect } from "react-router-dom";
import { keyBy, values } from "lodash";
import AppDataCacheCtx from "../../lib/data-cache";
import BaseDataPage from "../base-data";
import BaseLayoutPage, { renderSkeleton, PageContent } from "../base-layout";

import * as ExerciseSetsAPI from "../../lib/api/exercise-sets";
import * as ExercisesAPI from "../../lib/api/exercises";

import type { Exercise } from "../../lib/api/exercises";

type Props = {||};

class ExerciseSetPage extends React.Component<Props> {
  fetchData = async () => {
    // $FlowFixMe: add defs for react-router
    const { exerciseSetID } = this.props.match.params;

    const [exerciseSet, exercises] = await Promise.all([
      ExerciseSetsAPI.fetchExerciseSet(exerciseSetID),
      ExercisesAPI.fetchExercises(exerciseSetID)
    ]);

    return {
      exerciseSets: {
        [exerciseSet.id]: exerciseSet
      },
      exercises: keyBy(exercises, e => e.id)
    };
  };

  render() {
    // $FlowFixMe: add defs for react-router
    const { exerciseSetID } = this.props.match.params;

    return (
      <BaseDataPage
        fetchData={this.fetchData}
        renderSkeleton={renderSkeleton}
      >
        <AppDataCacheCtx.Consumer>
          {({ cache }) => {
            const allExercises: Exercise[] = values(cache.exercises);
            const exerciseSet = cache.exerciseSets[exerciseSetID];
            const exercises = allExercises.filter(
              e => e.exercise_set_id === exerciseSet.id
            );

            return (
              <BaseLayoutPage>
                <PageContent title={exerciseSet.title}>
                  {exercises.length ? (
                    <Redirect to={`${exerciseSet.id}/${exercises[0].id}`} />
                  ) : (
                    "There are no exercises"
                  )}
                </PageContent>
              </BaseLayoutPage>
            );
          }}
        </AppDataCacheCtx.Consumer>
      </BaseDataPage>
    );
  }
}

export default ExerciseSetPage;
