// @flow

import makeRequest from "../make-request";

export type Task = {
  uuid: string,
  status: "QUEUED" | "PROCESSING" | "COMPLETED" | "FAILED",
  error: ?string,
  result: string
};

export async function fetchTask(uuid: string): Promise<Task> {
  const url = `/api/queue/tasks/${uuid}`;

  return makeRequest(url);
}
