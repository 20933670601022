// @flow
import * as React from "react";
import { FaCheck } from "react-icons/fa";
import styles from "./Checkbox.module.css";

type Props = {|
  label?: string,
  pro?: boolean,
  checked: boolean,
  disabled?: boolean,
  onChange: (checked: boolean) => any
|};

class Checkbox extends React.Component<Props> {
  handleChange = () => {
    this.props.onChange(!this.props.checked);
  };

  render() {
    const { label, checked, disabled, pro } = this.props;
    return (
      <div className={styles.container} onClick={this.handleChange}>
        <span
          className={[
            styles.checkbox,
            disabled ? styles.disabled : "",
            pro ? styles.pro : ""
          ].join(" ")}
        >
          <FaCheck style={{ visibility: checked ? "visible" : "hidden" }} />
        </span>
        {label ? <span className={styles.label}>{label}</span> : null}
      </div>
    );
  }
}

export default Checkbox;
