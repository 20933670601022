// @flow
import * as React from "react";
import { uniqueId } from "lodash";
import ReactQuill from "react-quill";
import Label from "../label";

import styles from "./HTMLEditor.module.css";

import "react-quill/dist/quill.snow.css";

type Props = {|
  label?: string,
  html: string,
  onChange: (html: string) => any
|};

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],

  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ align: [] }],

  ["clean"] // remove formatting button
];

class HTMLEditor extends React.Component<Props> {
  id: string;

  componentWillMount() {
    this.id = uniqueId("frm-html-editor-");
  }

  handleChange = (value: string) => {
    this.props.onChange(value);
  };

  render() {
    const { label, html } = this.props;

    return (
      <div>
        {label && <Label htmlFor={this.id}>{label}</Label>}
        <ReactQuill
          id={this.id}
          className={styles.htmlEditor}
          modules={{
            toolbar: toolbarOptions,
            clipboard: {
              matchVisual: false
            }
          }}
          defaultValue={html}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

export default HTMLEditor;
