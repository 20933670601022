// @flow
import * as React from "react";
import CodeEditor from "../../../style-guide/code-editor";
import { Error } from "../../../style-guide/text";
import styles from "./Editor.module.css";

import type { File } from "./../../index";
import type { Language } from "../../../style-guide/code-editor";

type Props = {|
  readOnly?: boolean,
  file: ?File,
  onDataChange: (data: string) => void,

  toolbarEndEnhancer?: () => React.Node
|};

const getLanguage = (path: string): Language => {
  const sepIdx =
    path.lastIndexOf(".") >= 0 ? path.lastIndexOf(".") : path.lastIndexOf("/");
  const ext = path.substr(sepIdx + 1);

  switch (ext) {
    case "c":
    case "cpp":
    case "h":
      return "c_cpp";
    case "py":
      return "python";
    case "json":
      return "json";
    case "java":
      return "java";
    case "cs":
      return "csharp";
    case "vb":
      return "vbscript";
    case "swift":
      return "swift";
    case "sql":
      return "sql";
    case "Makefile":
      return "makefile";
    case "s":
      return "assembly_x86";
    case "js":
      return "javascript";
    case "html":
      return "html";
    case "Dockerfile":
      return "dockerfile";
    case "css":
      return "css";
    case "R":
      return "r";
    case "go":
      return "golang";
    case "m":
      return "matlab";
    default:
      return "plain_text";
  }
};

const Editor = (props: Props) => (
  <div className={styles.container}>
    <div className={styles.tabs}>
      <div>
        {props.file ? props.file.path : null}
        {props.file && !props.file.saved && !props.file.saveError ? (
          <span className={styles.saveStatus}>Saving..</span>
        ) : null}
        {props.file && !props.file.saving && props.file.saved ? (
          <span className={styles.saveStatus}>Saved!</span>
        ) : null}
        {props.file && !props.file.saving && props.file.saveError ? (
          <span className={styles.saveStatus}>
            <Error>Failed to save changes</Error>
          </span>
        ) : null}
      </div>
      <div>{props.toolbarEndEnhancer && props.toolbarEndEnhancer()}</div>
    </div>

    {props.file && props.file.loading ? (
      <div className={styles.padded}>Loading..</div>
    ) : null}

    {props.file && !props.file.loading && props.file.loadError ? (
      <div className={styles.padded}>{props.file.loadError}</div>
    ) : null}

    {!props.file ? (
      <div className={styles.padded}>
        Select a file to {props.readOnly ? "view" : "edit"}
      </div>
    ) : null}

    {props.file &&
    !props.file.loading &&
    !props.file.loadError &&
    props.file.data != null ? (
      typeof props.file.data === "string" ? (
        <CodeEditor
          readOnly={props.readOnly}
          code={props.file.data}
          language={getLanguage(props.file.path)}
          onCodeChange={d => props.onDataChange(d)}
        />
      ) : (
        "Cannot preview this file.."
      )
    ) : null}
  </div>
);

export default Editor;
