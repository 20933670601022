// @flow
import * as React from "react";
import { FaCalendarAlt, FaClock, FaPlus } from "react-icons/fa";
import { keyBy } from "lodash";
import queryString from "query-string";
import AppDataCacheCtx from "../../lib/data-cache";
import ConfirmAction from "../../modules/style-guide/confirm-action";
import Button from "../../modules/style-guide/button";
import { Spacer, SpacerLg } from "../../modules/style-guide/spacer";
import HelpSection from "../../modules/style-guide/help-section";
import BaseLayoutPage, {
  renderSkeleton,
  PageAsideContent,
  PageContent
} from "../base-layout";
import BaseDataPage from "../base-data";
import AssignmentEditor from "./assignment-editor";
import { mapAssignment, newAssignmentParams } from "./assignment-params";

import * as ExerciseSetsAPI from "../../lib/api/exercise-sets";
import * as AssignmentsAPI from "../../lib/api/assignments";

import type { UpdateAssignmentParams } from "../../lib/api/assignments";

type Props = {||};

type State = {|
  deleting: boolean,
  deleteError: ?string
|};

class AssignmentPage extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      deleting: false,
      deleteError: null
    };
  }

  get assignmentName(): string {
    // $FlowFixMe: add defs for react-router
    const { location } = this.props;
    const { name } = queryString.parse(location.search);

    return name || "";
  }

  get exerciseSetIDs() {
    // $FlowFixMe: add defs for react-router
    const { location } = this.props;
    const { exercise_sets } = queryString.parse(location.search);

    return (exercise_sets || "")
      .split(",")
      .map(s => s.trim())
      .filter(s => s.length);
  }

  fetchData = async () => {
    // $FlowFixMe: add defs for react-router
    const { assignmentID } = this.props.match.params;
    let appData = { assignments: {}, exerciseSets: {} };

    if (assignmentID) {
      const [
        assignment,
        exerciseSets
      ] = await AssignmentsAPI.fetchAssignmentWithExerciseSets(assignmentID);

      appData.assignments = {
        [assignmentID]: assignment
      };

      appData.exerciseSets = keyBy(exerciseSets, es => es.id);
    }

    appData.exerciseSets = {
      ...appData.exerciseSets,
      ...keyBy(
        await Promise.all(
          this.exerciseSetIDs.map(id => ExerciseSetsAPI.fetchExerciseSet(id))
        ),
        es => es.id
      )
    };

    return appData;
  };

  handleSave = async (p: UpdateAssignmentParams) => {
    // $FlowFixMe: add defs for react-router
    const { history, match } = this.props;
    const { courseID, assignmentID } = match.params;

    if (assignmentID) {
      await AssignmentsAPI.updateAssignment(assignmentID, p);
    } else {
      await AssignmentsAPI.createAssignment(courseID, p);
    }

    history.push(`/courses/${courseID}/assignments`);
  };

  handleDelete = async () => {
    // $FlowFixMe: add defs for react-router
    const { history, match } = this.props;
    const { courseID, assignmentID } = match.params;

    if (!assignmentID) {
      return;
    }

    try {
      this.setState({ deleting: true, deleteError: null });
      await AssignmentsAPI.deleteAssignment(assignmentID);
      history.push(`/courses/${courseID}/assignments`);
    } catch (e) {
      this.setState({
        deleting: false,
        deleteError: "Failed to delete assignment"
      });
    }
  };

  renderDeleteAction = () => {
    // $FlowFixMe: add defs for react-router
    const { assignmentID } = this.props.match.params;
    const { deleting, deleteError } = this.state;

    if (!assignmentID) {
      return null;
    }

    return (
      <ConfirmAction
        wide
        title="Delete Assignment"
        message="You will lose access to the assignment gradebook and all student submissions. This action cannot be undone."
        placement="RIGHT"
        onConfirm={this.handleDelete}
      >
        <Button small secondary danger>
          {deleting ? "Deleting Assignment.." : "Delete Assignment"}
        </Button>
        {deleteError ? (
          <>
            <Spacer />
            {deleteError}
          </>
        ) : null}
      </ConfirmAction>
    );
  };

  render() {
    // $FlowFixMe: add defs for react-router
    const { courseID, assignmentID } = this.props.match.params;

    return (
      <BaseDataPage fetchData={this.fetchData} renderSkeleton={renderSkeleton}>
        <AppDataCacheCtx.Consumer>
          {({ cache }) => {
            let assignmentParams = newAssignmentParams();

            if (assignmentID) {
              const assignment = cache.assignments[assignmentID];
              assignmentParams = mapAssignment(assignment);
            }

            if (this.exerciseSetIDs.length) {
              assignmentParams.exerciseSetIDs = this.exerciseSetIDs;
            }

            if (this.assignmentName) {
              assignmentParams.name = this.assignmentName;
            }

            return (
              <BaseLayoutPage>
                <PageContent
                  title={
                    assignmentID ? assignmentParams.name : "Create Assignment"
                  }
                  actions={[this.renderDeleteAction()]}
                >
                  <AssignmentEditor
                    assignmentID={assignmentID}
                    courseID={courseID}
                    assignmentParams={assignmentParams}
                    saveButtonText={
                      assignmentID ? "Save Changes" : "Create Assignment"
                    }
                    savingButtonText={
                      assignmentID
                        ? "Saving Changes.."
                        : "Creating Assignment.."
                    }
                    onSave={this.handleSave}
                  />
                </PageContent>
                <PageAsideContent>
                  <HelpSection icon={FaPlus} title="Add questions">
                    An assignment can contain one or more questions. Browse
                    questions to view your library and select the questions you
                    would like to add to this assignment.
                  </HelpSection>
                  <SpacerLg />
                  <HelpSection icon={FaCalendarAlt} title="Due date">
                    Students who make their attempts before the due date are
                    considered 'on-time'.
                  </HelpSection>
                  <SpacerLg />
                  <HelpSection icon={FaCalendarAlt} title="Cutoff date">
                    Students can make attempts past the due date until the
                    cutoff date. However, their submission will be marked late
                    in the gradebook. Students do not see the assignment past
                    the cutoff date.
                  </HelpSection>
                  <SpacerLg />
                  <HelpSection icon={FaCalendarAlt} title="Available date">
                    Plan your course ahead by creating assignments, and setting
                    an available date on them. Students will only see the
                    assignment past the available date.
                  </HelpSection>
                  <SpacerLg />
                  <HelpSection icon={FaClock} title="Time limit">
                    Set a limit on how long a student can take for this
                    assignment. Students only see the questions once they start
                    the timer.
                  </HelpSection>
                  <SpacerLg />
                </PageAsideContent>
              </BaseLayoutPage>
            );
          }}
        </AppDataCacheCtx.Consumer>
      </BaseDataPage>
    );
  }
}

export default AssignmentPage;
