// @flow
import * as React from "react";
import Logo from "../../modules/style-guide/logo";
import { SpacerXLg } from "../../modules/style-guide/spacer";
import styles from "./AnonymousLayout2.module.css";

type Props = {|
  children?: ?React.Node
|};

export const PageContent = (props: any) => (
  <div className={styles.content}>{props.children}</div>
);

export const SideContent = (props: any) => (
  <div className={styles.sideContent}>{props.children}</div>
);

class AnonymousLayout2 extends React.Component<Props> {
  renderSideContent() {
    const { children } = this.props;
    let side = null;

    React.Children.forEach(children, child => {
      if (child.type === SideContent) {
        side = child;
      }
    });

    return <div className={styles.sideContentContainer}>{side}</div>;
  }

  renderContent() {
    const { children } = this.props;
    let content = <PageContent>Empty page..</PageContent>;

    React.Children.forEach(children, child => {
      if (child.type === PageContent) {
        content = child;
      }
    });

    return content;
  }

  render() {
    return (
      <div className={styles.page}>
        <div className={styles.side}>
          <Logo />
          <SpacerXLg />
          {this.renderSideContent()}
        </div>
        <SpacerXLg />
        {this.renderContent()}
      </div>
    );
  }
}

export default AnonymousLayout2;
