// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import { keyBy, values, flatten } from "lodash";
import AppDataCacheCtx from "../../lib/data-cache";
import { Subtitle } from "../../modules/style-guide/text";
import { Spacer, SpacerXsm } from "../../modules/style-guide/spacer";
import Table from "../../modules/style-guide/table";
import { ListIcon } from "../../modules/style-guide/icons";
import Button from "../../modules/style-guide/button";
import BaseDataPage from "../base-data";
import BaseLayoutPage, {
  renderSkeleton,
  PageAsideContent,
  PageContent
} from "../base-layout";
import styles from "./CS50ExerciseSets.module.css";

import * as ExerciseSetsAPI from "../../lib/api/exercise-sets";
import * as ExercisesAPI from "../../lib/api/exercises";

import type { AppDataCache } from "../../lib/data-cache";
import type { ExerciseSet } from "../../lib/api/exercise-sets";
import type { Exercise } from "../../lib/api/exercises";
import { FaPlus } from "react-icons/fa";
import Support from "../../modules/support";

type Props = {||};

const cs50Tag = "exercise_sets.cs50";

class CS50ExerciseSetsPage extends React.Component<Props> {
  fetchData = async () => {
    const exerciseSets = await ExerciseSetsAPI.fetchTaggedExerciseSets(cs50Tag);
    const exercises = flatten(
      await Promise.all(
        exerciseSets.map(es => ExercisesAPI.fetchExercises(es.id))
      )
    );

    return {
      exerciseSets: keyBy(exerciseSets, es => es.id),
      exercises: keyBy(exercises, e => e.id)
    };
  };

  renderQuestions(cache: AppDataCache) {
    const allExerciseSets: ExerciseSet[] = values(cache.exerciseSets).reverse();
    const allExercises: Exercise[] = values(cache.exercises);
    const taggedExerciseSets = allExerciseSets.filter(
      es => es.tags.indexOf(cs50Tag) >= 0
    );

    return (
      <>
        <div className={styles.actionTitle}>
          <Support
            defaultProblem={`I'm interested in using the CS50 AP questions.\n\nCourse start date: ____\nNumber of students: ____`}
            renderOpener={(isOpen, open) => (
              <Button small onClick={open}>
                <FaPlus className={styles.plusIcon} /> I'm Interested
              </Button>
            )}
          />
        </div>
        <Spacer />
        <Subtitle>
          These questions will get your AP CS course up and running in no time.
          If you use the{" "}
          <a
            href="https://ap.cs50.net/"
            target="_blank"
            rel="noopener noreferrer"
          >
            CS50 AP
          </a>{" "}
          curriculum, these questions will work great for your course. To get
          started, submit a request by clicking "I'm Interested".
        </Subtitle>
        <Spacer />
        <Table
          listBorder
          columns={[
            { accessor: "title", left: true, compact: true, width: "40%" },
            { accessor: "exercise_count", compact: true },
            { accessor: "edit", right: true, compact: true }
          ]}
          rows={taggedExerciseSets.map(es => {
            const exercises = allExercises.filter(
              e => e.exercise_set_id === es.id
            );

            return {
              key: es.id,
              data: {
                title: exercises.length ? (
                  <Link to={`/questions/${es.id}`}>{es.title}</Link>
                ) : (
                  es.title
                ),
                exercise_count: exercises.length ? (
                  <span className={styles.exercisesCount}>
                    <ListIcon />
                    <SpacerXsm />
                    {`${exercises.length} exercise${
                      exercises.length === 1 ? "" : "s"
                    }`}
                  </span>
                ) : null,
                edit: null
              }
            };
          })}
        />
      </>
    );
  }

  render() {
    return (
      <BaseDataPage fetchData={this.fetchData} renderSkeleton={renderSkeleton}>
        <AppDataCacheCtx.Consumer>
          {({ cache }) => {
            return (
              <BaseLayoutPage>
                <PageContent title="CS50 AP Questions" actions={[]}>
                  {this.renderQuestions(cache)}
                </PageContent>
                <PageAsideContent />
              </BaseLayoutPage>
            );
          }}
        </AppDataCacheCtx.Consumer>
      </BaseDataPage>
    );
  }
}

export default CS50ExerciseSetsPage;
