// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import AppDataCacheCtx from "../../lib/data-cache";
import { H3 } from "../../modules/style-guide/text";
import TestCase from "../../modules/test-case";
import Button from "../../modules/style-guide/button";
import { Spacer, SpacerLg, SpacerXLg } from "../../modules/style-guide/spacer";
import Card from "../../modules/style-guide/card";
import CounterPill from "../../modules/style-guide/counter-pill";
import NestedNav from "../../modules/style-guide/nested-nav";
import HelpSection from "../../modules/style-guide/help-section";
import BaseDataPage from "../base-data";
import BaseLayoutPage, {
  renderSkeleton,
  PageContent,
  PageAsideContent
} from "../base-layout";
import MetadataEditor from "./metadata-editor";
import styles from "./EditTestCase.module.css";

import * as ExerciseSetsAPI from "../../lib/api/exercise-sets";
import * as ExercisesAPI from "../../lib/api/exercises";
import * as TestCasesAPI from "../../lib/api/test-cases";

import type { updateAppDataCacheFunc } from "../../lib/data-cache";

type Props = {||};

type State = {|
  savingMetadata: boolean,
  saveMetadataError: ?string
|};

class EditTestCasePage extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      savingMetadata: false,
      saveMetadataError: null
    };
  }

  fetchData = async () => {
    // $FlowFixMe: add defs for react-router
    const { exerciseSetID, exerciseID, testCaseID } = this.props.match.params;

    const [exerciseSet, exercise, testCase] = await Promise.all([
      ExerciseSetsAPI.fetchExerciseSet(exerciseSetID),
      ExercisesAPI.fetchExercise(exerciseID),
      TestCasesAPI.fetchTestCase(testCaseID)
    ]);

    return {
      exerciseSets: {
        [exerciseSet.id]: exerciseSet
      },
      exercises: {
        [exercise.id]: exercise
      },
      testCases: {
        [testCase.id]: testCase
      }
    };
  };

  handleMetadataSave = (updateCache: updateAppDataCacheFunc) => async (
    name: string,
    visible: boolean
  ) => {
    // $FlowFixMe: add defs for react-router
    const { testCaseID } = this.props.match.params;

    this.setState({ savingMetadata: true, saveMetadataError: null });

    updateCache({
      testCases: {
        [testCaseID]: {
          name,
          visible
        }
      }
    });
    try {
      await TestCasesAPI.updateTestCase(testCaseID, name, visible);

      this.setState({ savingMetadata: false });
    } catch (e) {
      this.setState({
        savingMetadata: false,
        saveMetadataError: "Failed to save metadata"
      });
    }
  };

  renderWidgetHelp(widget: string) {
    switch (widget) {
      case "OUTPUT_MATCHING":
        return (
          <>
            <HelpSection title="Console output">
              If you have a solution to the problem, run it on your computer and
              paste the output here (including user input). If not, simply type
              what you expect the output for this problem to be.
              <br />
              <br />
              Then, click 'Setup line-by-line tests' to setup the tests on the
              output. By default, the student output will be matched to this
              line-by-line.
              <br />
              <br />
              If some of your lines are user input, click the '+' next to the
              line and mark the line as user input.
            </HelpSection>
            <SpacerLg />
            <HelpSection title="Input files">
              If your students need to <strong>read</strong> from files, you can
              provide those files here. These files will be placed in the same
              directory as the submission.
            </HelpSection>
            <SpacerLg />
            <HelpSection title="Expected output files">
              If your students need to <strong>write</strong> to files, you can
              provide the expected version of those files here. The files
              created by the student submissions will be compared to these files
              provided by you.
            </HelpSection>
          </>
        );
      case "JUNIT":
        return (
          <HelpSection title="JUnit tests">
            You can provide your own unit tests that will be run on the
            students' submissions.
            <br />
            <br />
            Make sure your students know the signature of the classes and
            methods that you will be testing.
          </HelpSection>
        );
      case "PYTEST":
        return (
          <HelpSection title="pytest">
            pytest is only compatible with the Python 2.7 and 3 stacks. You can
            write your own pytest unit tests to test your students' code.
            <br />
            <br />
            Make sure your students know the signature of the classes and
            methods that you will be testing.
          </HelpSection>
        );
      case "GOTEST":
        return (
          <HelpSection title="gotest">
            gotest is only compatible with the Go stack. You can write your own
            Go unit tests to test your students' code.
          </HelpSection>
        );
      case "CUSTOM_SCRIPT":
        return (
          <HelpSection title="Custom script">
            If you have a special use case, you can write your own Python script
            to test the submission.
            <br />
            <br />
            Open the workspace and edit the test.py file to write your script.
            You can use helper methods provided by the ag module to save the
            test case results.
          </HelpSection>
        );
      case "WEB_UI":
        return (
          <HelpSection title="Web UI tests">
            Test a web app UI to ensure the pages contain the necessary elements
            and function as expected.
          </HelpSection>
        );
      default:
        return null;
    }
  }

  render() {
    // $FlowFixMe: add defs for react-router
    const { exerciseSetID, exerciseID, testCaseID } = this.props.match.params;
    const { savingMetadata, saveMetadataError } = this.state;

    return (
      <BaseDataPage fetchData={this.fetchData} renderSkeleton={renderSkeleton}>
        <AppDataCacheCtx.Consumer>
          {({ cache, updateCache }) => {
            const exerciseSet = cache.exerciseSets[exerciseSetID];
            const exercise = cache.exercises[exerciseID];
            const testCase = cache.testCases[testCaseID];

            return (
              <BaseLayoutPage>
                <PageContent title={exerciseSet.title}>
                  <H3>{exercise.title}</H3>
                  <Spacer />
                  <Card>
                    <NestedNav contained to="../edit?step=2">
                      <CounterPill>3</CounterPill> <span>Test Cases</span>
                    </NestedNav>
                    <MetadataEditor
                      name={testCase.name}
                      visible={testCase.visible}
                      saving={savingMetadata}
                      saveError={saveMetadataError}
                      onSave={this.handleMetadataSave(updateCache)}
                    />
                    <hr />
                    <div className={styles.testCaseEditor}>
                      <TestCase testCaseID={testCase.id} editable />
                    </div>
                    <hr />
                    <div className={styles.actionBar}>
                      <Button small el={Link} to="../edit?step=2">
                        Done
                      </Button>
                    </div>
                  </Card>
                </PageContent>
                <PageAsideContent>
                  <SpacerXLg />
                  <HelpSection title="Visibility">
                    You can hide test cases from your students to ensure they
                    are able to create a general solution to the problem. For
                    hidden test cases, students will not be able to see the test
                    case or detailed results.
                  </HelpSection>
                  <SpacerLg />
                  {this.renderWidgetHelp(testCase.ui_widget)}
                  <SpacerXLg />
                </PageAsideContent>
              </BaseLayoutPage>
            );
          }}
        </AppDataCacheCtx.Consumer>
      </BaseDataPage>
    );
  }
}

export default EditTestCasePage;
