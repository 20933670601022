// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import { FaCheck, FaPen, FaTimes, FaFileDownload } from "react-icons/fa";
import { keyBy, flatten, values, maxBy } from "lodash";
import AppDataCacheCtx from "../../lib/data-cache";
import { displayDateTime } from "../../lib/dates";
import Table from "../../modules/style-guide/table";
import Button from "../../modules/style-guide/button";
import ExternalLink from "../../modules/style-guide/external-link";
import BaseLayoutPage, { renderSkeleton, PageContent } from "../base-layout";
import BaseDataPage from "../base-data";
import styles from "./Gradebook.module.css";
import csvStringify from "csv-stringify/lib/sync";

import * as AssignmentsAPI from "../../lib/api/assignments";
import * as AttemptsAPI from "../../lib/api/attempts";
import * as CoursesAPI from "../../lib/api/courses";
import * as ExercisesAPI from "../../lib/api/exercises";

import type { Exercise } from "../../lib/api/exercises";
import type { AppDataCache } from "../../lib/data-cache";
import type { TableColumn, TableRow } from "../../modules/style-guide/table";
import type { Attempt } from "../../lib/api/attempts";
import DownloadSubmissionsButton from "./download-submissions-button";

type Props = {||};

class GradebookPage extends React.Component<Props> {
  fetchData = async () => {
    // $FlowFixMe: add defs for react-router
    const { courseID, assignmentID } = this.props.match.params;

    const [
      assignmentWithExerciseSets,
      attempts,
      studentProfiles
    ] = await Promise.all([
      AssignmentsAPI.fetchAssignmentWithExerciseSets(assignmentID),
      AttemptsAPI.fetchAssignmentAttempts(assignmentID),
      CoursesAPI.fetchCourseRoster(courseID)
    ]);
    const [assignment, exerciseSets] = assignmentWithExerciseSets;
    const exercises = flatten(
      await Promise.all(
        exerciseSets.map(es => ExercisesAPI.fetchExercises(es.id))
      )
    );

    return {
      courses: {
        [courseID]: {
          student_profile_ids: studentProfiles.map(p => p.id)
        }
      },
      assignments: {
        [assignment.id]: assignment
      },
      exerciseSets: keyBy(exerciseSets, es => es.id),
      exercises: keyBy(exercises, e => e.id),
      attempts: keyBy(attempts, a => a.id),
      profiles: keyBy(studentProfiles, p => p.id)
    };
  };

  getExercises(cache: AppDataCache): Exercise[] {
    // $FlowFixMe: add defs for react-router
    const { assignmentID } = this.props.match.params;
    const assignment = cache.assignments[assignmentID];
    const exerciseSets = assignment.exercise_set_ids.map(
      id => cache.exerciseSets[id]
    );

    return values(cache.exercises).filter(e =>
      exerciseSets.find(es => es.id === e.exercise_set_id)
    );
  }

  getTableColumns(
    cache: AppDataCache,
    opts: { includeEmail: boolean } = {}
  ): TableColumn[] {
    // $FlowFixMe: add defs for react-router
    const { assignmentID } = this.props.match.params;
    const assignment = cache.assignments[assignmentID];
    const exerciseSets = assignment.exercise_set_ids.map(
      id => cache.exerciseSets[id]
    );
    const exercises = this.getExercises(cache);
    const exerciseColumns: TableColumn[] = exerciseSets.map(es => ({
      accessor: es.id,
      label: es.title,
      subColumns: exercises
        .filter(e => e.exercise_set_id === es.id)
        .map((e, i) => ({
          accessor: e.id,
          label: e.title
        })),
      highlightCell: true
    }));

    return [
      { accessor: "name", label: "Name", left: true },
      ...(opts.includeEmail
        ? [{ accessor: "email", label: "Email", left: true }]
        : []),
      { accessor: "submission", label: "Latest Submission", left: true },
      ...exerciseColumns
    ];
  }

  getTableRows(cache: AppDataCache): TableRow[] {
    // $FlowFixMe: add defs for react-router
    const { courseID, assignmentID } = this.props.match.params;
    const course = cache.courses[courseID];
    const exercises = this.getExercises(cache);
    const assignmentAttempts: Attempt[] = values(cache.attempts).filter(
      a => a.assignment_id === assignmentID
    );

    if (!course.student_profile_ids) {
      throw new Error("course.student_profile_ids is undefined");
    }

    const studentProfiles = course.student_profile_ids.map(
      id => cache.profiles[id]
    );
    let rows: TableRow[] = [];

    for (let i = 0; i < studentProfiles.length; i++) {
      const student = studentProfiles[i];
      const attempts = assignmentAttempts.filter(
        a => a.user_uuid === student.id
      );
      const latestAttempt = maxBy(attempts, "created_at");

      let row: TableRow = {
        key: student.id,
        asciiData: {
          name: student.name,
          email: student.email,
          submission: attempts.length
            ? displayDateTime(latestAttempt.created_at)
            : "No submissions"
        },
        data: {
          name: student.name,
          email: student.email,
          submission: attempts.length
            ? displayDateTime(latestAttempt.created_at)
            : "No submissions"
        }
      };

      for (let j = 0; j < exercises.length; j++) {
        const exercise = exercises[j];
        const attempt = attempts.find(
          a => a.test_case_set_id === exercise.test_case_set_id
        );

        if (!attempt) {
          continue;
        }

        // $FlowFixMe: row.asciiData has been initialized
        row.asciiData[exercise.id] = attempt.did_pass ? "Pass" : "Fail";
        row.data[exercise.id] = (
          <div className={styles.attemptResult}>
            {attempt.did_pass ? (
              <FaCheck className={styles.passed} />
            ) : (
              <FaTimes className={styles.failed} />
            )}
            <ExternalLink
              href={`ides/${attempt.ide_id}/attempt?read_only=true`}
              className={styles.attemptLink}
            >
              View
            </ExternalLink>
          </div>
        );
      }

      rows.push(row);
    }

    return rows;
  }

  downloadCSVLink(cache: AppDataCache) {
    const cols = this.getTableColumns(cache, { includeEmail: true });
    const rows = this.getTableRows(cache);

    const csvCols = flatten(
      cols.map(col => {
        if (!col.subColumns || !col.subColumns.length) {
          return [col.label];
        }

        return col.subColumns.map(
          subCol => `${col.label || ""} / ${subCol.label || ""}`
        );
      })
    );

    const csvRows = rows.map(row =>
      flatten(
        cols.map(col => {
          if (col.subColumns && col.subColumns.length) {
            return col.subColumns.map(
              subCol => (row.asciiData || {})[subCol.accessor] || ""
            );
          }
          return [(row.asciiData || {})[col.accessor] || ""];
        })
      )
    );

    const csvData = [csvCols].concat(csvRows);
    const csv = csvStringify(csvData);

    return `data:text/csv;charset=utf-8,${encodeURI(csv || "")}`;
  }

  render() {
    // $FlowFixMe: add defs for react-router
    const { assignmentID } = this.props.match.params;

    return (
      <BaseDataPage fetchData={this.fetchData} renderSkeleton={renderSkeleton}>
        <AppDataCacheCtx.Consumer>
          {({ cache }) => {
            const assignment = cache.assignments[assignmentID];

            return (
              <BaseLayoutPage>
                <PageContent
                  title={assignment.name}
                  actions={[
                    <Button
                      small
                      secondary
                      el={"a"}
                      href={this.downloadCSVLink(cache)}
                    >
                      <FaFileDownload /> CSV Report
                    </Button>,
                    <DownloadSubmissionsButton assignmentID={assignmentID} />,
                    <Button small secondary el={Link} to="edit">
                      <FaPen /> Edit Assignment
                    </Button>
                  ]}
                >
                  <Table
                    columns={this.getTableColumns(cache)}
                    rows={this.getTableRows(cache)}
                  />
                </PageContent>
              </BaseLayoutPage>
            );
          }}
        </AppDataCacheCtx.Consumer>
      </BaseDataPage>
    );
  }
}

export default GradebookPage;
