// @flow
import * as React from "react";
import AppDataCacheCtx from "../../lib/data-cache";
import getResultSetSimpleStatus from "../../lib/result-set-simple-status";
import {
  FailedIndicator,
  InProgressIndicator,
  NeutralIndicator,
  SuccessIndicator
} from "../style-guide/indicators";

type Props = {|
  resultSetID: string
|};

class ResultSetIndicator extends React.Component<Props> {
  render() {
    const { resultSetID } = this.props;

    return (
      <AppDataCacheCtx.Consumer>
        {({ cache }) => {
          const resultSet = cache.resultSets[resultSetID];

          if (!resultSet) {
            throw Error("result set is undefined");
          }

          switch (getResultSetSimpleStatus(resultSet)) {
            case "IN_PROGRESS":
              return <InProgressIndicator />;
            case "SUCCESS":
              return <SuccessIndicator />;
            case "FAILED":
              return <FailedIndicator />;
            case "QUEUED":
            default:
              return <NeutralIndicator />;
          }
        }}
      </AppDataCacheCtx.Consumer>
    );
  }
}

export default ResultSetIndicator;
