// @flow
import * as React from "react";
import { Spacer, SpacerLg, SpacerXsm } from "../../style-guide/spacer";
import Button from "../../style-guide/button";
import Dropzone from "../../style-guide/dropzone";
import Label from "../../style-guide/label";
import ExternalLink from "../../style-guide/external-link";
import ConsoleOutput from "../../console-output";
import Input from "../../style-guide/input";
import Pre from "../../style-guide/pre";
import styles from "./OutputMatching.module.css";

import type { StdoutLine } from "../../console-output";

type Props = {|
  inputFileLinks: string[],
  outputFileLinks: string[],
  args: string,
  stdout: StdoutLine[],
  editable?: boolean,
  saving: boolean,
  saveError: ?string,

  onArgsChange: (args: string) => any,
  onStdoutChange: (stdout: StdoutLine[]) => any,
  onInputFileDelete: (path: string) => any,
  onInputFilesUpload: (files: File[]) => any,
  onOutputFileDelete: (path: string) => any,
  onOutputFilesUpload: (files: File[]) => any,
  onSave: () => any
|};

const getDisplayPath = (p: string): string => {
  if (p.indexOf("/in_files/") >= 0) {
    return p.substring(p.lastIndexOf("/in_files") + 10);
  } else if (p.indexOf("/out_files/") >= 0) {
    return p.substring(p.lastIndexOf("/out_files") + 11);
  } else {
    return p;
  }
};

class OutputMatching extends React.Component<Props> {
  renderReadOnlyFiles(label: string, fileLinks: string[]) {
    if (!fileLinks.length) {
      return null;
    }

    return (
      <div>
        {label}
        {fileLinks.map((p, i) => (
          <span key={p}>
            <ExternalLink href={p}>{getDisplayPath(p)}</ExternalLink>
            {i === fileLinks.length - 1 ? null : ", "}
          </span>
        ))}
      </div>
    );
  }

  renderReadOnlyIOFiles() {
    const { inputFileLinks, outputFileLinks } = this.props;

    return (
      <>
        {this.renderReadOnlyFiles("Read files from: ", inputFileLinks)}
        {inputFileLinks.length ? <SpacerXsm /> : null}
        {this.renderReadOnlyFiles("Write files to: ", outputFileLinks)}
        {inputFileLinks.length || outputFileLinks.length ? <Spacer /> : null}
      </>
    );
  }

  renderEditableIOFiles() {
    const {
      onInputFileDelete,
      onInputFilesUpload,
      onOutputFileDelete,
      onOutputFilesUpload
    } = this.props;

    return (
      <>
        <SpacerLg />
        <div className={styles.ioFiles}>
          <div>
            <Label htmlFor="">Input files</Label>
            <Dropzone
              paths={this.props.inputFileLinks.map(getDisplayPath)}
              onDelete={onInputFileDelete}
              onUpload={onInputFilesUpload}
            />
          </div>
          <div>
            <Label htmlFor="">Expected output files</Label>
            <Dropzone
              paths={this.props.outputFileLinks.map(getDisplayPath)}
              onDelete={onOutputFileDelete}
              onUpload={onOutputFilesUpload}
            />
          </div>
        </div>
      </>
    );
  }

  renderArgs() {
    const { editable, args, onArgsChange } = this.props;

    if (editable) {
      return (
        <>
          <Input
            label="Arguments"
            helpTooltip={
              <div>
                Arguments passed to the program when running it.
                <br />
                <br />
                Example:
                <Pre inline>first_arg second_arg</Pre>
              </div>
            }
            value={args}
            onChange={onArgsChange}
          />
          <Spacer />
        </>
      );
    }

    if (!args) {
      return null;
    }

    return (
      <>
        <Pre inline>$ ./run {args}</Pre>
        <Spacer />
      </>
    );
  }

  render() {
    const {
      stdout,
      editable,
      saving,
      saveError,
      onStdoutChange,
      onSave
    } = this.props;

    return (
      <div className={styles.container}>
        {!editable && this.renderReadOnlyIOFiles()}
        {this.renderArgs()}
        <ConsoleOutput
          stdout={stdout}
          editable={editable}
          onChange={onStdoutChange}
        />
        {editable && this.renderEditableIOFiles()}
        {editable && (
          <div className={styles.actionBar}>
            <Spacer />
            <Button secondary small onClick={onSave}>
              {saving ? "Saving.." : "Save"}
            </Button>
            <div>{saveError}</div>
          </div>
        )}
      </div>
    );
  }
}

export default OutputMatching;
