// @flow
import * as React from "react";
import { Spacer } from "../style-guide/spacer";
import ExternalLink from "../style-guide/external-link";
import ConsoleOutput from "../console-output";

type Props = {|
  logFiles: string[],

  logs: ?string,
  loadingLogs: boolean,
  logsLoadError: ?string
|};

const getLogName = (path: string): string => {
  const fileName = path.substr(path.lastIndexOf("/") + 1);

  switch (fileName) {
    case "build.sh.out":
      return "build";
    case "run.sh.out":
      return "stdout";
    case "test.sh.out":
      return "test script";
    case "results.json":
      return "results";
    case "docker.build.out":
      return "docker";
    default:
      return fileName;
  }
};

class ResultSetLogs extends React.Component<Props> {
  renderLogs() {
    const { logs, logsLoadError } = this.props;

    if (logsLoadError) {
      return <div>Failed to load logs..</div>;
    }

    if (typeof logs !== "string") {
      return null;
    }

    const logsLines = logs.split("\n").map((line, i) => ({
      line_num: i,
      test_operator: "EQUALS",
      test_operand: line
    }));

    return <ConsoleOutput stdout={logsLines} />;
  }

  renderAllLogs() {
    const { logFiles } = this.props;

    const links = logFiles.map((l, i) => (
      <span key={l}>
        <ExternalLink href={`/api/blobs/${l}`}>{getLogName(l)}</ExternalLink>
        {i === logFiles.length - 1 ? null : ", "}
      </span>
    ));

    return <div>All logs: {links}</div>;
  }

  render() {
    return (
      <div>
        {this.renderLogs()}
        <Spacer />
        {this.renderAllLogs()}
      </div>
    );
  }
}

export default ResultSetLogs;
