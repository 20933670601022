// @flow
import * as React from "react";
import { Spacer } from "../../modules/style-guide/spacer";
import Button from "../../modules/style-guide/button";
import Input from "../../modules/style-guide/input";
import styles from "./ExerciseSet.module.css";

import type { ExerciseSet } from "../../lib/api/exercise-sets";

type Props = {|
  exerciseSet: ExerciseSet,
  onSave: (title: string) => any,
  saving: boolean,
  saveError: ?string
|};

type State = {|
  title: string,
  editing: boolean
|};

class MetadataEditor extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      title: this.props.exerciseSet.title,
      editing: false
    };
  }

  handleTitleChange = (title: string) => {
    this.setState({ title });
  };

  handleSave = () => {
    const { saving, onSave } = this.props;
    const { title } = this.state;

    if (saving) {
      return;
    }

    onSave(title);

    this.setState({ editing: false });
  };

  render() {
    const { saving } = this.props;
    const { title } = this.state;

    return (
      <>
        <Input label="Title" value={title} onChange={this.handleTitleChange} />
        <Spacer />
        <div className={styles.actionBar}>
          <div />
          <Button small onClick={this.handleSave}>
            {saving ? "Saving.." : "Save"}
          </Button>
        </div>
      </>
    );
  }
}

export default MetadataEditor;
