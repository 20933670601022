// @flow
import * as React from "react";
import { H5 } from "../../modules/style-guide/text";
import NavHr from "./nav-hr";
import { SpacerLg } from "../../modules/style-guide/spacer";
import styles from "./BaseLayout.module.css";

type Props = {|
  children: string,
  accessory?: React.Node
|};

class NavTitle extends React.Component<Props> {
  render() {
    const { accessory, children } = this.props;
    return (
      <>
        <SpacerLg />
        <div className={styles.navTitleContainer}>
          <H5 className={styles.navTitle}>{children}</H5>
          {accessory ? accessory : null}
        </div>
        <NavHr />
      </>
    );
  }
}

export default NavTitle;
