// @flow
import * as React from "react";
import styles from "./CreateCourseForm.module.css";
import MetadataEditor from "./metadata-editor";
import PaymentMethodEditor from "./payment-method-editor";
import type { Course } from "../../lib/api/courses";
import Button from "../style-guide/button";
import UpfrontPaymentForm from "./upfront-payment-form";
import PaymentComplete from "./payment-complete";

const Steps = {
  Metadata: 0,
  PaymentMethod: 1,
  UpfrontPayment: 2,
  Complete: 4
};

const CreateCourseForm = () => {
  const [course, setCourse] = React.useState<$Shape<Course>>({
    tier: "PRO"
  });
  const [step, setStep] = React.useState(Steps.Metadata);

  return (
    <div className={styles.container}>
      {[Steps.PaymentMethod, Steps.UpfrontPayment].indexOf(step) >= 0 && (
        <div className={styles.back}>
          <Button
            link
            onClick={() => {
              switch (step) {
                case Steps.PaymentMethod:
                  setStep(Steps.Metadata);
                  break;
                default:
                  setStep(Steps.PaymentMethod);
                  break;
              }
            }}
          >
            Back
          </Button>
        </div>
      )}

      {step === Steps.Metadata && (
        <MetadataEditor
          course={course}
          onNext={(name, startOn, endOn) => {
            setCourse({
              ...course,
              name,
              start_on: startOn,
              end_on: endOn
            });
            setStep(Steps.PaymentMethod);
          }}
        />
      )}

      {step === Steps.PaymentMethod && (
        <PaymentMethodEditor
          course={course}
          onNext={() => {
            setCourse({ ...course, payment_method: "UPFRONT" });
            setStep(Steps.UpfrontPayment);
          }}
          onCreateCourse={courseID => {
            setCourse({
              ...course,
              id: courseID,
              payment_method: "ON_ENROLL"
            });
            setStep(Steps.Complete);
          }}
        />
      )}

      {step === Steps.UpfrontPayment && (
        <UpfrontPaymentForm
          course={course}
          onCreateCourse={(courseID, paymentID) => {
            setCourse({
              ...course,
              id: courseID,
              payment_id: paymentID,
              payment_method: "UPFRONT"
            });
            setStep(Steps.Complete);
          }}
        />
      )}

      {step === Steps.Complete && <PaymentComplete course={course} />}
    </div>
  );
};

export default CreateCourseForm;
