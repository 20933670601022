// @flow
import * as React from "react";
import Notification from "../style-guide/notification";
import { Spacer } from "../style-guide/spacer";
import AppDataCacheCtx from "../../lib/data-cache";
import { displayDate, parseDate } from "../../lib/dates";
import { hasCourseEnded } from "../../lib/course";

type Props = {
  courseID: string
};

const CourseStatusNotification = (props: Props) => {
  const { cache } = React.useContext(AppDataCacheCtx);
  const course = cache.courses[props.courseID];

  if (!course || course.is_active) {
    return null;
  }

  if (hasCourseEnded(course)) {
    return (
      <>
        <Notification>
          This course has ended and students cannot access it anymore. Please
          create a new course or contact support.
        </Notification>
        <Spacer />
      </>
    );
  }

  return (
    <>
      <Notification>
        This course has not begun yet. Your students can enroll starting{" "}
        {displayDate(parseDate(course.start_on))}
      </Notification>
      <Spacer />
    </>
  );
};

export default CourseStatusNotification;
