// @flow
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(duration);

const API_DATE_FORMAT = "YYYY-MM-DD";

export function displayDateTime(ts: number): string {
  return dayjs.unix(ts).format("L LTS");
}

export function displayRelative(ts: number): string {
  return dayjs.unix(ts).fromNow();
}

export function displayDate(date: Date): string {
  return dayjs(date).format("MMM D, YYYY");
}

export function displayDuration(seconds: number): string {
  const dur = dayjs.duration(seconds, "seconds");
  const hours = dur.asHours();

  const hourPart = Math.floor(hours);
  const minsPart = Math.round((hours - hourPart) * 60);

  const hourStr =
    hourPart < 1 ? "" : hourPart === 1 ? "1 hour" : `${hourPart} hours`;
  const minsStr = minsPart === 0 ? "" : `${minsPart} minutes`;

  return `${hourStr} ${minsStr}`;
}

export function parseDate(date: string): Date {
  const d = dayjs(date, API_DATE_FORMAT);

  if (!d.isValid()) {
    throw new Error(`invalid date: ${date}`);
  }

  return d.toDate();
}

export function apiDate(date: Date): string {
  return dayjs(date).format(API_DATE_FORMAT);
}
