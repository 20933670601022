// @flow
import * as React from "react";
import styles from "./CounterPill.module.css";

type Props = {|
  children: string | number
|};

class CounterPill extends React.Component<Props> {
  render() {
    const { children } = this.props;
    return <div className={styles.counterPill}>{children}</div>;
  }
}

export default CounterPill;
