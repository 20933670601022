// @flow
import * as React from "react";
import { H4, RunningText } from "../text";
import { SpacerSm, SpacerXsm } from "../spacer";
import styles from "./HelpSection.module.css";

type Props = {|
  icon?: React.ComponentType<{}>,
  title: string,
  children: React.Node
|};

class HelpSection extends React.Component<Props> {
  render() {
    const { icon, title, children } = this.props;
    return (
      <div className={styles.container}>
        {icon ? (
          <>
            <div>{React.createElement(icon, { className: styles.icon })}</div>
            <SpacerXsm />
          </>
        ) : null}
        <div>
          <H4 muted>{title}</H4>
          <SpacerSm />
          <RunningText muted>{children}</RunningText>
        </div>
      </div>
    );
  }
}

export default HelpSection;
