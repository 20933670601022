// @flow
import makeRequest from "../make-request";

export type TestCase = {|
  id: string,
  name: string,
  order: number,
  visible: boolean,
  ui_widget:
    | "OUTPUT_MATCHING"
    | "JUNIT"
    | "CUSTOM_SCRIPT"
    | "WEB_UI"
    | "PYTEST"
    | "GOTEST"
    | "HIDDEN",
  test_case_set_id: string,
  test_case_template_id: string,
  workspace_id: string
|};

export async function updateTestCase(
  testCaseID: string,
  name: string,
  visible: boolean
): Promise<any> {
  const url = `/api/test-cases/${testCaseID}`;
  const data = JSON.stringify({
    name,
    visible
  });

  return makeRequest(url, { method: "POST", body: data });
}

export async function deleteTestCase(testCaseID: string): Promise<any> {
  const url = `/api/test-cases/${testCaseID}`;

  return makeRequest(url, { method: "DELETE" });
}

export async function fetchTestCase(testCaseID: string): Promise<TestCase> {
  const url = `/api/test-cases/${testCaseID}`;

  return makeRequest(url);
}

export async function fetchTestCases(
  testCaseSetID: string
): Promise<TestCase[]> {
  const url = `/api/test-case-sets/${testCaseSetID}/test-cases`;

  return makeRequest(url);
}

export async function create(
  name: string,
  setID: string,
  templateID: string
): Promise<TestCase> {
  const url = `/api/test-cases`;

  return makeRequest(url, {
    method: "POST",
    body: JSON.stringify({
      name,
      test_case_set_id: setID,
      test_case_template_id: templateID
    })
  });
}
