// @flow
import * as React from "react";
import styles from "./Pill.module.css";

type Props = {|
  children: React.Node
|};

class Pill extends React.Component<Props> {
  render() {
    return <span className={styles.pill}>{this.props.children}</span>;
  }
}

export default Pill;
