// @flow

import type { ResultSet } from "./api/attempts";

export type ResultSetSimpleStatus =
  | "QUEUED"
  | "IN_PROGRESS"
  | "SUCCESS"
  | "FAILED";

export default function getResultSetSimpleStatus(
  resultSet: ResultSet
): ResultSetSimpleStatus {
  switch (resultSet.status) {
    case "PREPARING":
    case "BUILDING_DOCKER":
    case "RUNNING":
    case "UPLOADING_LOGS":
      return "IN_PROGRESS";
    case "COMPLETED": {
      return resultSet.did_pass ? "SUCCESS" : "FAILED";
    }
    case "TIMED_OUT":
    case "RUN_NON_ZERO_EXIT_CODE":
    case "FAILED":
      return "FAILED";
    case "QUEUED":
    default:
      return "QUEUED";
  }
}
