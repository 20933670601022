// @flow
import * as React from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { values } from "lodash";
import AppDataCacheCtx from "../../lib/data-cache";
import TestCaseSet from "./test-case-set-component";

import type { TestCase } from "../../lib/api/test-cases";
import type { ResultSet } from "../../lib/api/attempts";

type Props = {|
  testCaseSetID: string,
  docked?: boolean,
  collapsed?: boolean
|};

type State = {|
  view: "TEST_CASE" | "RESULTS" | "LOGS"
|};

class TestCaseSetContainer extends React.Component<Props, State> {
  getAttemptID(): ?string {
    // $FlowFixMe: add defs for react-router
    return queryString.parse(this.props.location.search).attemptID;
  }

  getTestCaseID(): ?string {
    // $FlowFixMe: add defs for react-router
    return queryString.parse(this.props.location.search).testCaseID;
  }

  getView(): "test_case" | "my_results" | "logs" {
    // $FlowFixMe: add defs for react-router
    return queryString.parse(this.props.location.search).view || "test_case";
  }

  setTestCaseID(id: string) {
    // $FlowFixMe: add defs for react-router
    const { history, location } = this.props;

    history.push({
      search: `?${queryString.stringify({
        ...queryString.parse(location.search),
        testCaseID: id
      })}`
    });
  }

  handleTestCaseChange = (testCase: TestCase) => {
    this.setTestCaseID(testCase.id);
  };

  render() {
    const { testCaseSetID, docked, collapsed } = this.props;
    const testCaseID = this.getTestCaseID();
    const attemptID = this.getAttemptID();
    const view = this.getView();

    return (
      <AppDataCacheCtx.Consumer>
        {({ cache }) => {
          const allResultSets: ResultSet[] = values(cache.resultSets);
          const allTestCases: TestCase[] = values(cache.testCases);
          const testCases = allTestCases.filter(
            tc => tc.test_case_set_id === testCaseSetID
          );

          if (!testCases.length) {
            return null;
          }

          const selectedTestCaseID = testCaseID || testCases[0].id;
          const resultSets = attemptID
            ? allResultSets.filter(rs => rs.attempt_id === attemptID)
            : [];

          return (
            <TestCaseSet
              docked={docked}
              collapsed={collapsed}
              testCases={testCases}
              resultSets={resultSets}
              selectedTestCaseID={selectedTestCaseID}
              view={view}
              onTestCaseChange={this.handleTestCaseChange}
            />
          );
        }}
      </AppDataCacheCtx.Consumer>
    );
  }
}

export default withRouter(TestCaseSetContainer);
