// @flow
import * as React from "react";
import ReactModal from "react-modal";

ReactModal.setAppElement("#root");

type Props = {|
  isOpen: boolean,
  onRequestClose?: () => any,
  children: React.Node,
  wide?: ?boolean
|};

const styles = ({ wide }) => ({
  overlay: {
    backgroundColor: "rgba(26, 37, 46, 0.95)"
  },
  content: {
    width: wide ? "800px" : "500px",
    top: "10%",
    left: 0,
    right: 0,
    maxHeight: "80%",
    bottom: "unset",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: "5px",
    padding: 0
  }
});

class Modal extends React.Component<Props> {
  render() {
    const { children, wide, ...modalProps } = this.props;

    return (
      <ReactModal {...modalProps} style={{ ...styles({ wide }) }}>
        {children}
      </ReactModal>
    );
  }
}

export default Modal;
