// @flow
import * as React from "react";
import { withRouter } from "react-router-dom";
import { isEqual, keyBy, mapValues, merge, omitBy } from "lodash";
import { UnauthorizedError } from "../../lib/api/make-request";
import AppDataCacheCtx, { emptyAppDataCache } from "../../lib/data-cache";

import * as ProfileAPI from "../../lib/api/profile";
import * as CoursesAPI from "../../lib/api/courses";

import type { AppDataCache } from "../../lib/data-cache";

type Props = {|
  fetchData: () => Promise<?$Shape<AppDataCache>>,
  children: React.Node,
  allowUnverified?: boolean,

  renderSkeleton: (progress: number, error: ?string) => React.Node
|};

type State = {|
  loading: boolean,
  error: ?string,
  progress: number,

  data: AppDataCache
|};

class BaseDataPage extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      loading: true,
      error: null,
      progress: 0,
      data: emptyAppDataCache()
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: Props) {
    // $FlowFixMe: add defs for react-router
    const prevParams = prevProps.match.params;
    // $FlowFixMe: add defs for react-router
    const { params } = this.props.match;

    if (!isEqual(prevParams, params)) {
      this.fetchData();
    }
  }

  handleUpdateAppData = (data: $Shape<AppDataCache>) => {
    this.setState(s => {
      const mergedData = merge({}, s.data, data);
      const omitDeleted = mapValues(mergedData, cacheByID =>
        omitBy(cacheByID, obj => obj._deleted)
      );

      return {
        data: omitDeleted
      };
    });
  };

  async fetchData() {
    // $FlowFixMe: add defs for react-router
    const { history, location, allowUnverified } = this.props;

    this.setState({
      progress: 0,
      loading: true,
      error: null
    });

    const progressInterval = setInterval(() => {
      const { progress } = this.state;

      if (progress < 50) {
        this.setState({ progress: 50 });
      } else if (progress < 75) {
        this.setState({ progress: 75 });
      } else if (progress < 90) {
        this.setState({ progress: 90 });
      } else if (progress < 99) {
        this.setState({ progress: progress + 1 });
      }
    }, 300);

    try {
      const profile = await ProfileAPI.fetchProfile();

      const session = {
        profiles: {
          [profile.id]: profile
        },
        session: {
          profileID: profile.id
        }
      };

      if (!profile.verified && !allowUnverified) {
        history.push("/signup/verify");
        this.setState(s => ({
          loading: false,
          data: {
            ...s.data,
            ...session
          }
        }));
        return;
      }

      const [courses, appData] = await Promise.all([
        profile.verified ? CoursesAPI.fetchCourses() : [],
        this.props.fetchData()
      ]);

      this.setState(s => ({
        loading: false,
        data: merge(
          {},
          s.data,
          { courses: keyBy(courses, c => c.id) },
          appData,
          session
        )
      }));
    } catch (e) {
      if (e instanceof UnauthorizedError) {
        history.push(`/login?to=${location.pathname}?${location.search}`);
        return;
      }

      this.setState({
        loading: false,
        progress: 100,
        error: "Failed to load page.."
      });
    } finally {
      clearInterval(progressInterval);
    }
  }

  render() {
    const { renderSkeleton } = this.props;
    const { data, loading, error, progress } = this.state;

    return (
      <AppDataCacheCtx.Provider
        value={{ cache: data, updateCache: this.handleUpdateAppData }}
      >
        {loading || error
          ? renderSkeleton(progress, error)
          : this.props.children}
      </AppDataCacheCtx.Provider>
    );
  }
}

export default (withRouter(BaseDataPage): React.ComponentType<Props>);
