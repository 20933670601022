// @flow
import * as React from "react";
import { chunk } from "lodash";
import Checkbox from "../checkbox";
import { Spacer } from "../spacer";
import styles from "./MultiSelector.module.css";

export type MultiSelectorItem = {|
  id: string,
  label: React.Node,
  disabled?: boolean,
  accessory?: React.Node
|};

type Props = {|
  items: MultiSelectorItem[],
  selectedItemIDs: string[],
  chunkSize: number,

  onChange: (items: MultiSelectorItem[]) => any
|};

class MultiSelector extends React.Component<Props> {
  static defaultProps = {
    chunkSize: 5,
    selectedItemIDs: []
  };

  handleChange = (item: MultiSelectorItem, checked: boolean) => {
    const { items, selectedItemIDs, onChange } = this.props;
    const selectedItems: MultiSelectorItem[] = selectedItemIDs
      .map(id => items.find(i => i.id === id))
      .filter(Boolean);

    if (item.disabled) {
      return;
    }

    if (checked) {
      onChange([...selectedItems, item]);
    } else {
      onChange(selectedItems.filter(i => i.id !== item.id));
    }
  };

  render() {
    const { items, chunkSize, selectedItemIDs } = this.props;
    const itemChunks = chunk(items, chunkSize);

    return (
      <div className={styles.container}>
        {itemChunks.map((items, i) => (
          <div key={i} className={styles.column}>
            {items.map(i => (
              <div key={i.id} className={styles.item}>
                <Checkbox
                  label={i.label}
                  disabled={i.disabled}
                  checked={selectedItemIDs.indexOf(i.id) >= 0}
                  onChange={checked => this.handleChange(i, checked)}
                />
                {i.accessory ? (
                  <>
                    <Spacer />
                    {i.accessory}
                  </>
                ) : null}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

export default MultiSelector;
