// @flow
import * as React from "react";
import { H3 } from "../text";
import { Spacer, SpacerSm } from "../spacer";
import Card from "../card";
import styles from "./NullStateCard.module.css";

type Props = {|
  backgroundEl?: React.ComponentType<{}>,
  illustrationEl?: React.ComponentType<{}>,
  compact?: boolean,
  title: string,
  content: string,
  cta: React.Node,
  pro?: boolean
|};

class NullStateCard extends React.Component<Props> {
  render() {
    const {
      backgroundEl,
      illustrationEl,
      title,
      content,
      cta,
      pro,
      compact
    } = this.props;
    return (
      <Card
        className={[
          styles.container,
          pro ? styles.pro : "",
          compact ? styles.compact : ""
        ].join(" ")}
      >
        {backgroundEl
          ? React.createElement(backgroundEl, {
              className: styles.background
            })
          : null}
        {illustrationEl ? (
          <>
            {React.createElement(illustrationEl, {
              className: styles.illustration
            })}
            <SpacerSm />
          </>
        ) : null}
        <H3>{title}</H3>
        <SpacerSm />
        <div className={styles.content}>{content}</div>
        <Spacer />
        {cta}
      </Card>
    );
  }
}

export default NullStateCard;
