// @flow
import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ExerciseOverviewPage from "./pages/exercise-overview";
import WorkspacePage from "./pages/workspace";
import AttemptPage from "./pages/attempt";
import EditExercisePage from "./pages/edit-exercise";
import EditTestCasePage from "./pages/edit-test-case";
import EditExerciseSetPage from "./pages/edit-exercise-set";
import CourseOverviewPage from "./pages/course-overview";
import LoginPage from "./pages/login";
import SignupPage from "./pages/signup";
import DashboardPage from "./pages/dashboard";
import SignupVerifyPage from "./pages/signup-verify";
import ResetPasswordPage from "./pages/reset-password";
import GradebookPage from "./pages/gradebook";
import AssignmentPage from "./pages/assignment";
import CourseRosterPage from "./pages/course-roster";
import ExerciseSetsPage from "./pages/exercise-sets";
import ProfilePage from "./pages/profile";
import CourseManagePage from "./pages/course-manage";
import ExerciseSetPage from "./pages/exercise-set";
import GettingStartedPage from "./pages/getting-started";
import CourseEnrollPage from "./pages/course-enroll";
import SignupSurveyPage from "./pages/signup-survey";
import SampleExerciseSetsPage from "./pages/sample-exercise-sets";
import CS50ExerciseSetsPage from "./pages/cs50-exercise-sets";

const AppRouter = () => (
  <Router>
    <Switch>
      <Route exact strict path="/" component={DashboardPage} />

      <Route exact strict path="/login" component={LoginPage} />

      <Route exact strict path="/signup" component={SignupPage} />

      <Route exact strict path="/signup/verify" component={SignupVerifyPage} />

      <Route exact strict path="/signup/survey" component={SignupSurveyPage} />

      <Route exact strict path="/profile" component={ProfilePage} />

      <Route
        exact
        strict
        path="/forgot-password"
        component={ResetPasswordPage}
      />

      <Route
        exact
        strict
        path="/getting-started"
        component={GettingStartedPage}
      />

      <Route exact strict path="/courses/enroll" component={CourseEnrollPage} />

      <Route exact strict path="/questions" component={ExerciseSetsPage} />

      <Route
        exact
        strict
        path="/questions/sample"
        component={SampleExerciseSetsPage}
      />

      <Route
        exact
        strict
        path="/questions/cs50"
        component={CS50ExerciseSetsPage}
      />

      <Route
        exact
        strict
        path="/courses/:courseID/assignments"
        component={CourseOverviewPage}
      />

      <Route
        exact
        strict
        path="/courses/:courseID/roster"
        component={CourseRosterPage}
      />

      <Route
        exact
        strict
        path="/courses/:courseID/manage"
        component={CourseManagePage}
      />

      <Route
        exact
        strict
        path="/courses/:courseID/assignments/create"
        component={AssignmentPage}
      />

      <Route
        exact
        strict
        path="/courses/:courseID/assignments/:assignmentID/gradebook"
        component={GradebookPage}
      />

      <Route
        exact
        strict
        path="/courses/:courseID/assignments/:assignmentID/edit"
        component={AssignmentPage}
      />

      <Route
        exact
        strict
        path="/courses/:courseID/assignments/:assignmentID/questions/:exerciseSetID"
        component={ExerciseSetPage}
      />

      <Route
        exact
        strict
        path="/courses/:courseID/assignments/:assignmentID/questions/:exerciseSetID/:exerciseID"
        component={ExerciseOverviewPage}
      />

      <Route
        exact
        strict
        path="/courses/:courseID/assignments/:assignmentID/ides/:ideID/attempt"
        component={AttemptPage}
      />

      <Route
        exact
        strict
        path="/questions/:exerciseSetID/:exerciseID/:testCaseID/edit"
        component={EditTestCasePage}
      />

      <Route
        exact
        strict
        path={"/ides/:ideID/attempt"}
        component={AttemptPage}
      />

      <Route
        exact
        strict
        path="/questions/:exerciseSetID/:exerciseID/edit"
        component={EditExercisePage}
      />

      <Route
        exact
        strict
        path="/questions/:exerciseSetID/edit"
        component={EditExerciseSetPage}
      />

      <Route
        exact
        strict
        path="/questions/:exerciseSetID/:exerciseID"
        component={ExerciseOverviewPage}
      />

      <Route
        exact
        strict
        path="/questions/:exerciseSetID"
        component={ExerciseSetPage}
      />

      <Route
        exact
        strict
        path="/workspaces/:workspaceID"
        component={WorkspacePage}
      />
    </Switch>
  </Router>
);

export default AppRouter;
