// @flow
import * as React from "react";
import { H5, RunningText } from "../../modules/style-guide/text";
import { SpacerSm } from "../../modules/style-guide/spacer";
import ActionBar from "../../modules/style-guide/action-bar";
import Button from "../../modules/style-guide/button";
import ConfirmAction from "../../modules/style-guide/confirm-action";

type Props = {|
  courseID: string,

  onDelete: () => Promise<any>
|};

type State = {|
  deletingCourse: boolean,
  deleteCourseError: ?string
|};

class DeleteCourse extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      deletingCourse: false,
      deleteCourseError: null
    };
  }

  handleDeleteCourse = async () => {
    try {
      this.setState({ deletingCourse: true, deleteCourseError: null });
      await this.props.onDelete();
    } catch (e) {
      this.setState({
        deletingCourse: false,
        deleteCourseError: "Failed to delete course"
      });
    }
  };

  render() {
    const { deletingCourse, deleteCourseError } = this.state;

    return (
      <>
        <H5>Delete Course</H5>
        <SpacerSm />
        <RunningText>
          If this course is completed, you can delete it to make room for new
          courses. This course will not be accessible to you, other instructors,
          or any students. New students will not be able to enroll in this
          course.
        </RunningText>
        <ActionBar error={deleteCourseError}>
          <ConfirmAction
            wide
            title="Delete Course"
            message="This action cannot be undone."
            placement="RIGHT"
            onConfirm={this.handleDeleteCourse}
          >
            <Button small secondary danger>
              {deletingCourse ? "Deleting Course.." : "Delete Course"}
            </Button>
          </ConfirmAction>
        </ActionBar>
      </>
    );
  }
}

export default DeleteCourse;
