// @flow

import type { Course } from "./api/courses";
import { parseDate } from "./dates";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

export const PRICE_PER_STUDENT_PER_MONTH = 4;

export function hasCourseEnded(course: Course): boolean {
  const endOn = dayjs(parseDate(course.end_on));

  return endOn.isBefore(dayjs());
}

export function hasCourseBegun(course: Course): boolean {
  const startOn = dayjs(parseDate(course.start_on));

  return startOn.isBefore(dayjs());
}

export function getCourseDuration({
  start_on,
  end_on
}: $Shape<Course>): number {
  if (!start_on || !end_on) {
    throw new Error("course start and end dates must be set");
  }

  const endOn = dayjs(parseDate(end_on));

  let curr = dayjs(parseDate(start_on));
  let months = 0;

  while (curr.isBefore(endOn)) {
    months++;
    curr = curr.add(1, "month");
  }

  return months;
}

export function getCourseDurationLabel(course: $Shape<Course>): string {
  const months = getCourseDuration(course);

  if (months === 1) {
    return "1 month";
  }

  return `${months} months`;
}

export function getCoursePricePerStudent(course: $Shape<Course>): number {
  const months = getCourseDuration(course);

  return months * PRICE_PER_STUDENT_PER_MONTH;
}

export function getCourseTotalPrice(course: $Shape<Course>): number {
  const months = getCourseDuration(course);

  return PRICE_PER_STUDENT_PER_MONTH * (course.seats || 0) * months;
}
