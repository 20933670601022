// @flow
import * as React from "react";
import { H5 } from "../../style-guide/text";
import { SpacerSm } from "../../style-guide/spacer";
import Pre from "../../style-guide/pre";
import { ChevronDown, ChevronUp, DeleteIcon } from "../../style-guide/icons";
import styles from "./WebUITests.module.css";

import type { WebUITest, WebUITestAction } from "./index";
import type { ListItem } from "../../style-guide/list";

function renderAction(action: WebUITestAction) {
  switch (action.type) {
    case "GO_TO":
      return (
        <span>
          Go to <Pre inline>{action.url}</Pre>
        </span>
      );
    case "MATCH":
      return (
        <span>
          Match <Pre inline>{action.matcher}</Pre> on page
        </span>
      );
    case "FILL":
      return (
        <span>
          Fill <Pre inline>{action.selector}</Pre> with{" "}
          <Pre inline>{action.value}</Pre>
        </span>
      );
    case "CLICK":
      return (
        <span>
          Click <Pre inline>{action.selector}</Pre>{" "}
          {action.text && (
            <>
              with text <Pre inline>{action.text}</Pre>
            </>
          )}
        </span>
      );
    default:
      return <div>Unknown action</div>;
  }
}

type Props = {|
  test: WebUITest,
  testIdx: number,
  editable?: boolean,

  onActionMoveUp: (testIdx: number, actionIdx: number) => any,
  onActionMoveDown: (testIdx: number, actionIdx: number) => any,
  onActionDelete: (testIdx: number, actionIdx: number) => any
|};

function getActionListItems(props: Props): ListItem[] {
  return props.test.actions.map((action, actionIdx) => ({
    id: `action-${actionIdx}`,
    className: styles.sequenceStep,
    label: (
      <>
        <H5>Step {actionIdx + 1}</H5>
        <SpacerSm />
        {renderAction(action)}
      </>
    ),
    actions: props.editable
      ? [
          () => (
            <ChevronUp
              onClick={() => props.onActionMoveUp(props.testIdx, actionIdx)}
            />
          ),
          () => (
            <ChevronDown
              onClick={() => props.onActionMoveDown(props.testIdx, actionIdx)}
            />
          ),
          () => (
            <DeleteIcon
              onClick={() => props.onActionDelete(props.testIdx, actionIdx)}
            />
          )
        ]
      : []
  }));
}

export default getActionListItems;
