// @flow
import * as React from "react";
import AppDataCacheCtx from "../../../lib/data-cache";
import TitleDescriptionEditor from "./title-description-editor-component";

import * as ExercisesAPI from "../../../lib/api/exercises";
import type { updateAppDataCacheFunc } from "../../../lib/data-cache";

type Props = {|
  exerciseID: string,
  next: () => any
|};

type State = {|
  saving: boolean,
  saveError: ?string
|};

class TitleDescriptionEditorContainer extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      saving: false,
      saveError: null
    };
  }

  handleSave = (updateCache: updateAppDataCacheFunc) => async (
    title: string,
    description: string
  ) => {
    const { exerciseID, next } = this.props;
    const { saving } = this.state;

    if (saving) {
      return;
    }

    this.setState({ saving: true, saveError: null });
    try {
      await ExercisesAPI.updateExercise(exerciseID, { title, description });

      this.setState({ saving: false });

      updateCache({
        exercises: {
          [exerciseID]: {
            title,
            description
          }
        }
      });

      next();
    } catch (e) {
      this.setState({ saving: false, saveError: "Failed to save exercise" });
    }
  };

  render() {
    return (
      <AppDataCacheCtx.Consumer>
        {({ cache, updateCache }) => {
          const exercise = cache.exercises[this.props.exerciseID];

          return (
            <TitleDescriptionEditor
              title={exercise.title}
              description={exercise.description}
              onSave={this.handleSave(updateCache)}
              {...this.state}
            />
          );
        }}
      </AppDataCacheCtx.Consumer>
    );
  }
}

export default TitleDescriptionEditorContainer;
