// @flow
import * as React from "react";
import { H3 } from "../../modules/style-guide/text";
import { Spacer, SpacerSm } from "../../modules/style-guide/spacer";
import Card from "../../modules/style-guide/card";
import Input from "../../modules/style-guide/input";
import ActionBar from "../../modules/style-guide/action-bar";
import Button from "../../modules/style-guide/button";
import styles from "./Profile.module.css";

type Props = {|
  onChangePassword: (oldPassword: string, newPassword: string) => Promise<any>
|};

type State = {|
  oldPassword: string,
  newPassword: string,
  confirmPassword: string,

  changingPassword: boolean,
  changePasswordError: ?string
|};

class ChangePassword extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",

      changingPassword: false,
      changePasswordError: null
    };
  }

  handleChangePassword = async () => {
    const { oldPassword, newPassword, confirmPassword } = this.state;

    if (newPassword.length < 6) {
      this.setState({ changePasswordError: "New password is too short" });
      return;
    }

    if (newPassword !== confirmPassword) {
      this.setState({ changePasswordError: "Passwords do not match" });
      return;
    }

    try {
      this.setState({ changingPassword: true, changePasswordError: null });
      await this.props.onChangePassword(oldPassword, newPassword);
      this.setState({
        changingPassword: false,
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
      });
    } catch (e) {
      this.setState({
        changingPassword: false,
        changePasswordError: "Failed to change password"
      });
    }
  };

  handleOldPasswordChange = (oldPassword: string) =>
    this.setState({ oldPassword });

  handleNewPasswordChange = (newPassword: string) =>
    this.setState({ newPassword });

  handleConfirmPasswordChange = (confirmPassword: string) =>
    this.setState({ confirmPassword });

  render() {
    const {
      oldPassword,
      newPassword,
      confirmPassword,
      changingPassword,
      changePasswordError
    } = this.state;

    return (
      <>
        <H3>Change Password</H3>
        <Spacer />
        <Card className={styles.padded}>
          <Input
            password
            label="Old Password"
            value={oldPassword}
            onEnter={this.handleChangePassword}
            onChange={this.handleOldPasswordChange}
          />
          <SpacerSm />
          <Input
            password
            label="New Password"
            value={newPassword}
            onEnter={this.handleChangePassword}
            onChange={this.handleNewPasswordChange}
          />
          <SpacerSm />
          <Input
            password
            label="ConfirmPassword"
            value={confirmPassword}
            onEnter={this.handleChangePassword}
            onChange={this.handleConfirmPasswordChange}
          />
          <SpacerSm />
          <ActionBar error={changePasswordError}>
            <Button small onClick={this.handleChangePassword}>
              {changingPassword ? "Changing Password.." : "Change Password"}
            </Button>
          </ActionBar>
        </Card>
      </>
    );
  }
}

export default ChangePassword;
