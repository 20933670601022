// @flow
import * as React from "react";
import queryString from "query-string";
import { Link } from "react-router-dom";
import { UnauthorizedError } from "../../lib/api/make-request";
import { SpacerLg, SpacerSm } from "../../modules/style-guide/spacer";
import Button from "../../modules/style-guide/button";
import { H1, H3, RunningText, Subtitle } from "../../modules/style-guide/text";
import GirlOnLaptop from "../../modules/illustrations/girl-on-laptop";
import AnonymousLayout2, {
  PageContent,
  SideContent
} from "../anonymous-layout-2";
import LoginForm from "./login-form";
import styles from "./Login.module.css";

import * as AuthAPI from "../../lib/api/auth";
import * as ProfileAPI from "../../lib/api/profile";

type Props = {||};

type State = {|
  loggingIn: boolean,
  loginError: ?string
|};

class LoginPage extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      loggingIn: false,
      loginError: null
    };
  }

  handleLogin = async (email: string, password: string) => {
    // $FlowFixMe: add defs for react-router
    const { history, location } = this.props;

    try {
      this.setState({ loggingIn: true, loginError: null });

      await AuthAPI.login(email, password);
      const profile = await ProfileAPI.fetchProfile();

      if (profile.verified) {
        history.push(queryString.parse(location.search).to || "/");
      } else {
        history.push("/signup/verify");
      }
    } catch (e) {
      if (e instanceof UnauthorizedError) {
        this.setState({
          loggingIn: false,
          loginError: "Incorrect email/password"
        });
      } else {
        this.setState({
          loggingIn: false,
          loginError: "Failed to log in"
        });
      }
    }
  };

  render() {
    const { loggingIn, loginError } = this.state;

    return (
      <AnonymousLayout2>
        <SideContent>
          <H3>Unit Test grading is here</H3>
          <SpacerSm />
          <RunningText>
            Prepare your students for programming in the real world. Unit test
            grading can be added to your existing questions, layered on top
            other grading methods.
          </RunningText>
          <SpacerLg />
          <GirlOnLaptop />
        </SideContent>

        <PageContent>
          <div className={styles.centered}>
            <H1>Welcome back</H1>
            <SpacerSm />
            <Subtitle>If you already have an account, log in here</Subtitle>
          </div>
          <SpacerLg />
          <LoginForm
            loggingIn={loggingIn}
            loginError={loginError}
            onLogin={this.handleLogin}
          />
          <SpacerLg />
          <div className={styles.centered}>
            Don't have an account?
            <SpacerSm />
            <Button el={Link} to="/signup" secondary>
              Sign up
            </Button>
          </div>
        </PageContent>
      </AnonymousLayout2>
    );
  }
}

export default LoginPage;
