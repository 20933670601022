// @flow
import makeRequest from "../make-request";

export type Profile = {|
  id: string,
  name: string,
  email: string,
  role: "INSTRUCTOR" | "STUDENT",
  verified: boolean,
  tags: string[],
  moss_user_id: ?string
|};

export async function fetchProfile(): Promise<Profile> {
  const url = `/api/profile`;

  return makeRequest(url);
}

export async function updateProfile(
  name: string,
  mossUserID: ?string,
  role?: "INSTRUCTOR" | "STUDENT"
): Promise<any> {
  const url = `/api/profile`;
  const data = JSON.stringify({
    name,
    moss_user_id: mossUserID,
    role
  });

  return makeRequest(url, { method: "POST", body: data });
}
