// @flow
import * as React from "react";
import { H5 } from "../../style-guide/text";
import { Spacer, SpacerSm } from "../../style-guide/spacer";
import Select from "../../style-guide/select";
import Input from "../../style-guide/input";
import ActionBar from "../../style-guide/action-bar";
import Button from "../../style-guide/button";
import styles from "./WebUITests.module.css";

import type { SelectItem } from "../../style-guide/select";
import type { WebUITestAction } from "./index";

type Props = {|
  onAddNewActionStep: (action: WebUITestAction) => any
|};

type State = {|
  actionType: "NONE" | "GO_TO" | "MATCH" | "FILL" | "CLICK",

  goToURL: string,

  matchText: string,

  fillSelector: string,
  fillValue: string,

  clickSelector: string,
  clickText: string
|};

class NewActionStep extends React.Component<Props, State> {
  state = {
    actionType: "NONE",
    goToURL: "",
    matchText: "",
    fillSelector: "",
    fillValue: "",
    clickSelector: "",
    clickText: ""
  };

  reset() {
    this.setState({
      actionType: "NONE",
      goToURL: "",
      matchText: "",
      fillSelector: "",
      fillValue: "",
      clickSelector: "",
      clickText: ""
    });
  }

  handleActionTypeSelect = (item: SelectItem) => {
    // $FlowFixMe: item.id is always an action type
    this.setState({ actionType: item.id });
  };

  handleGoToURLChange = (goToURL: string) => this.setState({ goToURL });

  handleMatchTextChange = (matchText: string) => this.setState({ matchText });

  handleFillSelectorChange = (fillSelector: string) =>
    this.setState({ fillSelector });

  handleFillValueChange = (fillValue: string) => this.setState({ fillValue });

  handleClickSelectorChange = (clickSelector: string) =>
    this.setState({ clickSelector });

  handleClickTextChange = (clickText: string) => this.setState({ clickText });

  handleAddStepClick = () => {
    switch (this.state.actionType) {
      case "GO_TO":
        this.props.onAddNewActionStep({
          type: "GO_TO",
          url: this.state.goToURL
        });
        break;
      case "MATCH":
        this.props.onAddNewActionStep({
          type: "MATCH",
          matcher: this.state.matchText
        });
        break;
      case "FILL":
        this.props.onAddNewActionStep({
          type: "FILL",
          selector: this.state.fillSelector,
          value: this.state.fillValue
        });
        break;
      case "CLICK":
        this.props.onAddNewActionStep({
          type: "CLICK",
          selector: this.state.clickSelector,
          text: this.state.clickText
        });
        break;
      default:
        break;
    }

    this.reset();
  };

  renderActionFields() {
    const {
      actionType,
      goToURL,
      matchText,
      fillSelector,
      fillValue,
      clickSelector,
      clickText
    } = this.state;

    switch (actionType) {
      case "GO_TO":
        return (
          <Input
            label="URL"
            placeholder="http://localhost:8080"
            value={goToURL}
            onChange={this.handleGoToURLChange}
          />
        );
      case "MATCH":
        return (
          <Input
            label="Text or regex to match against"
            placeholder="Hello, World"
            value={matchText}
            onChange={this.handleMatchTextChange}
          />
        );
      case "FILL":
        return (
          <div className={styles.flex}>
            <Input
              label="Input field CSS selector"
              placeholder='input[name="First Name"]'
              value={fillSelector}
              onChange={this.handleFillSelectorChange}
            />
            <Spacer />
            <Input
              label="Value"
              placeholder="Jane Doe"
              value={fillValue}
              onChange={this.handleFillValueChange}
            />
          </div>
        );
      case "CLICK":
        return (
          <div className={styles.flex}>
            <Input
              label="Element's CSS selector"
              placeholder='button[type="submit"]'
              value={clickSelector}
              onChange={this.handleClickSelectorChange}
            />
            <Spacer />
            <Input
              label="Element's text content (optional)"
              placeholder="Submit"
              value={clickText}
              onChange={this.handleClickTextChange}
            />
          </div>
        );
      case "NONE":
      default:
        return null;
    }
  }

  render() {
    const { actionType } = this.state;

    return (
      <>
        <H5>New Step</H5>
        <SpacerSm />
        <Select
          label="Action type"
          items={[
            { id: "NONE", label: "Select an action" },
            { id: "GO_TO", label: "Go to url" },
            {
              id: "MATCH",
              label: "Match text on page"
            },
            { id: "FILL", label: "Fill input fields" },
            { id: "CLICK", label: "Click" }
          ]}
          value={actionType}
          onSelect={this.handleActionTypeSelect}
        />
        <Spacer />
        {this.renderActionFields()}
        <SpacerSm />
        <ActionBar error={null}>
          <Button
            small
            secondary
            disabled={actionType === "NONE"}
            onClick={this.handleAddStepClick}
          >
            Add Step
          </Button>
        </ActionBar>
      </>
    );
  }
}

export default NewActionStep;
