// @flow
import * as React from "react";
import { uniqueId } from "lodash";
import { FaQuestionCircle } from "react-icons/fa";
import Label from "../label";
import Popover from "../popover";
import styles from "./Input.module.css";

type Props = {
  label?: string,
  helpTooltip?: React.Node,
  password?: boolean,
  onChange?: (val: string) => any,
  onEnter?: () => any,
  compact?: ?boolean,
  wide?: ?boolean,
  className?: string
};

class Input extends React.Component<Props> {
  id: string;

  componentWillMount() {
    this.id = uniqueId("frm-input-");
  }

  handleKeyPress = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      this.props.onEnter && this.props.onEnter();
    }
  };

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.props.onChange && this.props.onChange(e.target.value);
  };

  render() {
    const {
      label,
      password,
      helpTooltip,
      compact,
      wide,
      className,
      onEnter,
      ...inputProps
    } = this.props;
    return (
      <div>
        {label && <Label htmlFor={this.id}>{label}</Label>}
        {helpTooltip && (
          <Popover
            popoverEl={<div className={styles.popover}>{helpTooltip}</div>}
          >
            <FaQuestionCircle className={styles.helpIcon} />
          </Popover>
        )}
        <input
          {...inputProps}
          id={this.id}
          type={password ? "password" : "text"}
          className={`${styles.text} ${compact ? styles.compact : ""} ${
            wide ? styles.wide : ""
          } ${className || ""}`}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
        />
      </div>
    );
  }
}

export default Input;
