import * as React from "react";

function LogoSmall(props) {
  return (
    <svg width={36} height={36} viewBox="0 0 36 36" {...props}>
      <title>{"Group 3"}</title>
      <defs>
        <filter
          x="-68.3%"
          y="-170.8%"
          width="236.7%"
          height="503.8%"
          filterUnits="objectBoundingBox"
          id="prefix__a"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={4}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <path id="prefix__b" d="M3.88 5.957h19.025v6.44H3.881z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M18.342 0L36 18l-10.257-.018-6.42-6.551-6.4 6.529L1 17.94 18.342 0z"
          fill="#0B35CA"
        />
        <g fill="#0B35CA">
          <path d="M4.846 13.17L.293 17.725l4.553 4.553 4.553-4.553zM18.016 0l-4.553 4.553 4.553 4.553 4.554-4.553z" />
        </g>
        <path
          fill="#0B35CA"
          d="M26.764 8.78l4.553 4.554-4.553 4.553-4.553-4.553z"
        />
        <path
          fill="#0B35CA"
          d="M22.374 4.39l4.553 4.553-4.553 4.553-4.553-4.553z"
        />
        <path
          fill="#0B35CA"
          d="M17.984 0l4.553 4.553-4.553 4.553-4.554-4.553z"
        />
        <path
          fill="#8B94EE"
          d="M13.626 4.39l4.553 4.553-4.553 4.553-4.553-4.553z"
        />
        <g>
          <path
            fill="#8B94EE"
            d="M9.236 17.887l-4.553-4.553L9.236 8.78l4.553 4.554z"
          />
          <path
            fill="#0B35CA"
            d="M18.016 35.415l-4.553-4.553 4.553-4.554 4.554 4.554z"
          />
        </g>
        <g>
          <g transform="scale(1 -1) rotate(45 60.956 2.092)">
            <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
            <use fill="#8B94EE" xlinkHref="#prefix__b" />
          </g>
          <path
            fill="#FFF"
            d="M31.154 22.244l4.553-4.553-4.553-4.553-4.553 4.553z"
          />
          <path
            fill="#0B35CA"
            d="M26.764 17.854l4.553-4.553-4.553-4.553-4.553 4.553z"
          />
        </g>
        <path
          fill="#8B94EE"
          d="M4.639 13.256L.086 17.81l17.798 17.798 4.553-4.553z"
        />
      </g>
    </svg>
  );
}

export default LogoSmall;
