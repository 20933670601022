// @flow
import * as React from "react";
import styles from "./Pre.module.css";

type Props = {|
  id?: string,
  className?: string,
  children: React.Node,
  initialLineNum?: number,
  inline?: boolean
|};

class Pre extends React.Component<Props> {
  render() {
    const { id, className, inline, initialLineNum, children } = this.props;
    const counterReset = initialLineNum ? `line ${initialLineNum - 1}` : "line";

    if (inline) {
      return (
        <pre
          id={id}
          className={[styles.inlinePre, className ? className : ""]
            .join(" ")
            .trim()}
        >
          {children}
        </pre>
      );
    }

    return (
      <pre
        id={id}
        className={[styles.pre, className ? className : ""].join(" ").trim()}
        style={{ counterReset }}
      >
        {children}
      </pre>
    );
  }
}

export default Pre;
