// @flow
import * as React from "react";
import { withRouter } from "react-router-dom";
import ExerciseSetOverview from "./exercise-set-overview-component";
import AppDataCacheCtx from "../../lib/data-cache";

import type { Exercise } from "../../lib/api/exercises";

type Props = {||};

class ExerciseSetOverviewContainer extends React.Component<Props> {
  render() {
    // $FlowFixMe
    const { exerciseSetID, exerciseID } = this.props.match.params;

    return (
      <AppDataCacheCtx.Consumer>
        {({ cache }) => {
          const exerciseSet = cache.exerciseSets[exerciseSetID];
          const exercises = ((Object.values(
            cache.exercises
          ): any): Exercise[]).filter(e => e.exercise_set_id === exerciseSetID);

          return (
            <ExerciseSetOverview
              exerciseSet={exerciseSet}
              exercises={exercises}
              selectedExerciseID={exerciseID}
            />
          );
        }}
      </AppDataCacheCtx.Consumer>
    );
  }
}

export default withRouter(ExerciseSetOverviewContainer);
