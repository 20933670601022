// @flow
import * as React from "react";
import AppDataCacheCtx from "../../lib/data-cache";
import ResultSet from "./result-set-component";

type Props = {|
  resultSetID: string
|};

class ResultSetContainer extends React.Component<Props> {
  render() {
    const { resultSetID } = this.props;

    return (
      <AppDataCacheCtx.Consumer>
        {({ cache }) => {
          const resultSet = cache.resultSets[resultSetID];

          if (!resultSet) {
            throw Error("result set is undefined");
          }

          return <ResultSet resultSet={resultSet} />;
        }}
      </AppDataCacheCtx.Consumer>
    );
  }
}

export default ResultSetContainer;
