// @flow
import * as React from "react";
import Accordion from "./index";
import Panel from "./panel";

type Props = {|
  children: React.ChildrenArray<React.Element<typeof Panel>>
|};

type State = {|
  selected: ?number
|};

class StatefulAccordion extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      selected: null
    };
  }

  handleSelect = (index: number) => {
    const { selected } = this.state;
    this.setState({ selected: selected === index ? null : index });
  };

  render() {
    return (
      <Accordion selected={this.state.selected} onSelect={this.handleSelect}>
        {this.props.children}
      </Accordion>
    );
  }
}

export default StatefulAccordion;
