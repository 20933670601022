// @flow
import * as React from "react";
import styles from "./Notification.module.css";

type Props = {|
  children: React.Node
|};

class Notification extends React.Component<Props> {
  render() {
    return <div className={styles.notification}>{this.props.children}</div>;
  }
}

export default Notification;
