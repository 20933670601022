// @flow
import * as React from "react";
import styles from "./LoadingBar.module.css";

type Props = {|
  progress: number
|};

class LoadingBar extends React.Component<Props> {
  render() {
    const { progress } = this.props;

    return (
      <div className={styles.loadingBar} style={{ width: `${progress}%` }} />
    );
  }
}

export default LoadingBar;
