import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import AppRouter from "./router";

import "normalize.css";
import "./styles.css";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_KEY } from "./lib/stripe";
import { Elements as StripeElements } from "@stripe/react-stripe-js";

const stripe = loadStripe(STRIPE_KEY);

ReactDOM.render(
  <StripeElements stripe={stripe}>
    <AppRouter />
  </StripeElements>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
