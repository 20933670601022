// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import BaseDataPage from "../base-data";
import BaseLayoutPage, { renderSkeleton, PageContent } from "../base-layout";
import BasePanel from "../../modules/style-guide/accordion/panel";
import CounterPill from "../../modules/style-guide/counter-pill";
import { SpacerLg, SpacerSm } from "../../modules/style-guide/spacer";
import { ExternalLinkIcon } from "../../modules/style-guide/icons";
import styles from "./GettingStarted.module.css";
import ExternalLink from "../../modules/style-guide/external-link";
import Button from "../../modules/style-guide/button";

type Props = {||};

const Panel = props => {
  const { step, ...panelProps } = props;
  return (
    <BasePanel
      open
      startEnhancer={<CounterPill>{step}</CounterPill>}
      renderSelectButton={() => null}
      className={styles.panel}
      {...panelProps}
    >
      <div className={styles.flex}>
        <SpacerLg horizontal />
        {props.children}
      </div>
    </BasePanel>
  );
};

class GettingStartedPage extends React.Component<Props> {
  static renderPanels() {
    return (
      <>
        <Panel
          step={1}
          title="Welcome to AutoGradr!"
          subtitle={
            <>
              We're excited to have you here and help ease grading for your
              course. On this page, you will find some useful materials to get
              familiar with AutoGradr.
            </>
          }
        >
          <span />
        </Panel>
        <Panel
          step={2}
          title="Try out a question"
          subtitle={
            <>
              Students make submissions through our fully-loaded IDE. Try out
              this sample question to see how AutoGradr runs these submissions
              and provides instant feedback.
            </>
          }
        >
          <Button small secondary el={Link} to={`/questions/ZcwfSh/Km080R`}>
            Try Sample Question
          </Button>
        </Panel>
        <Panel
          step={3}
          title="Demo & Tutorials"
          subtitle={
            <>
              Watch these videos to familiarize yourself with different features
              that are available on AutoGradr. We'll go over how students make
              an attempt and how you can create your questions with different
              grading types.
            </>
          }
        >
          <div className={styles.videosContainer}>
            <iframe
              title={"Student making an attempt"}
              src="https://player.vimeo.com/video/451250937"
              width="600"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
            <iframe
              title={"How to create a question with output matching"}
              src="https://player.vimeo.com/video/451250443"
              width="600"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
            <iframe
              title={"How to create a question with JUnit tests"}
              src="https://player.vimeo.com/video/451250229"
              width="600"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
            <iframe
              title={"How to create an assignment"}
              src="https://player.vimeo.com/video/451250001"
              width="600"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
            <iframe
              title={"How to create a question with unit tests in Python"}
              src="https://player.vimeo.com/video/451250772"
              width="600"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
            <iframe
              title={"How to create a question with unit tests in Go"}
              src="https://player.vimeo.com/video/451250065"
              width="600"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </div>
        </Panel>
        <Panel
          step={4}
          title="Assisted Onboarding"
          subtitle={
            <>
              Book 45 mins with us and we'll help you set up your course on
              AutoGradr over Zoom. We'll create questions and go over your
              requirements and how we can help. Alternatively, send us an email
              here -{" "}
              <a href={"mailto:tushar@autogradr.com"}>tushar@autogradr.com</a>.
            </>
          }
        >
          <Button
            small
            secondary
            el={ExternalLink}
            href={`https://calendly.com/autogradr-tushar/autogradr-office-hours`}
          >
            Book Zoom Meeting
            <SpacerSm horizontal />
            <ExternalLinkIcon />
          </Button>
        </Panel>
      </>
    );
  }

  fetchData = async () => ({});

  render() {
    return (
      <BaseDataPage fetchData={this.fetchData} renderSkeleton={renderSkeleton}>
        <BaseLayoutPage>
          <PageContent title="Getting started">
            {GettingStartedPage.renderPanels()}
          </PageContent>
        </BaseLayoutPage>
      </BaseDataPage>
    );
  }
}

export default GettingStartedPage;
