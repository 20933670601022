// @flow
import dayjs from "dayjs";
import type { Assignment } from "./api/assignments";

export type AssignmentSessionState = "REQUIRED" | "EXPIRED" | "IN_SESSION";

export default function getAssignmentSessionState(
  as: Assignment
): AssignmentSessionState {
  if (!as.session_time_limit) {
    return "IN_SESSION";
  }

  if (!as.session_started_at) {
    return "REQUIRED";
  }

  const sessionExpiresAt = as.session_started_at + as.session_time_limit;
  const isExpired = dayjs().unix() > sessionExpiresAt;

  if (isExpired) {
    return "EXPIRED";
  }

  return "IN_SESSION";
}
