// @flow
import * as React from "react";
import styles from "./Card.module.css";

type Props = {|
  className?: string,
  children: React.Node
|};

class Card extends React.Component<Props> {
  render() {
    const { className, children } = this.props;

    return (
      <div className={`${styles.card} ${className || ""}`}>{children}</div>
    );
  }
}

export default Card;
