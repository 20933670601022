// @flow
import * as React from "react";
import { withAppDataCacheCtx } from "../../lib/data-cache";
import * as BlobsAPI from "../../lib/api/blobs";
import ResultSetLogs from "./result-set-logs-component";

import type { AppDataCache } from "../../lib/data-cache";

type Props = {|
  cache: AppDataCache,
  resultSetID: string
|};

type State = {|
  logs: ?string,
  loadingLogs: boolean,
  logsLoadError: ?string
|};

class ResultSetContainer extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      logs: null,
      loadingLogs: false,
      logsLoadError: null
    };
  }

  componentDidMount() {
    this.fetchLogs();
  }

  async fetchLogs() {
    const { cache, resultSetID } = this.props;
    const resultSet = cache.resultSets[resultSetID];

    if (!resultSet) {
      throw Error("result set is undefined");
    }

    const logsPath =
      resultSet.logs.find(p => p.endsWith("/run.sh.out")) ||
      resultSet.logs.find(p => p.endsWith("/build.sh.out"));

    if (!logsPath) {
      return;
    }

    this.setState({ loadingLogs: true, logsLoadError: null });

    try {
      const logs = await BlobsAPI.downloadBlob(logsPath);
      this.setState({ loadingLogs: false, logs });
    } catch (e) {
      this.setState({
        loadingLogs: false,
        logsLoadError: "Failed to load logs"
      });
    }
  }

  render() {
    const { cache, resultSetID } = this.props;
    const resultSet = cache.resultSets[resultSetID];

    if (!resultSet) {
      throw Error("result set is undefined");
    }

    return <ResultSetLogs logFiles={resultSet.logs} {...this.state} />;
  }
}

export default withAppDataCacheCtx(ResultSetContainer);
