// @flow
import * as React from "react";
import styles from "./BaseLayout.module.css";

type Props = {||};

class NavHr extends React.Component<Props> {
  render() {
    return <hr className={styles.navHr} />;
  }
}

export default NavHr;
