// @flow
import * as React from "react";
import { range } from "lodash";

import styles from "./Progress.module.css";

type Props = {|
  total: number,
  completed: number
|};

export const Progress = (props: Props) => (
  <div className={styles.container}>
    {range(props.total).map(i => (
      <div
        key={i}
        className={`
          ${styles.progress}
          ${i < props.completed ? styles.completed : ""}
        `}
      />
    ))}
  </div>
);

export default Progress;
