// @flow
import dayjs from "dayjs";

import type {
  Assignment,
  UpdateAssignmentParams
} from "../../lib/api/assignments";

const BEFORE_MIDNIGHT = dayjs()
  .set("hour", 23)
  .set("minutes", 59)
  .set("seconds", 59);

export function mapAssignment(assignment: Assignment): UpdateAssignmentParams {
  return {
    exerciseSetIDs: assignment.exercise_set_ids,
    name: assignment.name,
    availableAt: assignment.available_at,
    dueAt: assignment.due_at,
    cutoffAt: assignment.cutoff_at,
    sessionTimeLimit: assignment.session_time_limit
  };
}

export function newAssignmentParams(): UpdateAssignmentParams {
  return {
    name: "",
    availableAt: Math.round(new Date().getTime() / 1000),
    dueAt: BEFORE_MIDNIGHT.add(1, "week").unix(),
    cutoffAt: BEFORE_MIDNIGHT.add(2, "weeks").unix(),
    exerciseSetIDs: [],
    sessionTimeLimit: null
  };
}
