// @flow
import * as React from "react";
import { FaDownload, FaExternalLinkAlt } from "react-icons/fa";
import Button from "../../modules/style-guide/button";

import * as QueueAPI from "../../lib/api/queue";
import * as AttemptsAPI from "../../lib/api/attempts";
import type { Task } from "../../lib/api/queue";
import ExternalLink from "../../modules/style-guide/external-link";

type Props = {
  assignmentID: string
};

const DownloadSubmissionsButton = (props: Props) => {
  const [task, setTask] = React.useState<?Task>(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const onDownload = async () => {
    setTask(null);
    setLoading(true);
    setError(false);

    try {
      const newTask = await AttemptsAPI.downloadSubmissions(props.assignmentID);

      setTask(newTask);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(
    () => {
      async function updateTask() {
        if (!task) {
          return;
        }

        try {
          const updatedTask = await QueueAPI.fetchTask(task.uuid);

          setTask(updatedTask);
        } catch (e) {
          console.log("Failed to update task..", e);
        }
      }

      if (task && (task.status === "QUEUED" || task.status === "PROCESSING")) {
        setTimeout(() => updateTask(), 3000);
      }

      if (task && task.status === "FAILED") {
        setError(true);
      }
    },
    [task]
  );

  const isDownloading =
    loading ||
    (task && (task.status === "QUEUED" || task.status === "PROCESSING"));

  if (error) {
    return (
      <Button small secondary danger onClick={onDownload}>
        <FaDownload />
        Try Again
      </Button>
    );
  }

  if (isDownloading) {
    return (
      <Button small secondary disabled>
        <FaDownload />
        Creating Link..
      </Button>
    );
  }

  if (task) {
    return (
      <Button
        small
        primary
        el={ExternalLink}
        href={`/api/blobs/${task.result}`}
        onClick={() => setTask(null)}
      >
        <FaExternalLinkAlt />
        Download
      </Button>
    );
  }

  return (
    <Button small secondary onClick={onDownload}>
      <FaDownload />
      Submissions
    </Button>
  );
};

export default DownloadSubmissionsButton;
