// @flow
import * as React from "react";
import Button from "../button";
import Popover from "../popover";
import { H3, RunningText } from "../text";
import { SpacerSm } from "../spacer";
import styles from "./ConfirmAction.module.css";

type Props = {|
  title?: string,
  message?: string,
  button: string,
  confirmingButton: string,
  placement?: "LEFT" | "RIGHT",
  wide?: boolean,
  children: React.Node,
  onConfirm: () => Promise<any>
|};

type State = {|
  confirming: boolean,
  confirmError: ?string
|};

class ConfirmAction extends React.Component<Props, State> {
  popover: ?Popover;

  static defaultProps = {
    message: "Are you sure?",
    button: "Confirm",
    confirmingButton: "Confirming"
  };

  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      confirming: false,
      confirmError: null
    };
  }

  handleConfirm = async (event: SyntheticMouseEvent<HTMLButtonElement>) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }

    if (this.state.confirming) {
      return;
    }

    try {
      this.setState({ confirming: true, confirmError: null });
      await this.props.onConfirm();
      this.popover && this.popover.hide();
    } catch (e) {
      this.setState({ confirming: false, confirmError: "Failed to confirm" });
    }
  };

  render() {
    const {
      title,
      message,
      button,
      confirmingButton,
      placement,
      wide,
      children
    } = this.props;
    const { confirming, confirmError } = this.state;

    return (
      <Popover
        ref={r => (this.popover = r)}
        placement={placement}
        popoverEl={
          <div className={`${styles.popover} ${wide ? styles.wide : ""}`}>
            <H3>{title || null}</H3>
            <SpacerSm />
            <RunningText>{message || null}</RunningText>
            <SpacerSm />
            <hr />
            <Button danger small wide onClick={this.handleConfirm}>
              {confirming ? confirmingButton : button}
            </Button>
            <SpacerSm />
            <span className={styles.error}>{confirmError}</span>
          </div>
        }
      >
        {children}
      </Popover>
    );
  }
}

export default ConfirmAction;
