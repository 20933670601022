// @flow
import * as React from "react";
import Button from "../../modules/style-guide/button";
import styles from "./ViewToggles.module.css";
import {
  BottomSidebarIcon,
  RightSidebarIcon
} from "../../modules/style-guide/icons";
import { SpacerXsm } from "../../modules/style-guide/spacer";

type Props = {
  right: boolean,
  bottom: boolean,

  onToggleRight: () => void,
  onToggleBottom: () => void
};

const ViewToggles = (props: Props) => (
  <div>
    <Button
      link
      className={props.bottom ? "" : styles.inactive}
      onClick={props.onToggleBottom}
    >
      <BottomSidebarIcon />
    </Button>

    <SpacerXsm horizontal />

    <Button
      link
      className={props.right ? "" : styles.inactive}
      onClick={props.onToggleRight}
    >
      <RightSidebarIcon />
    </Button>
  </div>
);

export default ViewToggles;
