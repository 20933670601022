// @flow
import type { Stack } from "./stack";

export const databaseStacks: Stack[] = [
  {
    id: "sql_server",
    name: "SQL Server",
    unavailable: true,
    timeout: 0,
    workspace_id: "",
    tags: []
  },
  {
    id: "psql",
    name: "PostgreSQL",
    unavailable: true,
    timeout: 0,
    workspace_id: "",
    tags: []
  }
];

export const webAppStacks: Stack[] = [
  {
    id: "web",
    name: "HTML / CSS / JS",
    unavailable: true,
    timeout: 0,
    workspace_id: "",
    tags: []
  }
];

export const commandLineStacks: Stack[] = [
  {
    id: "javascript",
    name: "Javascript",
    unavailable: true,
    timeout: 0,
    workspace_id: "",
    tags: []
  },
  {
    id: "rust",
    name: "Rust",
    unavailable: true,
    timeout: 0,
    workspace_id: "",
    tags: []
  }
];
