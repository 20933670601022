// @flow
import * as React from "react";
import { FaFileCode, FaFolder } from "react-icons/fa";
import TreeContainer from "./index";
import styles from "./Node.module.css";
import { DeleteIcon } from "../icons";
import ConfirmAction from "../confirm-action";

type Props = {|
  readOnly?: boolean,
  label: string,
  children: React.Element<typeof TreeContainer> | null,
  onClick: (file: string) => void,
  onDelete: (file: string) => Promise<void>
|};

const getIcon = (style: "FOLDER" | "FILE") => {
  switch (style) {
    case "FOLDER":
      return <FaFolder className={styles.labelIcon} />;
    case "FILE":
    default:
      return <FaFileCode className={styles.labelIcon} />;
  }
};

class Node extends React.Component<Props> {
  handleSelect = () =>
    this.props.children ? undefined : this.props.onClick(this.props.label);

  handleDelete = () => this.props.onDelete(this.props.label);

  render() {
    return (
      <>
        <div
          className={`${styles.label} ${
            this.props.children ? "" : styles.file
          }`}
          onClick={this.handleSelect}
        >
          <div>
            {getIcon(this.props.children ? "FOLDER" : "FILE")}
            {this.props.label}
          </div>
          {this.props.readOnly ? null : (
            <ConfirmAction
              button="Delete"
              confirmingButton="Deleting.."
              placement="RIGHT"
              onConfirm={this.handleDelete}
            >
              <DeleteIcon className={styles.fileActionBtn} />
            </ConfirmAction>
          )}
        </div>
        {this.props.children && (
          <div className={styles.indentation}>{this.props.children}</div>
        )}
      </>
    );
  }
}

export default Node;
