// @flow
import * as React from "react";
import { difference, values } from "lodash";
import { FaCode, FaDatabase, FaGlobeAmericas } from "react-icons/fa";
import { withAppDataCacheCtx } from "../../../lib/data-cache";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs
} from "../../../modules/style-guide/tabs";
import Button from "../../../modules/style-guide/button";
import { Spacer } from "../../../modules/style-guide/spacer";
import StacksEditor from "./stacks-editor-component";
import styles from "../EditExercise.module.css";

import * as ExercisesAPI from "../../../lib/api/exercises";
import * as UnavailableStacks from "./unavailable-stacks";

import type { AppDataCache } from "../../../lib/data-cache";
import type { Stack } from "./stack";

type Props = {|
  cache: AppDataCache,
  exerciseID: string,
  next: () => any
|};

type State = {|
  selectedStackIDs: string[],
  saving: boolean,
  saveError: ?string
|};

class StacksEditorContainer extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      selectedStackIDs: this.props.cache.stackIDsForExercise[
        this.props.exerciseID
      ],
      saving: false,
      saveError: null
    };
  }

  handleSave = async () => {
    const { cache, exerciseID, next } = this.props;
    const { selectedStackIDs, saving } = this.state;

    if (saving) {
      return;
    }

    const existingStackIDs = cache.stackIDsForExercise[exerciseID];
    const stackIDsToAdd = selectedStackIDs;
    const stackIDsToRemove = difference(existingStackIDs, selectedStackIDs);

    this.setState({ saving: true, saveError: null });
    try {
      await Promise.all([
        ...stackIDsToAdd.map(id =>
          ExercisesAPI.addStackToExercise(exerciseID, id)
        ),
        ...stackIDsToRemove.map(id =>
          ExercisesAPI.removeStackFromExercise(exerciseID, id)
        )
      ]);

      this.setState({ saving: false });
      next();
    } catch (e) {
      this.setState({ saving: false, saveError: "Failed to save exercise" });
    }
  };

  handleSelectStackIDsChange = (tag: string) => (selectedStackIDs: string[]) =>
    this.setState(prevState => {
      const { cache } = this.props;
      const prevSelectedStacks = prevState.selectedStackIDs.map(
        id => cache.stacks[id]
      );
      const newSelectedStackIDs = prevSelectedStacks
        // filters out all the stacks with the same tag as this function
        .filter(s => !s.tags.some(t => t === tag))
        .map(s => s.id)
        .concat(selectedStackIDs);

      return { selectedStackIDs: newSelectedStackIDs };
    });

  handleCmdLineSelectedStackIDsChange = this.handleSelectStackIDsChange(
    "stacks.command_line"
  );

  handleWebAppSelectedStackIDsChange = this.handleSelectStackIDsChange(
    "stacks.web_app"
  );

  handleDBSelectedStackIDsChange = this.handleSelectStackIDsChange(
    "stacks.database"
  );

  render() {
    const { cache } = this.props;
    const { selectedStackIDs, saving, saveError } = this.state;
    const allStacks: Stack[] = values(cache.stacks);
    const commandLineStacks = allStacks
      .filter(s => s.tags.some(t => t === "stacks.command_line"))
      .concat(UnavailableStacks.commandLineStacks);
    const webAppStacks = allStacks
      .filter(s => s.tags.some(t => t === "stacks.web_app"))
      .concat(UnavailableStacks.webAppStacks);
    const databaseStacks = allStacks
      .filter(s => s.tags.some(t => t === "stacks.database"))
      .concat(UnavailableStacks.databaseStacks);

    return (
      <>
        <Tabs>
          <TabList>
            <Tab>
              <FaCode /> Command line
            </Tab>
            <Tab>
              <FaGlobeAmericas /> Web based apps
            </Tab>
            <Tab>
              <FaDatabase /> Database
            </Tab>
          </TabList>
          <TabPanel>
            <Spacer />
            <StacksEditor
              allStacks={commandLineStacks}
              selectedStackIDs={selectedStackIDs}
              onChange={this.handleCmdLineSelectedStackIDsChange}
            />
          </TabPanel>
          <TabPanel>
            <Spacer />
            <StacksEditor
              allStacks={webAppStacks}
              selectedStackIDs={selectedStackIDs}
              onChange={this.handleWebAppSelectedStackIDsChange}
            />
          </TabPanel>
          <TabPanel>
            <Spacer />
            <StacksEditor
              allStacks={databaseStacks}
              selectedStackIDs={selectedStackIDs}
              onChange={this.handleDBSelectedStackIDsChange}
            />
          </TabPanel>
        </Tabs>
        <Spacer />

        <div className={styles.actionBar}>
          <div>{saveError}</div>
          <Button small onClick={this.handleSave}>
            {saving ? "Saving.." : "Save & Next"}
          </Button>
        </div>
      </>
    );
  }
}

export default withAppDataCacheCtx(StacksEditorContainer);
