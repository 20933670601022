// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import { H3 } from "../style-guide/text";
import { SpacerLg, SpacerSm } from "../style-guide/spacer";
import RichHTML from "../style-guide/rich-html";
import Progress from "../style-guide/progress";
import ExercisePicker from "../exercise-picker";

import type { ExerciseSet } from "../../lib/api/exercise-sets";
import type { Exercise } from "../../lib/api/exercises";

type Props = {|
  exerciseSet: ExerciseSet,
  exercises: Exercise[],
  selectedExerciseID: string
|};

class ExerciseSetOverview extends React.Component<Props> {
  render() {
    const { exerciseSet, exercises, selectedExerciseID } = this.props;
    const exercise = exercises.find(e => e.id === selectedExerciseID);

    if (!exercise) {
      throw Error("Selected exercise is undefined");
    }

    return (
      <>
        <ExercisePicker
          exerciseSetID={exerciseSet.id}
          exerciseID={selectedExerciseID}
          renderLabel={e => <Link to={`${e.id}`}>{e.title}</Link>}
        />
        <SpacerSm />
        <Progress total={exercises.length} completed={exercise.order} />
        <SpacerLg />
        <H3>{exercise.title}</H3>
        <SpacerSm />
        <RichHTML>{exercise.description}</RichHTML>
        <SpacerSm />
      </>
    );
  }
}

export default ExerciseSetOverview;
