// @flow
import makeRequest from "../make-request";

import type { Stack } from "../stacks";

export type Exercise = {|
  id: string,
  order: number,
  title: string,
  description: string,
  exercise_set_id: string,
  test_case_set_id: string,
  stack_ids: string[]
|};

type updateExerciseParams = {|
  order?: number,
  title?: string,
  description?: string
|};

export async function fetchExercise(exerciseID: string): Promise<Exercise> {
  const url = `/api/exercises/${exerciseID}`;

  return makeRequest(url);
}

export async function fetchExerciseStacks(
  exerciseID: string
): Promise<Stack[]> {
  const url = `/api/exercises/${exerciseID}/stacks`;

  return makeRequest(url);
}

export async function addStackToExercise(
  exerciseID: string,
  stackID: string
): Promise<any> {
  const url = `/api/exercises/${exerciseID}/stacks/${stackID}`;

  return makeRequest(url, { method: "PUT" });
}

export async function removeStackFromExercise(
  exerciseID: string,
  stackID: string
): Promise<any> {
  const url = `/api/exercises/${exerciseID}/stacks/${stackID}`;

  return makeRequest(url, { method: "DELETE" });
}

export async function deleteExercise(exerciseID: string): Promise<{}> {
  const url = `/api/exercises/${exerciseID}`;

  return makeRequest(url, { method: "DELETE" });
}

export async function updateExercise(
  exerciseID: string,
  params: updateExerciseParams
): Promise<{}> {
  const url = `/api/exercises/${exerciseID}`;
  const data = JSON.stringify({
    order: params.order,
    title: params.title,
    description: params.description
  });

  return makeRequest(url, { method: "POST", body: data });
}

export async function fetchExercises(
  exerciseSetID: string
): Promise<Exercise[]> {
  const url = `/api/exercise-sets/${exerciseSetID}/exercises`;

  return makeRequest(url);
}
