// @flow
import * as React from "react";
import AppDataCacheCtx from "../../lib/data-cache";
import ExercisePicker from "./exercise-picker-component";

import type { Exercise } from "../../lib/api/exercises";

type Props = {|
  exerciseSetID: string,
  exerciseID: string,
  renderLabel?: (exercise: Exercise) => React.Node
|};

class ExercisePickerContainer extends React.Component<Props> {
  render() {
    return (
      <AppDataCacheCtx.Consumer>
        {({ cache }) => {
          const { exerciseSetID, exerciseID, renderLabel } = this.props;

          const allExercises: Exercise[] = (Object.values(
            cache.exercises
          ): any);
          const exercises = allExercises.filter(
            e => e.exercise_set_id === exerciseSetID
          );

          return (
            <ExercisePicker
              exercises={exercises}
              selectedExerciseID={exerciseID}
              renderLabel={renderLabel}
            />
          );
        }}
      </AppDataCacheCtx.Consumer>
    );
  }
}

export default ExercisePickerContainer;
