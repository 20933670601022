// @flow
import * as React from "react";
import styles from "./Text.module.css";

type Props = {|
  children?: React.Node,
  className?: string,
  muted?: boolean
|};

const cn = (classes: (?string)[]): string =>
  classes
    .filter(Boolean)
    .join(" ")
    .trim();

export const H1 = ({ className, children, muted }: Props) => (
  <h1 className={cn([styles.h1, muted ? styles.muted : "", className])}>
    {children}
  </h1>
);

export const H3 = ({ className, children, muted }: Props) => (
  <h3 className={cn([styles.h3, muted ? styles.muted : "", className])}>
    {children}
  </h3>
);

export const H4 = ({ className, children, muted }: Props) => (
  <h4 className={cn([styles.h4, muted ? styles.muted : "", className])}>
    {children}
  </h4>
);

export const H5 = ({ className, children, muted }: Props) => (
  <h5 className={cn([styles.h5, muted ? styles.muted : "", className])}>
    {children}
  </h5>
);

export const RunningText = ({ className, children, muted }: Props) => (
  <p className={cn([styles.p, muted ? styles.muted : "", className])}>
    {children}
  </p>
);

export const Subtitle = ({ className, children }: Props) => (
  <span className={cn([styles.subtitle, className])}>{children}</span>
);

export const Success = ({ className, children }: Props) => (
  <span className={cn([styles.success, className])}>{children}</span>
);

export const Error = ({ className, children }: Props) => (
  <span className={cn([styles.error, className])}>{children}</span>
);
