// @flow
import * as React from "react";
import Input from "../../../modules/style-guide/input";
import Select from "../../../modules/style-guide/select";
import Button from "../../../modules/style-guide/button";
import styles from "./TestSuiteEditor.module.css";

import type { TestCaseTemplate } from "../../../lib/api/test-case-templates";
import type { SelectItem } from "../../../modules/style-guide/select";

type Props = {|
  templates: TestCaseTemplate[],

  onCreate: (name: string, templateID: string) => any,
  creating: boolean,
  createError: ?string
|};

type State = {|
  name: string,
  selectedTemplateID: string
|};

class CreateTestCase extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      name: "",
      selectedTemplateID: this.props.templates[0].id
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.creating && !this.props.creating && !this.props.createError) {
      this.setState({
        name: ""
      });
    }
  }

  handleSelectTemplate = (item: SelectItem) => {
    this.setState({
      selectedTemplateID: item.id
    });
  };

  handleNameChange = (name: string) => {
    this.setState({
      name
    });
  };

  handleCreate = () => {
    const { onCreate, creating } = this.props;

    if (creating) {
      return;
    }

    onCreate(this.state.name, this.state.selectedTemplateID);
  };

  render() {
    const { templates, creating, createError } = this.props;
    const { name, selectedTemplateID } = this.state;

    return (
      <div className={styles.newTestCase}>
        <Input
          label="Test case name"
          placeholder="TC 1"
          value={name}
          onEnter={this.handleCreate}
          onChange={this.handleNameChange}
        />
        <Select
          label="Grading type"
          items={templates.map(t => ({
            id: t.id,
            label: t.name
          }))}
          value={selectedTemplateID}
          onSelect={this.handleSelectTemplate}
        />
        <Button small secondary onClick={this.handleCreate}>
          {creating ? "Creating" : "Create"}
        </Button>
        <div>{createError}</div>
      </div>
    );
  }
}

export default CreateTestCase;
