// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import AppDataCacheCtx from "../../lib/data-cache";
import { Spacer, SpacerLg } from "../../modules/style-guide/spacer";
import { H3 } from "../../modules/style-guide/text";
import { DeleteIcon, ExternalLinkIcon } from "../../modules/style-guide/icons";
import Input from "../../modules/style-guide/input";
import List from "../../modules/style-guide/list";
import ExternalLink from "../../modules/style-guide/external-link";
import DatePicker from "../../modules/style-guide/date-picker";
import ActionBar from "../../modules/style-guide/action-bar";
import Button from "../../modules/style-guide/button";
import NullStateCard from "../../modules/style-guide/null-state-card";
import validateAssignment from "./validate-assignment";
import styles from "./Assignment.module.css";

import type { UpdateAssignmentParams } from "../../lib/api/assignments";
import Select from "../../modules/style-guide/select";

type Props = {|
  assignmentID: ?string,
  courseID: string,
  assignmentParams: UpdateAssignmentParams,

  onSave: (p: UpdateAssignmentParams) => Promise<any>,

  saveButtonText: string,
  savingButtonText: string
|};

type State = {|
  name: string,
  exerciseSetIDs: string[],
  dueOn: Date,
  cutoffOn: Date,
  availableOn: Date,
  sessionTimeLimit: string,

  validationError: ?string,

  saving: boolean,
  saveError: ?string
|};

class AssignmentEditor extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    const { assignmentParams } = this.props;

    this.state = {
      name: assignmentParams.name,
      exerciseSetIDs: assignmentParams.exerciseSetIDs,
      dueOn: new Date(assignmentParams.dueAt * 1000),
      cutoffOn: new Date(assignmentParams.cutoffAt * 1000),
      availableOn: new Date(assignmentParams.availableAt * 1000),
      sessionTimeLimit: (assignmentParams.sessionTimeLimit || 0).toString(),

      validationError: null,

      saving: false,
      saveError: null
    };
  }

  get assignmentParams(): UpdateAssignmentParams {
    return {
      name: this.state.name,
      exerciseSetIDs: this.state.exerciseSetIDs,
      dueAt: Math.round(this.state.dueOn.getTime() / 1000),
      cutoffAt: Math.round(this.state.cutoffOn.getTime() / 1000),
      availableAt: Math.round(this.state.availableOn.getTime() / 1000),
      sessionTimeLimit: parseInt(this.state.sessionTimeLimit, 10) || null
    };
  }

  get browseQuestionsLink(): string {
    const { assignmentID, courseID } = this.props;
    const { name } = this.state;

    return (
      "/questions?" +
      [
        "create_assignment=true",
        assignmentID ? `assignment_id=${assignmentID}` : "",
        `course_id=${courseID}`,
        `name=${name}`
      ].join("&")
    );
  }

  handleSave = async () => {
    const validationError = validateAssignment(this.assignmentParams);

    if (validationError) {
      this.setState({ validationError });
      return;
    }

    try {
      this.setState({ saving: true, saveError: null, validationError: null });
      await this.props.onSave(this.assignmentParams);
    } catch (e) {
      this.setState({ saving: false, saveError: "Failed to save assignment" });
    }
  };

  handleNameChange = (name: string) => this.setState({ name });

  handleAvailableOnChange = (availableOn: Date) =>
    this.setState({ availableOn });

  handleDueOnChange = (dueOn: Date) => this.setState({ dueOn });

  handleCutoffOnChange = (cutoffOn: Date) => this.setState({ cutoffOn });

  handleDeleteExerciseSet = (exerciseSetID: string) =>
    this.setState(s => ({
      exerciseSetIDs: s.exerciseSetIDs.filter(id => id !== exerciseSetID)
    }));

  render() {
    const { saveButtonText, savingButtonText } = this.props;
    const {
      name,
      exerciseSetIDs,
      dueOn,
      cutoffOn,
      availableOn,
      sessionTimeLimit,
      saving,
      saveError,
      validationError
    } = this.state;

    return (
      <AppDataCacheCtx.Consumer>
        {({ cache }) => {
          return (
            <>
              <Input
                label="Assignment Name"
                value={name}
                onChange={this.handleNameChange}
              />
              <SpacerLg />
              <div className={styles.actionTitle}>
                <H3>Questions</H3>
                <Button small secondary el={Link} to={this.browseQuestionsLink}>
                  Browse Questions
                </Button>
              </div>
              <Spacer />
              {exerciseSetIDs.length ? null : (
                <NullStateCard
                  compact
                  title="Add questions"
                  content="Browse through your library to add questions to this assignment"
                  cta={
                    <Button small el={Link} to={this.browseQuestionsLink}>
                      Browse Questions
                    </Button>
                  }
                />
              )}
              <List
                items={exerciseSetIDs.map(id => ({
                  id: id,
                  label: cache.exerciseSets[id].title,
                  actions: [
                    () => (
                      <ExternalLink href={`/questions/${id}`}>
                        <ExternalLinkIcon />
                      </ExternalLink>
                    ),
                    () => (
                      <DeleteIcon
                        onClick={() => this.handleDeleteExerciseSet(id)}
                      />
                    )
                  ]
                }))}
              />
              <SpacerLg />
              <H3>Policies</H3>
              <Spacer />
              <div style={{ display: "flex" }}>
                <DatePicker
                  label="Due on"
                  selected={dueOn}
                  onChange={this.handleDueOnChange}
                />
                <Spacer />
                <DatePicker
                  label="Cutoff on"
                  selected={cutoffOn}
                  onChange={this.handleCutoffOnChange}
                />
              </div>
              <Spacer />
              <div style={{ display: "flex" }}>
                <DatePicker
                  label="Available on"
                  selected={availableOn}
                  onChange={this.handleAvailableOnChange}
                />
                <Spacer />
                <Select
                  label="Time Limit"
                  items={[
                    { id: "0", label: "Unlimited" },
                    { id: "1800", label: "30 mins" },
                    { id: "3600", label: "1 hour" },
                    { id: "5400", label: "1.5 hours" },
                    { id: "7200", label: "2 hours" },
                    { id: "9000", label: "2.5 hours" },
                    { id: "10800", label: "3 hours" },
                    { id: "12600", label: "3.5 hours" },
                    { id: "14400", label: "4 hours" },
                    { id: "16200", label: "4.5 hours" },
                    { id: "18000", label: "5 hours" },
                    { id: "19800", label: "5.5 hours" },
                    { id: "21600", label: "6 hours" }
                  ]}
                  value={sessionTimeLimit}
                  onSelect={({ id }) => this.setState({ sessionTimeLimit: id })}
                />
              </div>

              <ActionBar error={validationError || saveError}>
                <Button small onClick={this.handleSave}>
                  {saving ? savingButtonText : saveButtonText}
                </Button>
              </ActionBar>
            </>
          );
        }}
      </AppDataCacheCtx.Consumer>
    );
  }
}

export default AssignmentEditor;
