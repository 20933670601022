// @flow
import * as React from "react";
import Panel from "./panel";

type Props = {|
  selected: ?number,
  children: React.ChildrenArray<React.Element<typeof Panel>>,

  onSelect: (index: number) => any
|};

class Accordion extends React.Component<Props> {
  render() {
    const { selected, children, onSelect } = this.props;

    return (
      <div>
        {React.Children.map(children, (el, index) =>
          React.cloneElement(el, {
            index,
            open: selected === index,
            onSelect: onSelect
          })
        )}
      </div>
    );
  }
}

export default Accordion;
