// @flow
import { trimStart } from "lodash";
import makeRequest from "../make-request";

export async function fetchFiles(workspaceID: string): Promise<string[]> {
  const url = `/api/workspaces/${workspaceID}/files`;

  return makeRequest(url);
}

export async function deleteFile(workspaceID: string, filePath: string) {
  const sanitizedFilePath = trimStart(filePath, "/");
  const url = `/api/workspaces/${workspaceID}/files/${sanitizedFilePath}`;

  return makeRequest(url, { method: "DELETE" });
}

export async function uploadFile(
  workspaceID: string,
  filePath: string,
  data: string | Blob
) {
  const sanitizedFilePath = trimStart(filePath, "/");
  const url = `/api/workspaces/${workspaceID}/files/${sanitizedFilePath}`;

  return makeRequest(url, { method: "POST", body: data });
}

export async function downloadFile(
  workspaceID: string,
  filePath: string
): Promise<Blob | string> {
  const sanitizedFilePath = trimStart(filePath, "/");
  const url = `/api/workspaces/${workspaceID}/files/${sanitizedFilePath}`;

  return makeRequest(url);
}
