// @flow
import * as React from "react";
import { H3, Subtitle } from "../text";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { SpacerSm } from "../spacer";
import styles from "./Accordion.module.css";

type Props = {|
  index: number,
  title: string | React.Node,
  subtitle?: string | React.Node,
  open?: boolean,
  compact?: boolean,
  startEnhancer?: React.Node,
  children: React.Node,
  renderSelectButton?: (
    open: boolean,
    onSelect: (index: number) => any
  ) => React.Node,
  className?: string,

  onSelect: (index: number) => any
|};

class Panel extends React.Component<Props> {
  static defaultProps = {
    index: 0,
    onSelect: () => {},
    children: null
  };

  renderSelectButton() {
    const { index, open, renderSelectButton, onSelect } = this.props;

    if (renderSelectButton) {
      return renderSelectButton(open || false, () => onSelect(index));
    }

    if (open) {
      return (
        <span className={styles.panelChevron} onClick={() => onSelect(index)}>
          <FaChevronUp />
        </span>
      );
    }

    return (
      <span className={styles.panelChevron} onClick={() => onSelect(index)}>
        <FaChevronDown />
      </span>
    );
  }

  render() {
    const {
      title,
      subtitle,
      open,
      compact,
      startEnhancer,
      children,
      className
    } = this.props;

    return (
      <div
        className={[
          styles.panel,
          compact ? styles.compact : "",
          className || ""
        ].join(" ")}
      >
        <div className={styles.panelOverview}>
          {startEnhancer}
          <div style={{ flex: 1 }}>
            {typeof title === "string" ? <H3>{title}</H3> : title}
            <SpacerSm />
            <Subtitle>{subtitle}</Subtitle>
          </div>
          <div>{this.renderSelectButton()}</div>
        </div>
        {open && children && (
          <div className={styles.panelContent}>{children}</div>
        )}
      </div>
    );
  }
}

export default Panel;
