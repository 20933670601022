// @flow
import makeRequest from "../make-request";

export async function sendSupportRequest(problem: string): Promise<any> {
  const url = `/api/support/requests`;
  const data = JSON.stringify({
    problem
  });

  return makeRequest(url, { method: "POST", body: data });
}
