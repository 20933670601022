// @flow
import * as React from "react";
import { FaQrcode, FaUsers } from "react-icons/fa";
import { capitalize, keyBy } from "lodash";
import AppDataCacheCtx from "../../lib/data-cache";
import Table from "../../modules/style-guide/table";
import { H3 } from "../../modules/style-guide/text";
import { Spacer, SpacerLg, SpacerSm } from "../../modules/style-guide/spacer";
import HelpSection from "../../modules/style-guide/help-section";
import BaseDataPage from "../base-data";
import BaseLayoutPage, {
  renderSkeleton,
  PageAsideContent,
  PageContent
} from "../base-layout";
import Enroller from "./enroller";

import * as CoursesAPI from "../../lib/api/courses";
import CourseStatusNotification from "../../modules/course-status-notification";

type Props = {||};

class CourseRosterPage extends React.Component<Props> {
  fetchData = async () => {
    // $FlowFixMe: add defs for react-router
    const { courseID } = this.props.match.params;

    const [rosterProfiles, instructorProfiles] = await Promise.all([
      CoursesAPI.fetchCourseRoster(courseID),
      CoursesAPI.fetchCourseInstructors(courseID)
    ]);

    return {
      courses: {
        [courseID]: {
          instructor_profile_ids: instructorProfiles.map(p => p.id),
          student_profile_ids: rosterProfiles.map(p => p.id)
        }
      },
      profiles: keyBy(rosterProfiles.concat(instructorProfiles), p => p.id)
    };
  };

  render() {
    // $FlowFixMe: add defs for react-router
    const { courseID } = this.props.match.params;

    return (
      <BaseDataPage fetchData={this.fetchData} renderSkeleton={renderSkeleton}>
        <AppDataCacheCtx.Consumer>
          {({ cache }) => {
            if (!cache.session) {
              throw new Error("session is undefined");
            }

            const course = cache.courses[courseID];

            if (!course.student_profile_ids || !course.instructor_profile_ids) {
              throw new Error("student_profile_ids is undefined");
            }

            const profiles = course.instructor_profile_ids
              .concat(course.student_profile_ids)
              .map(id => cache.profiles[id]);

            return (
              <BaseLayoutPage>
                <PageContent title={course.name}>
                  <CourseStatusNotification courseID={courseID} />
                  <Enroller courseID={course.id} />
                  <Spacer />
                  <H3>Roster</H3>
                  <SpacerSm />
                  <Table
                    columns={[
                      { accessor: "name", label: "Name", left: true },
                      { accessor: "email", label: "Email", left: true },
                      { accessor: "role", label: "Role", left: true }
                    ]}
                    rows={profiles.map(r => ({
                      key: r.id,
                      data: {
                        name: r.name,
                        email: r.email,
                        role: capitalize(r.role)
                      }
                    }))}
                  />
                </PageContent>
                <PageAsideContent>
                  <HelpSection icon={FaQrcode} title="Invite Code">
                    Share the course invite code with your students to let them
                    enroll in this course. They will be prompted for the code
                    when they sign up.
                  </HelpSection>
                  <SpacerLg />
                  <HelpSection icon={FaUsers} title="Multiple Instructors">
                    If you would like multiple instructors to have access to
                    this course, have them sign up on AutoGradr as an
                    instructor. Then, use their registered email to add them to
                    the course.
                  </HelpSection>
                </PageAsideContent>
              </BaseLayoutPage>
            );
          }}
        </AppDataCacheCtx.Consumer>
      </BaseDataPage>
    );
  }
}

export default CourseRosterPage;
