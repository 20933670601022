// @flow
import * as React from "react";
import { groupBy, head, map, mapValues, tail, trimStart } from "lodash";
import Node from "./node";

type Props = {|
  readOnly?: boolean,
  paths: string[],
  root?: string,
  onClick: (path: string) => void,
  onDelete: (path: string) => Promise<void>
|};

class TreeContainer extends React.Component<Props> {
  static defaultProps = {
    root: ""
  };

  getNodes() {
    const paths = this.props.paths.map(p => trimStart(p, "/"));
    const groupByRoot = groupBy(paths, p => head(p.split("/")));

    return mapValues(groupByRoot, paths =>
      map(paths, p => tail(p.split("/")).join("/"))
    );
  }

  render() {
    return (
      <>
        {map(this.getNodes(), (subTree, label) => (
          <Node
            readOnly={this.props.readOnly}
            key={label}
            label={label}
            onClick={path =>
              this.props.onClick([this.props.root, path].join("/"))
            }
            onDelete={path =>
              this.props.onDelete([this.props.root, path].join("/"))
            }
          >
            {subTree.length && subTree[0] ? (
              <TreeContainer
                readOnly={this.props.readOnly}
                root={label}
                paths={subTree}
                onClick={path =>
                  this.props.onClick([this.props.root, path].join("/"))
                }
                onDelete={path =>
                  this.props.onDelete([this.props.root, path].join("/"))
                }
              />
            ) : null}
          </Node>
        ))}
      </>
    );
  }
}

export default TreeContainer;
