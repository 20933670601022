// @flow
import * as React from "react";
import styles from "./Popover.module.css";

type Props = {|
  children: React.Node,
  popoverEl?: React.Node,
  placement: "LEFT" | "RIGHT",
  verticalPlacement: "BOTTOM" | "TOP",
  disabled?: boolean
|};

type State = {|
  hidden: boolean
|};

class Popover extends React.Component<Props, State> {
  static defaultProps = {
    placement: "LEFT",
    verticalPlacement: "BOTTOM"
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      hidden: true
    };
  }

  toggle() {
    if (this.props.disabled) {
      return;
    }

    this.setState(s => ({
      hidden: !s.hidden
    }));
  }

  hide() {
    this.setState({ hidden: true });
  }

  render() {
    const { placement, verticalPlacement } = this.props;
    let popoverStyle = {};

    if (placement === "LEFT") {
      popoverStyle = { left: 0 };
    } else if (placement === "RIGHT") {
      popoverStyle = { right: 0 };
    }

    if (verticalPlacement === "BOTTOM") {
      popoverStyle = { ...popoverStyle, top: "100%" };
    } else if (verticalPlacement === "TOP") {
      popoverStyle = { ...popoverStyle, bottom: "120%" };
    }

    return (
      <>
        {this.state.hidden ? null : (
          <div
            className={styles.layover}
            onClick={e => {
              e.stopPropagation();
              this.hide();
            }}
          />
        )}
        <div className={styles.container}>
          <span
            className={styles.button}
            onClick={e => {
              e.stopPropagation();
              this.toggle();
            }}
          >
            {this.props.children}
          </span>
          {this.state.hidden ? null : (
            <span className={styles.popover} style={popoverStyle}>
              {this.props.popoverEl}
            </span>
          )}
        </div>
      </>
    );
  }
}

export default Popover;
