// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import AnonymousLayout1, {
  PageContent,
  PageFooter
} from "../anonymous-layout-1";
import BaseDataPage from "../base-data";
import SignupSurveyForm from "./signup-survey-form";
import styles from "../signup/Signup.module.css";

import * as AuthAPI from "../../lib/api/auth";
import * as SupportAPI from "../../lib/api/support";

type Props = {||};

type State = {|
  sending: boolean,
  sendError: ?string
|};

class SignupSurveyPage extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      sending: false,
      sendError: null
    };
  }

  fetchData = async () => {};

  handleLogout = async () => {
    // $FlowFixMe: add defs for react-router
    const { history } = this.props;

    try {
      await AuthAPI.logout();
    } catch (e) {
    } finally {
      history.push("/login");
    }
  };

  handleSend = async (text: string) => {
    // $FlowFixMe: add defs for react-router
    const { history } = this.props;

    try {
      this.setState({ sending: true, sendError: null });

      if (text) {
        await SupportAPI.sendSupportRequest("Signup Survey: " + text);
      }

      history.push("/getting-started");
    } catch (e) {
      this.setState({
        sending: false,
        sendError: "Failed to send message"
      });
    }
  };

  render() {
    return (
      <BaseDataPage
        allowUnverified
        fetchData={this.fetchData}
        renderSkeleton={AnonymousLayout1.renderSkeleton}
      >
        <AnonymousLayout1
          title="Welcome to AutoGradr"
          subtitle="Tell us a little about yourself"
        >
          <PageContent>
            <SignupSurveyForm {...this.state} onSend={this.handleSend} />
          </PageContent>

          <PageFooter>
            <div className={styles.center}>
              <Link to="#" onClick={this.handleLogout}>
                Logout
              </Link>
            </div>
          </PageFooter>
        </AnonymousLayout1>
      </BaseDataPage>
    );
  }
}

export default SignupSurveyPage;
