// @flow
import * as React from "react";
import styles from "./ActionBar.module.css";

type Props = {|
  error: ?string,
  children?: React.Node
|};

class ActionBar extends React.Component<Props> {
  render() {
    const { error, children } = this.props;

    return (
      <div className={styles.actionBar}>
        <div className={styles.error}>{error}</div>
        <div>{children}</div>
      </div>
    );
  }
}

export default ActionBar;
