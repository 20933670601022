// @flow
import * as React from "react";
import { values } from "lodash";
import AppDataCacheCtx from "../../lib/data-cache";
import IDEButton from "./ide-button-component";

import * as IDEsAPI from "../../lib/api/ides";

import type { updateAppDataCacheFunc } from "../../lib/data-cache";
import type { IDE } from "../../lib/api/ides";

type Props = {|
  stackID: ?string,
  assignmentID: ?string,
  exerciseID: string
|};

type State = {|
  creatingIDE: boolean,
  createIDEError: ?string
|};

class IDEButtonContainer extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      creatingIDE: false,
      createIDEError: null
    };
  }

  handleCreateIDE = async (
    testCaseSetID: string,
    updateCtxData: updateAppDataCacheFunc
  ): Promise<?IDE> => {
    const { stackID, assignmentID } = this.props;

    if (!stackID) {
      return;
    }

    this.setState({ creatingIDE: true, createIDEError: null });

    try {
      const ide = await IDEsAPI.createIDE(stackID, testCaseSetID, assignmentID);

      updateCtxData({
        ides: {
          [ide.id]: ide
        }
      });

      this.setState({ creatingIDE: false });

      return ide;
    } catch (e) {
      this.setState({
        creatingIDE: false,
        createIDEError: "Failed to create IDE"
      });
    }
  };

  render() {
    const { exerciseID, stackID, assignmentID } = this.props;

    return (
      <AppDataCacheCtx.Consumer>
        {({ cache, updateCache }) => {
          const { session, exercises, ides } = cache;
          const exercise = exercises[exerciseID];
          const allIDEs: IDE[] = values(ides);

          if (!session) {
            throw new Error("session is undefined");
          }

          const ide = allIDEs.find(
            ide =>
              ide.test_case_set_id === exercise.test_case_set_id &&
              ide.stack_id === stackID &&
              ide.user_uuid === session.profileID &&
              (assignmentID
                ? ide.assignment_id === assignmentID
                : !ide.assignment_id)
          );

          return (
            <IDEButton
              ideID={ide ? ide.id : null}
              disabled={!stackID || this.state.creatingIDE}
              onCreateIDE={() =>
                this.handleCreateIDE(exercise.test_case_set_id, updateCache)
              }
            />
          );
        }}
      </AppDataCacheCtx.Consumer>
    );
  }
}

export default IDEButtonContainer;
