// @flow
import * as React from "react";

type Props = {|
  href: string,
  children: React.Node,
  className?: string
|};

class ExternalLink extends React.Component<Props> {
  static defaultProps = {
    className: ""
  };

  render() {
    const { href, className, children } = this.props;

    return (
      <a
        rel="noopener noreferrer"
        target="_blank"
        href={href}
        className={className}
      >
        {children}
      </a>
    );
  }
}

export default ExternalLink;
