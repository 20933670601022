// @flow
import * as React from "react";
import "react-tabs/style/react-tabs.css";
import { Tabs as RTTabs, Tab as RTTab, TabList as RTTabList } from "react-tabs";
import styles from "./Tabs.module.css";

export { TabPanel } from "react-tabs";

export const Tabs = (props: any) => (
  <RTTabs selectedTabClassName={styles.selectedTab} {...props} />
);

export const TabList = (props: any) => (
  <RTTabList className={styles.tabList} {...props} />
);
TabList.tabsRole = "TabList";

export const Tab = (props: any) => (
  <RTTab className={["react-tabs__tab", styles.tab]} {...props} />
);
Tab.tabsRole = "Tab";
