//@flow

import type { UpdateAssignmentParams } from "../../lib/api/assignments";

export default function validateAssignment(p: UpdateAssignmentParams): ?string {
  const { name, availableAt, exerciseSetIDs, dueAt, cutoffAt } = p;

  if (!name) {
    return "Assignment name is required";
  }

  if (!exerciseSetIDs.length) {
    return "At least one question is required";
  }

  if (dueAt < availableAt) {
    return "Assignment cannot be due before it's available";
  }

  if (cutoffAt < dueAt) {
    return "Assignment's cutoff date cannot be before its due date";
  }

  return null;
}
