// @flow
import { flatten } from "lodash";
import makeRequest from "../make-request";

import type { Exercise } from "../exercises";

export type ExerciseSet = {|
  id: string,
  title: string,
  created_by: string,
  tags: string[]
|};

export async function createExercise(
  exerciseSetID: string,
  title: string
): Promise<Exercise> {
  const url = `/api/exercise-sets/${exerciseSetID}/exercises`;
  const data = JSON.stringify({
    title
  });

  return makeRequest(url, { method: "POST", body: data });
}

export async function createExerciseSet(title: string): Promise<ExerciseSet> {
  const url = `/api/exercise-sets`;
  const data = JSON.stringify({
    title
  });

  return makeRequest(url, { method: "POST", body: data });
}

export async function deleteExerciseSet(exerciseSetID: string): Promise<any> {
  const url = `/api/exercise-sets/${exerciseSetID}`;

  return makeRequest(url, { method: "DELETE" });
}

export async function fetchMyExerciseSetsWithExercises(): Promise<
  [ExerciseSet[], Exercise[]]
> {
  const url = `/api/exercise-sets?created=true&include=exercises`;
  let exerciseSets = await makeRequest(url);

  const exercises = flatten(exerciseSets.map(es => es.exercises));

  exerciseSets = exerciseSets.map(({ exercises, ...es }) => es);

  return [exerciseSets, exercises];
}

export async function fetchTaggedExerciseSets(
  tag: string
): Promise<ExerciseSet[]> {
  const url = `/api/exercise-sets?tag=${tag}`;

  return makeRequest(url);
}

export async function fetchExerciseSet(
  exerciseSetID: string
): Promise<ExerciseSet> {
  const url = `/api/exercise-sets/${exerciseSetID}`;

  return makeRequest(url);
}

export async function updateExerciseSet(
  exerciseSetID: string,
  title: ?string
): Promise<{}> {
  const url = `/api/exercise-sets/${exerciseSetID}`;
  const data = JSON.stringify({
    title
  });

  return makeRequest(url, { method: "POST", body: data });
}
