// @flow
import * as React from "react";
import { values } from "lodash";
import { withAppDataCacheCtx } from "../../../lib/data-cache";
import CreateTestCase from "./create-test-case-component";
import TestSuiteEditor from "./test-suite-component";
import Button from "../../../modules/style-guide/button";
import { Spacer } from "../../../modules/style-guide/spacer";
import styles from "./TestSuiteEditor.module.css";

import * as TestCasesAPI from "../../../lib/api/test-cases";

import type { TestCase } from "../../../lib/api/test-cases";
import type {
  AppDataCache,
  updateAppDataCacheFunc
} from "../../../lib/data-cache";
import { withRouter } from "react-router-dom";

type Props = {|
  testCaseSetID: string,
  cache: AppDataCache,
  updateCache: updateAppDataCacheFunc,
  next: () => any
|};

type State = {|
  deletingByTestCaseID: {
    [string]: boolean
  },
  deleteErrorByTestCaseID: {
    [string]: ?string
  },

  creatingTestCase: boolean,
  createTestCaseError: ?string
|};

class TestSuiteEditorContainer extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      deletingByTestCaseID: {},
      deleteErrorByTestCaseID: {},
      creatingTestCase: false,
      createTestCaseError: null
    };
  }

  handleDelete = async (testCaseID: string) => {
    const { updateCache } = this.props;

    this.setState(s => ({
      deletingByTestCaseID: { ...s.deletingByTestCaseID, [testCaseID]: true },
      deleteErrorByTestCaseID: {
        ...s.deleteErrorByTestCaseID,
        [testCaseID]: null
      }
    }));

    try {
      await TestCasesAPI.deleteTestCase(testCaseID);

      this.setState(s => ({
        deletingByTestCaseID: { ...s.deletingByTestCaseID, [testCaseID]: false }
      }));

      updateCache({
        testCases: {
          [testCaseID]: {
            _deleted: true
          }
        }
      });
    } catch (e) {
      this.setState(s => ({
        deletingByTestCaseID: {
          ...s.deletingByTestCaseID,
          [testCaseID]: false
        },
        deleteErrorByTestCaseID: {
          ...s.deleteErrorByTestCaseID,
          [testCaseID]: "Failed to delete test case"
        }
      }));
    }
  };

  handleCreate = async (name: string, templateID: string) => {
    // $FlowFixMe: add defs for react-router
    const { history } = this.props;
    const { testCaseSetID } = this.props;

    this.setState({
      creatingTestCase: true,
      createTestCaseError: null
    });

    try {
      const testCase = await TestCasesAPI.create(
        name,
        testCaseSetID,
        templateID
      );

      history.push(`${testCase.id}/edit`);
    } catch (e) {
      this.setState({
        creatingTestCase: false,
        createTestCaseError: "Failed to create test case.."
      });
    }
  };

  handleNext = () => {
    this.props.next();
  };

  render() {
    const { cache, testCaseSetID } = this.props;
    const {
      creatingTestCase,
      createTestCaseError,
      deletingByTestCaseID,
      deleteErrorByTestCaseID
    } = this.state;
    const templates = values(cache.testCaseTemplates);
    const allTestCases: TestCase[] = values(cache.testCases);
    const testCases = allTestCases.filter(
      tc => tc.test_case_set_id === testCaseSetID
    );

    return (
      <div>
        <CreateTestCase
          templates={templates}
          onCreate={this.handleCreate}
          creating={creatingTestCase}
          createError={createTestCaseError}
        />
        <Spacer />
        <TestSuiteEditor
          testCases={testCases.map(tc => ({
            ...tc,
            deleting: deletingByTestCaseID[tc.id],
            deleteError: deleteErrorByTestCaseID[tc.id]
          }))}
          onDelete={this.handleDelete}
        />
        <Spacer />
        <div className={styles.actionBar}>
          <Button small onClick={this.handleNext}>
            Next
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(withAppDataCacheCtx(TestSuiteEditorContainer));
