// @flow
import * as React from "react";
import styles from "./FileBrowser.module.css";
import { startsWith } from "lodash";

type Props = {|
  onNewFile: (path: string) => void
|};

type State = {|
  path: string
|};

class NewFile extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      path: "/"
    };
  }

  updatePath(path: string) {
    this.setState({
      path: startsWith(path, "/") ? path : `/${path}`
    });
  }

  render() {
    return (
      <div className={styles.newFileContainer}>
        <input
          type="text"
          placeholder="/path/to/file"
          autoFocus
          value={this.state.path}
          onKeyPress={e =>
            e.key === "Enter"
              ? this.props.onNewFile(this.state.path)
              : undefined
          }
          onChange={e => this.updatePath(e.target.value)}
        />
      </div>
    );
  }
}

export default NewFile;
