// @flow
import * as React from "react";
import Input from "../../modules/style-guide/input";
import Select from "../../modules/style-guide/select";
import Button from "../../modules/style-guide/button";
import { Spacer } from "../../modules/style-guide/spacer";
import styles from "./EditTestCase.module.css";

import type { SelectItem } from "../../modules/style-guide/select";

type Props = {|
  name: string,
  visible: boolean,

  onSave: (name: string, visible: boolean) => any,
  saving: boolean,
  saveError: ?string
|};

type State = {|
  name: string,
  visible: boolean
|};

class MetadataEditor extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      name: this.props.name,
      visible: this.props.visible
    };
  }

  handleNameChange = (name: string) => {
    this.setState({
      name
    });
  };

  handleVisibilitySelect = (item: SelectItem) => {
    this.setState({
      visible: item.id === "true"
    });
  };

  handleSave = () => {
    const { saving, onSave } = this.props;
    const { name, visible } = this.state;

    if (saving) {
      return;
    }

    onSave(name, visible);
  };

  render() {
    const { name, visible } = this.state;
    const { saving, saveError } = this.props;

    return (
      <div className={styles.metadataEditor}>
        <div className={styles.metadataEditorForm}>
          <Input label="Name" value={name} onChange={this.handleNameChange} />
          <Select
            label="Visibility"
            items={[
              { id: "true", label: "Visible to students" },
              { id: "false", label: "Hidden from students" }
            ]}
            value={visible ? "true" : "false"}
            onSelect={this.handleVisibilitySelect}
          />
        </div>
        <Spacer />
        <div className={styles.metadataActionBar}>
          <div>{saveError}</div>
          <Button small secondary onClick={this.handleSave}>
            {saving ? "Saving.." : "Save"}
          </Button>
        </div>
      </div>
    );
  }
}

export default MetadataEditor;
