// @flow
import * as React from "react";
import styles from "./Spacer.module.css";

type Props = {|
  horizontal?: boolean
|};

function getClassName(base: string, props: Props): string {
  if (props.horizontal) {
    return `${base} ${styles.horizontal}`;
  }

  return base;
}

export const SpacerXsm = (props: Props) => (
  <div className={getClassName(styles.spacerXsm, props)} />
);

export const SpacerSm = (props: Props) => (
  <div className={getClassName(styles.spacerSm, props)} />
);

export const Spacer = (props: Props) => (
  <div className={getClassName(styles.spacerMd, props)} />
);

export const SpacerLg = (props: Props) => (
  <div className={getClassName(styles.spacerLg, props)} />
);

export const SpacerXLg = (props: Props) => (
  <div className={getClassName(styles.spacerXLg, props)} />
);
