// @flow
import * as React from "react";
import { uniqueId } from "lodash";
import ReactDatePicker from "react-datepicker";
import Label from "../label";
import Input from "../input";

import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "./DatePicker.css";

type Props = {
  label?: string,
  hideTimeSelect?: ?boolean
};

class DatePicker extends React.Component<Props> {
  id: string;

  componentWillMount() {
    this.id = uniqueId("frm-date-picker-");
  }

  render() {
    const { label, hideTimeSelect, ...inputProps } = this.props;

    return (
      <div>
        {label && <Label htmlFor={this.id}>{label}</Label>}
        <div>
          <ReactDatePicker
            {...inputProps}
            customInput={<Input compact={hideTimeSelect} />}
            dateFormat={hideTimeSelect ? "MM/dd/yyyy" : "MM/dd/yyyy h:mm aa"}
            showTimeSelect={!hideTimeSelect}
            injectTimes={[new Date(1970, 0, 1, 23, 59, 59)]}
            popperModifiers={{
              preventOverflow: {
                enabled: true,
                escapeWithReference: false
              }
            }}
          />
        </div>
      </div>
    );
  }
}

export default DatePicker;
