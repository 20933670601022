// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import { keyBy, values, flatten } from "lodash";
import AppDataCacheCtx from "../../lib/data-cache";
import { Subtitle } from "../../modules/style-guide/text";
import { Spacer, SpacerXsm } from "../../modules/style-guide/spacer";
import Table from "../../modules/style-guide/table";
import { ListIcon } from "../../modules/style-guide/icons";
import BaseDataPage from "../base-data";
import BaseLayoutPage, {
  renderSkeleton,
  PageAsideContent,
  PageContent
} from "../base-layout";
import styles from "./SampleExerciseSets.module.css";

import * as SampleMaterialsAPI from "../../lib/api/sample-materials";
import * as ExercisesAPI from "../../lib/api/exercises";

import type { AppDataCache } from "../../lib/data-cache";
import type { ExerciseSet } from "../../lib/api/exercise-sets";
import type { Exercise } from "../../lib/api/exercises";

type Props = {||};

class SampleExerciseSetsPage extends React.Component<Props> {
  fetchData = async () => {
    const exerciseSets = await SampleMaterialsAPI.fetchSampleExerciseSets();
    const exercises = flatten(
      await Promise.all(
        exerciseSets.map(es => ExercisesAPI.fetchExercises(es.id))
      )
    );

    return {
      exerciseSets: keyBy(exerciseSets, es => es.id),
      exercises: keyBy(exercises, e => e.id)
    };
  };

  renderSample(cache: AppDataCache) {
    const allExerciseSets: ExerciseSet[] = values(cache.exerciseSets);
    const allExercises: Exercise[] = values(cache.exercises);
    const sampleExerciseSets = allExerciseSets.filter(
      es => es.tags.indexOf("exercise_sets.sample_material") >= 0
    );

    return (
      <>
        <Subtitle>
          These sample questions help you understand how to use AutoGradr, the
          many stacks it supports, and various grading types.
        </Subtitle>
        <Spacer />
        <Table
          listBorder
          columns={[
            { accessor: "title", left: true, compact: true, width: "40%" },
            { accessor: "exercise_count", compact: true },
            { accessor: "edit", right: true, compact: true }
          ]}
          rows={sampleExerciseSets.map(es => {
            const exercises = allExercises.filter(
              e => e.exercise_set_id === es.id
            );

            return {
              key: es.id,
              data: {
                title: <Link to={`/questions/${es.id}`}>{es.title}</Link>,
                exercise_count: (
                  <span className={styles.exercisesCount}>
                    <ListIcon />
                    <SpacerXsm />
                    {`${exercises.length} exercise${
                      exercises.length === 1 ? "" : "s"
                    }`}
                  </span>
                ),
                edit: null
              }
            };
          })}
        />
      </>
    );
  }

  render() {
    return (
      <BaseDataPage fetchData={this.fetchData} renderSkeleton={renderSkeleton}>
        <AppDataCacheCtx.Consumer>
          {({ cache }) => {
            return (
              <BaseLayoutPage>
                <PageContent title="Sample Questions" actions={[]}>
                  {this.renderSample(cache)}
                </PageContent>
                <PageAsideContent />
              </BaseLayoutPage>
            );
          }}
        </AppDataCacheCtx.Consumer>
      </BaseDataPage>
    );
  }
}

export default SampleExerciseSetsPage;
