// @flow
import makeRequest from "../make-request";

import type { ExerciseSet } from "../exercise-sets";

const sampleTag = "exercise_sets.sample_material";

export async function fetchSampleExerciseSets(): Promise<ExerciseSet[]> {
  const url = `/api/exercise-sets?tag=${sampleTag}`;

  return makeRequest(url);
}
