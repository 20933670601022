// @flow
import * as React from "react";
import styles from "./Button.module.css";

type Props = {
  children: React.Node,
  className?: string,
  link?: boolean,
  wide?: boolean,
  small?: boolean,
  secondary?: boolean,
  danger?: boolean,
  disabled?: boolean,
  pro?: boolean,
  el?: string | React.ComponentType<any>
};

class Button extends React.Component<Props> {
  render() {
    const {
      className,
      link,
      wide,
      small,
      secondary,
      danger,
      disabled,
      pro,
      el,
      ...elProps
    } = this.props;
    const classNames = [
      link ? styles.link : styles.btn,
      wide ? styles.wide : "",
      small ? styles.small : "",
      secondary ? styles.secondary : "",
      danger ? styles.danger : "",
      disabled ? styles.disabled : "",
      pro ? styles.pro : "",
      className || ""
    ].join(" ");

    return React.createElement(el || (link ? "a" : "button"), {
      ...elProps,
      className: classNames,
      disabled
    });
  }
}

export default Button;
