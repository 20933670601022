// @flow
import * as React from "react";
import Modal from "../modal";

type Props = {|
  renderOpener: (isOpen: boolean, open: () => any) => React.Node,
  renderModal: (close: () => any) => React.Node,
  wide?: ?boolean
|};

type State = {|
  isOpen: boolean
|};

class StatefulModal extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      isOpen: false
    };
  }

  handleOpen = () => this.setState({ isOpen: true });

  handleClose = () => this.setState({ isOpen: false });

  render() {
    const { renderOpener, renderModal, wide } = this.props;
    const { isOpen } = this.state;

    return (
      <>
        {renderOpener(isOpen, this.handleOpen)}
        <Modal isOpen={isOpen} onRequestClose={this.handleClose} wide={wide}>
          {renderModal(this.handleClose)}
        </Modal>
      </>
    );
  }
}

export default StatefulModal;
