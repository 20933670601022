// @flow
import * as React from "react";
import Tree from "../../../style-guide/tree";
import { FaPlus } from "react-icons/fa";
import Popover from "../../../style-guide/popover";
import NewFile from "./new-file";
import styles from "./FileBrowser.module.css";

type Props = {|
  readOnly?: boolean,
  paths: string[],
  onSelectFile: (path: string) => void,
  onDeleteFile: (path: string) => Promise<void>,
  onNewFile: (path: string) => void
|};

class FileBrowser extends React.Component<Props> {
  popoverEl: ?Popover;

  render() {
    const { readOnly } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.actionBar}>
          {readOnly ? null : (
            <Popover
              ref={r => {
                this.popoverEl = r;
              }}
              popoverEl={
                <NewFile
                  onNewFile={path => {
                    this.props.onNewFile(path);
                    this.popoverEl && this.popoverEl.hide();
                  }}
                />
              }
            >
              <FaPlus />
            </Popover>
          )}
        </div>
        <Tree
          readOnly={readOnly}
          paths={this.props.paths}
          onClick={path => this.props.onSelectFile(path)}
          onDelete={path => this.props.onDeleteFile(path)}
        />
      </div>
    );
  }
}

export default FileBrowser;
