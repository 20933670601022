// @flow
import * as React from "react";
import LogoOnly from "./logo";
import LogoWithText from "./logo-with-text";
import LogoSmall from "./logo-small";

type Props = {|
  noText?: boolean,
  small?: boolean
|};

const Logo = (props: Props) => {
  if (props.small) {
    return <LogoSmall />;
  }

  return props.noText ? <LogoOnly /> : <LogoWithText />;
};

export default Logo;
