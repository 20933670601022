// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import { FaTasks } from "react-icons/fa";
import { getSessionProfile } from "../../lib/session";
import { withAppDataCacheCtx } from "../../lib/data-cache";
import {
  displayRelative,
  displayDateTime,
  displayDuration
} from "../../lib/dates";
import List from "../../modules/style-guide/list";
import Card from "../../modules/style-guide/card";
import {
  InProgressIndicator,
  NeutralIndicator,
  SuccessIndicator
} from "../../modules/style-guide/indicators";
import { Spacer, SpacerSm } from "../../modules/style-guide/spacer";
import { Subtitle } from "../../modules/style-guide/text";
import Button from "../../modules/style-guide/button";
import styles from "./CourseOverview.module.css";

import type { Assignment } from "../../lib/api/assignments";
import type { AppDataCache } from "../../lib/data-cache";
import type { ExerciseSet } from "../../lib/api/exercise-sets";
import getAssignmentSessionState from "../../lib/get-assignment-session-state";
import StartAssignmentButton from "./start-assignment-button";

type Props = {|
  assignments: Assignment[],

  cache: AppDataCache
|};

const getGradebookLink = (as: Assignment): string =>
  ["/courses", as.course_id, "assignments", as.id, "gradebook"].join("/");

const getExerciseSetLink = (
  as: Assignment,
  es: ExerciseSet,
  role: "INSTRUCTOR" | "STUDENT"
): string =>
  role === "STUDENT"
    ? `/courses/${as.course_id}/assignments/${as.id}/questions/${es.id}`
    : `/questions/${es.id}`;

const AssignmentIcon = ({ assignment }: { assignment: Assignment }) => {
  if (assignment.is_past_due) {
    return <SuccessIndicator />;
  }

  if (assignment.is_available) {
    return <InProgressIndicator />;
  }

  return <NeutralIndicator />;
};

class AssignmentsList extends React.Component<Props> {
  render() {
    const { assignments, cache } = this.props;
    const role = getSessionProfile(cache).role;

    return (
      <Card className={styles.cardContainer}>
        <List
          items={assignments.map((as: Assignment) => {
            const exerciseSets = as.exercise_set_ids.map(
              id => cache.exerciseSets[id]
            );

            const sessionState =
              role === "INSTRUCTOR"
                ? "IN_SESSION"
                : getAssignmentSessionState(as);

            return {
              id: as.id,
              icon: <AssignmentIcon assignment={as} />,
              label: (
                <>
                  <strong>{as.name}</strong>
                  <SpacerSm />
                  <Subtitle>
                    {as.is_available ? "Assigned " : "Opens "}
                    {displayRelative(as.available_at)}
                    {", due "}
                    {displayDateTime(as.due_at)}
                  </Subtitle>
                  <Spacer />

                  {sessionState === "REQUIRED" && as.session_time_limit ? (
                    <Subtitle>
                      You will have{" "}
                      <strong>{displayDuration(as.session_time_limit)}</strong>{" "}
                      to complete your assignment.
                    </Subtitle>
                  ) : null}

                  {role === "STUDENT" &&
                  sessionState === "IN_SESSION" &&
                  as.session_time_limit ? (
                    <>
                      <Subtitle>
                        You have{" "}
                        <strong>
                          {displayDuration(as.session_time_limit)}
                        </strong>{" "}
                        to complete your assignment
                        {as.session_started_at
                          ? ` starting ${displayDateTime(
                              as.session_started_at
                            )}`
                          : "."}
                        .
                      </Subtitle>
                      <Spacer />
                    </>
                  ) : null}

                  {sessionState === "EXPIRED" ? (
                    <Subtitle>
                      Your time is complete. You cannot make any more attempts.
                    </Subtitle>
                  ) : null}

                  {sessionState === "IN_SESSION" &&
                    exerciseSets.map(es => (
                      <div key={es.id}>
                        <Link to={getExerciseSetLink(as, es, role)}>
                          <FaTasks className={styles.labIcon} /> {es.title}
                        </Link>
                        <SpacerSm />
                      </div>
                    ))}
                </>
              ),
              actions: [
                () =>
                  role === "INSTRUCTOR" ? (
                    <Button small secondary el={Link} to={getGradebookLink(as)}>
                      Gradebook
                    </Button>
                  ) : null,
                () =>
                  sessionState === "REQUIRED" ? (
                    <StartAssignmentButton assignmentID={as.id} />
                  ) : null
              ]
            };
          })}
        />
      </Card>
    );
  }
}

export default withAppDataCacheCtx(AssignmentsList);
