import React from "react";

const ManHoldingFile = props => (
  <svg viewBox="0 0 1055 902" {...props}>
    <title>{"illustrations/project"}</title>
    <g transform="matrix(-1 0 0 1 1055 0)" fillRule="nonzero" fill="none">
      <path fill="#DE8E68" d="M995.32 807.29h29.31v22.85h-29.31z" />
      <path
        d="M938.18 220.3c-3.3-2.48-28.39-38.35-34.73-63.83-6.34-25.48-6.89 74.58-6.89 74.58l15.71 13.78 25.91-24.53z"
        fill="#FFCC9B"
      />
      <path
        fill="#D7E6FF"
        d="M934.86 588.49l-34.07 59.62-8.52-185.26 54.3 1.78z"
      />
      <path
        d="M970.14 210.78c86.35 18.22 59.49 217.22 42.75 233.52-16.74 16.3-140.1 19.33-152 5.7-11.9-13.63.88-262.11 109.25-239.22z"
        fill="#FFD7B6"
      />
      <path
        d="M982.27 232.9c-1 6.62-40.08 1.91-38.38-7.19l9.94-53 38.38 7.2s-8.93 46.38-9.94 52.99z"
        fill="#D37C59"
      />
      <ellipse
        fill="#DE8E68"
        transform="rotate(-79.39 966.619 146.517)"
        cx={966.619}
        cy={146.517}
        rx={54.17}
        ry={38.97}
      />
      <path
        d="M937.5 58.5c10.25 0 14.56 7 41 7 12.86 0 39 2.74 39 36 0 22.34 13 24 13 38 0 7-6.19 24-22 24-7 0-14-12-14-16s4-7 4-10c.08-14.45-46.73-22.48-49.85-35.48-3.39 3.11-11.19 12.7-14.1 18.91-4-2.05-3.54-5.38-8-8.43-3.85-2.61-15.85-9.29-15.85-22.59-.05-6.61 6.84-31.41 26.8-31.41z"
        fill="#0F212E"
      />
      <circle fill="#DE8E68" cx={999.95} cy={156.47} r={12.53} />
      <path
        fill="#EBF2FF"
        d="M1010.22 446.29l19.56 363.03h-35.79L898.73 468.4v-20.78z"
      />
      <path fill="#DFDFDF" d="M0 58.9h904.54v667.49H0z" />
      <path fill="#F3F3F3" d="M0 137.24h904.54v667.49H0z" />
      <path
        d="M950.35 315.07c-8.31 4.29-20.37 10.45-28.68 26.8-10.45-1.88-62.45-11.26-99.7-10.72-3.48 7.5-9.38 19.3-7 20.91 2.38 1.61 78.8 33.5 132.4 28.67 7 .5 35.16-26 36.07-28.14.91-2.14-33.09-37.52-33.09-37.52z"
        fill="#DE8E68"
      />
      <ellipse
        fill="#DE8E68"
        transform="rotate(-73.84 803.676 337.813)"
        cx={803.676}
        cy={337.813}
        rx={16.27}
        ry={24.27}
      />
      <ellipse fill="#DE8E68" cx={826.68} cy={22.66} rx={25.77} ry={22.05} />
      <path fill="#DFDFDF" d="M554.91 266.4H223.17v538.33h453.7V388.36z" />
      <circle fill="#FFF" cx={346.37} cy={472.1} r={52.66} />
      <path
        fill="#FFF"
        d="M427.33 512.68h179v14.15h-179zM293.71 575.96h312.63v14.15H293.71zM293.71 639.23h312.63v14.15H293.71z"
      />
      <rect
        fill="#F3F3F3"
        x={580.79}
        y={13.74}
        width={323.75}
        height={149.76}
        rx={24.54}
      />
      <path
        d="M580.79 38.28c0 11.32-34.84 99-220.35 99 0 18.9 230.1 9.37 230.1 9.37l3.4-106.54-13.15-1.83z"
        fill="#F3F3F3"
      />
      <path
        d="M1029 265.69c23 25.92-33.5 100.44-44.39 97.36-12.83-3.63-51.87-40.17-52.18-52.82-.31-12.65 68.2-76.49 96.57-44.54z"
        fill="#FFCC9B"
      />
      <path
        fill="#0B35CA"
        d="M995.32 824.18v18.63l45.94 58.34 13.74-6.68-28.14-70.29z"
      />
    </g>
  </svg>
);

export default ManHoldingFile;
