// @flow
import * as React from "react";
import CodeEditor from "./code-editor-component";

import type { WorkspaceRenderProps } from "../index";

type Props = {|
  ...WorkspaceRenderProps,
  readOnly?: boolean,
  toolbarEndEnhancer?: () => React.Node
|};

type State = {|
  selectedFilePath: ?string
|};

class CodeEditorContainer extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      selectedFilePath: null
    };
  }

  handleSelectFile = (path: ?string) => {
    if (path) {
      this.props.loadFile(path);
    }

    this.setState({
      selectedFilePath: path
    });
  };

  render() {
    return (
      <CodeEditor
        {...this.props}
        selectedFilePath={this.state.selectedFilePath}
        selectFile={this.handleSelectFile}
      />
    );
  }
}

export default CodeEditorContainer;
