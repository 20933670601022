// @flow
import * as React from "react";
import { Prompt } from "react-router-dom";
import { keyBy, values } from "lodash";
import * as WorkspacesAPI from "../../lib/api/workspaces";

export type File = {|
  path: string,
  data?: string | Blob,
  deleted: boolean,

  loading: boolean,
  loadError: ?string,

  saving: boolean,
  saved: boolean,
  saveError: ?string
|};

export type WorkspaceRenderProps = {|
  workspaceID: string,
  files: File[],
  loading: boolean,
  loadError: ?string,

  loadFile: (path: string) => Promise<void>,
  newFile: (path: string, data?: string | Blob) => Promise<void>,
  deleteFile: (path: string) => Promise<void>,
  updateFileData: (path: string, data: string) => Promise<void>,
  saveFile: (path: string) => Promise<void>
|};

type Props = {
  workspaceID: string,
  component?: React.ComponentType<any>,
  renderComponent?: (props: WorkspaceRenderProps) => React.Node,

  uploadFile?: (path: string, data: string | Blob) => Promise<void>,
  deleteFile?: (path: string) => Promise<void>
};

type State = {|
  loading: boolean,
  error: ?string,

  files: { [string]: File }
|};

class Workspace extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      loading: true,
      error: null,
      files: {}
    };
  }

  componentDidMount(): void {
    this.fetchData();
  }

  componentDidUpdate = () => {
    if (this.hasUnsavedFiles()) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  hasUnsavedFiles() {
    const files: File[] = (Object.values(this.state.files): any);
    return files.filter(f => !f.saved).length > 0;
  }

  save(): Promise<any> {
    return Promise.all(
      (values(this.state.files): File[])
        .filter(f => !f.saved)
        .map(f => this.handleSaveFile(f.path))
    );
  }

  async fetchData() {
    try {
      const paths: string[] = await WorkspacesAPI.fetchFiles(
        this.props.workspaceID
      );
      const files: File[] = paths.map(path => ({
        path,
        deleted: false,
        loading: false,
        loadError: null,
        saving: false,
        saved: true,
        saveError: null
      }));
      const filesByPath: { [string]: File } = keyBy(files, f => f.path);

      this.setState({
        loading: false,
        error: null,
        files: filesByPath
      });
    } catch (e) {
      this.setState({
        loading: false,
        error: "Failed to load workspace files"
      });
    }
  }

  updateFile(f: File) {
    this.setState(state => ({
      files: {
        ...state.files,
        [f.path]: f
      }
    }));
  }

  handleLoadFile = async (path: string) => {
    const file: ?File = this.state.files[path];

    if (!file || file.data || file.data === "") {
      return;
    }

    this.updateFile({ ...file, loading: true });

    try {
      const data = await WorkspacesAPI.downloadFile(
        this.props.workspaceID,
        file.path
      );

      this.updateFile({ ...file, loading: false, data });
    } catch (e) {
      this.updateFile({
        ...file,
        loading: false,
        loadError: "Failed to download file"
      });
    }
  };

  handleNewFile = async (path: string, data?: string | Blob) => {
    const file: File = {
      path,
      data,
      deleted: false,
      loading: false,
      loadError: null,
      saving: false,
      saved: false,
      saveError: null
    };

    this.updateFile(file);
  };

  handleDeleteFile = async (path: string) => {
    const file: ?File = this.state.files[path];

    if (!file) {
      return;
    }

    this.updateFile({ ...file, deleted: true, saved: false });
  };

  handleUpdateFileData = async (path: string, data: string | Blob) => {
    const file: ?File = this.state.files[path];

    if (!file) {
      return;
    }

    this.updateFile({ ...file, data, saved: false });
  };

  handleSaveFile = async (path: string) => {
    const { deleteFile, uploadFile } = this.props;
    const file: ?File = this.state.files[path];

    if (!file || file.saving) {
      return;
    }

    this.updateFile({ ...file, saving: true });

    try {
      if (file.deleted) {
        deleteFile
          ? await deleteFile(path)
          : await WorkspacesAPI.deleteFile(this.props.workspaceID, path);
      } else {
        uploadFile
          ? await uploadFile(file.path, file.data || "")
          : await WorkspacesAPI.uploadFile(
              this.props.workspaceID,
              file.path,
              file.data || ""
            );
      }

      this.updateFile({ ...file, saving: false, saved: true });
    } catch (e) {
      this.updateFile({
        ...file,
        saving: false,
        saveError: "Failed to save file"
      });
    }
  };

  render() {
    const {
      workspaceID,
      component,
      renderComponent,
      ...componentProps
    } = this.props;
    const files: File[] = (Object.values(this.state.files): any);

    const renderProps: WorkspaceRenderProps = {
      workspaceID,
      files,
      loading: this.state.loading,
      loadError: this.state.error,

      loadFile: this.handleLoadFile,
      newFile: this.handleNewFile,
      deleteFile: this.handleDeleteFile,
      updateFileData: this.handleUpdateFileData,
      saveFile: this.handleSaveFile
    };

    const prompt = (
      <Prompt
        when={this.hasUnsavedFiles()}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
    );
    if (renderComponent) {
      return (
        <>
          {prompt}
          {renderComponent(renderProps)}
        </>
      );
    }

    if (component) {
      return (
        <>
          {prompt}
          {React.createElement(component, {
            ...componentProps,
            ...renderProps
          })}
        </>
      );
    }

    throw Error("children and component are undefined");
  }
}

export default Workspace;
