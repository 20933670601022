// @flow
import * as React from "react";
import AppDataCacheCtx from "../../lib/data-cache";
import BaseDataPage from "../base-data";
import BaseLayoutPage, {
  renderSkeleton,
  PageAsideContent,
  PageContent
} from "../base-layout";
import { SpacerLg, SpacerSm } from "../../modules/style-guide/spacer";
import { H3 } from "../../modules/style-guide/text";
import Card from "../../modules/style-guide/card";
import DeleteCourse from "./delete-course";
import RenameCourse from "./rename-course";
import styles from "./CourseManage.module.css";

import * as CoursesAPI from "../../lib/api/courses";

import type { updateAppDataCacheFunc } from "../../lib/data-cache";
import CourseStatusNotification from "../../modules/course-status-notification";

type Props = {||};

class CourseManagePage extends React.Component<Props> {
  fetchData = async () => {};

  handleDelete = async () => {
    // $FlowFixMe: add defs for react-router
    const { match, history } = this.props;
    const { courseID } = match.params;

    await CoursesAPI.deleteCourse(courseID);

    history.push("/");
  };

  handleRename = (updateCache: updateAppDataCacheFunc) => async (
    name: string
  ) => {
    // $FlowFixMe: add defs for react-router
    const { courseID } = this.props.match.params;

    await CoursesAPI.updateCourse(courseID, name);

    updateCache({
      courses: {
        [courseID]: {
          name
        }
      }
    });
  };

  render() {
    // $FlowFixMe: add defs for react-router
    const { courseID } = this.props.match.params;

    return (
      <BaseDataPage fetchData={this.fetchData} renderSkeleton={renderSkeleton}>
        <AppDataCacheCtx.Consumer>
          {({ cache, updateCache }) => {
            const course = cache.courses[courseID];

            return (
              <BaseLayoutPage>
                <PageContent title={course.name}>
                  <CourseStatusNotification courseID={courseID} />
                  <RenameCourse
                    name={course.name}
                    onRename={this.handleRename(updateCache)}
                  />
                  <SpacerLg />
                  <H3>Danger Zone</H3>
                  <SpacerSm />
                  <Card className={styles.padded}>
                    <DeleteCourse
                      courseID={courseID}
                      onDelete={this.handleDelete}
                    />
                  </Card>
                </PageContent>
                <PageAsideContent />
              </BaseLayoutPage>
            );
          }}
        </AppDataCacheCtx.Consumer>
      </BaseDataPage>
    );
  }
}

export default CourseManagePage;
