// @flow
import * as React from "react";
import AppDataCacheCtx from "../../lib/data-cache";
import ExternalLink from "../style-guide/external-link";
import { RunningText } from "../style-guide/text";
import { Spacer, SpacerSm } from "../style-guide/spacer";
import Button from "../style-guide/button";
import { ExternalLinkIcon } from "../style-guide/icons";
import Pill from "../style-guide/pill";
import Workspace from "../workspace";
import OutputMatching from "./output-matching";
import WebUITests from "./web-ui-tests";

type Props = {|
  testCaseID: string,
  editable?: boolean
|};

class TestCaseContainer extends React.Component<Props> {
  render() {
    const { testCaseID, editable } = this.props;

    return (
      <AppDataCacheCtx.Consumer>
        {({ cache }) => {
          const testCase = cache.testCases[testCaseID];

          if (!testCase) {
            throw Error("test case is undefined");
          }

          switch (testCase.ui_widget) {
            case "OUTPUT_MATCHING":
              return (
                <Workspace
                  workspaceID={testCase.workspace_id}
                  component={OutputMatching}
                  editable={editable}
                />
              );
            case "WEB_UI":
              return (
                <Workspace
                  workspaceID={testCase.workspace_id}
                  component={WebUITests}
                  editable={editable}
                />
              );
            case "JUNIT":
              return (
                <div>
                  <Pill>JUnit Tests</Pill>
                  <SpacerSm />
                  <RunningText>
                    {editable
                      ? "Open the workspace and edit the TestRunner.java file to setup the unit tests."
                      : "Your submission will be tested against unit tests."}
                  </RunningText>
                  {editable && (
                    <>
                      <Spacer />
                      <Button
                        small
                        secondary
                        el={ExternalLink}
                        href={`/workspaces/${testCase.workspace_id}${
                          editable ? "" : "?read_only=true"
                        }`}
                      >
                        {editable ? "Setup" : "View"} Unit Tests
                        <SpacerSm horizontal />
                        <ExternalLinkIcon />
                      </Button>
                    </>
                  )}
                </div>
              );
            case "PYTEST":
              return (
                <div>
                  <Pill>pytest</Pill>
                  <SpacerSm />
                  <RunningText>
                    {editable
                      ? "Open the workspace and edit the test_main.py file to setup the unit tests."
                      : "Your submission will be tested against unit tests."}
                  </RunningText>
                  {editable && (
                    <>
                      <Spacer />
                      <Button
                        small
                        secondary
                        el={ExternalLink}
                        href={`/workspaces/${testCase.workspace_id}${
                          editable ? "" : "?read_only=true"
                        }`}
                      >
                        {editable ? "Setup" : "View"} Unit Tests
                        <SpacerSm horizontal />
                        <ExternalLinkIcon />
                      </Button>
                    </>
                  )}
                </div>
              );
            case "GOTEST":
              return (
                <div>
                  <Pill>gotest</Pill>
                  <SpacerSm />
                  <RunningText>
                    {editable
                      ? "Open the workspace and edit the main_test.go file to setup the unit tests."
                      : "Your submission will be tested against unit tests."}
                  </RunningText>
                  {editable && (
                    <>
                      <Spacer />
                      <Button
                        small
                        secondary
                        el={ExternalLink}
                        href={`/workspaces/${testCase.workspace_id}${
                          editable ? "" : "?read_only=true"
                        }`}
                      >
                        {editable ? "Setup" : "View"} Unit Tests
                        <SpacerSm horizontal />
                        <ExternalLinkIcon />
                      </Button>
                    </>
                  )}
                </div>
              );
            case "CUSTOM_SCRIPT":
              return (
                <div>
                  <Pill>Custom Script</Pill>
                  <SpacerSm />
                  <RunningText>
                    {editable
                      ? "Open the workspace and edit the test.py file to setup the test script."
                      : "Your submission will be tested against a custom script."}
                  </RunningText>
                  {editable ? (
                    <>
                      <Spacer />
                      <Button
                        small
                        secondary
                        el={ExternalLink}
                        href={`/workspaces/${testCase.workspace_id}`}
                      >
                        Setup Custom Script
                        <SpacerSm horizontal />
                        <ExternalLinkIcon />
                      </Button>
                    </>
                  ) : null}
                </div>
              );
            case "HIDDEN":
              return <div>This test case is hidden from you.</div>;
            default:
              return (
                <Button
                  small
                  el={ExternalLink}
                  href={`/workspaces/${testCase.workspace_id}${
                    editable ? "" : "?read_only=true"
                  }`}
                >
                  Open Workspace
                </Button>
              );
          }
        }}
      </AppDataCacheCtx.Consumer>
    );
  }
}

export default TestCaseContainer;
