// @flow
import * as React from "react";
import { capitalize } from "lodash";
import { withRouter } from "react-router-dom";
import { withAppDataCacheCtx } from "../../lib/data-cache";
import { H5 } from "../../modules/style-guide/text";
import Panel from "../../modules/style-guide/accordion/panel";
import StatefulAccordion from "../../modules/style-guide/accordion/stateful";
import NavLink from "./nav-link";

import styles from "./BaseLayout.module.css";

import * as AuthAPI from "../../lib/api/auth";
import type { AppDataCache } from "../../lib/data-cache";

type Props = {|
  cache: AppDataCache
|};

class AccountSubNav extends React.Component<Props> {
  handleLogout = async () => {
    // $FlowFixMe: add defs for react-router
    const { history } = this.props;

    try {
      await AuthAPI.logout();
    } catch (e) {
    } finally {
      history.push("/login");
    }
  };

  render() {
    const { cache } = this.props;
    const session = cache.session;

    if (!session) {
      return <div className={styles.accountSubNavSkeleton} />;
    }

    const profile = cache.profiles[session.profileID];

    return (
      <div className={styles.accountSubNav}>
        <StatefulAccordion>
          <Panel
            compact
            className={styles.lightText}
            title={<H5 className={styles.lightText}>{profile.name}</H5>}
            subtitle={
              <span className={styles.lightText}>
                {capitalize(profile.role)}
              </span>
            }
          >
            <NavLink to="/profile">Profile</NavLink>
            <NavLink to="#" onClick={this.handleLogout}>
              Logout
            </NavLink>
          </Panel>
        </StatefulAccordion>
      </div>
    );
  }
}

export default withRouter(withAppDataCacheCtx(AccountSubNav));
