// @flow
import * as React from "react";
import List from "../../style-guide/list";
import StatefulAccordion from "../../style-guide/accordion/stateful";
import Panel from "../../style-guide/accordion/panel";
import Pill from "../../style-guide/pill";
import { H4 } from "../../style-guide/text";
import { Spacer } from "../../style-guide/spacer";
import Button from "../../style-guide/button";
import ActionBar from "../../style-guide/action-bar";
import NewActionStep from "./new-action-step";
import getActionListItems from "./get-action-list-items";
import CreateSequence from "./create-sequence";
import styles from "./WebUITests.module.css";

import type { WorkspaceRenderProps } from "../../workspace";

type Props = {|
  ...WorkspaceRenderProps,
  editable?: boolean
|};

export type WebUITestAction =
  | {
      type: "GO_TO",
      url: string
    }
  | {
      type: "MATCH",
      matcher: string
    }
  | {
      type: "FILL",
      selector: string,
      value: string
    }
  | {
      type: "CLICK",
      selector: string,
      text?: string
    };

export type WebUITest = {
  label: string,
  actions: WebUITestAction[]
};

const uiTestsFilePath = "/ui_tests.json";

class WebUITestsContainer extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    if (prevProps.loading && !this.props.loading && this.props.files.length) {
      this.loadUITests();
    }
  }

  get isLoading() {
    const { loading, files } = this.props;
    const uiTestsFile = files.find(f => f.path === uiTestsFilePath);

    return loading || !uiTestsFile || !uiTestsFile.data || uiTestsFile.loading;
  }

  get isSaving() {
    return this.props.files.filter(f => f.saving).length > 0;
  }

  get saveError(): ?string {
    return this.props.files.filter(f => f.saveError).length > 0
      ? "Failed to save some changes"
      : null;
  }

  get uiTests(): WebUITest[] {
    const file = this.props.files.find(p => p.path === uiTestsFilePath);

    if (!file || file.loading || file.loadError) {
      return [];
    }

    const { data } = file;

    if (!data || typeof data !== "string") {
      return [];
    }

    return JSON.parse(data);
  }

  set uiTests(tests: WebUITest[]) {
    this.props.updateFileData(uiTestsFilePath, JSON.stringify(tests));
  }

  loadUITests() {
    const { files, loadFile } = this.props;
    const uiTestsFile = files.find(p => p.path === uiTestsFilePath);

    if (!uiTestsFile) {
      return;
    }

    loadFile(uiTestsFile.path);
  }

  getNewActionListItem(testIdx: number) {
    return {
      id: "new-action",
      className: styles.sequenceStep,
      label: (
        <NewActionStep
          onAddNewActionStep={this.handleAddNewActionStep(testIdx)}
        />
      ),
      actions: []
    };
  }

  handleCreateSequence = (name: string) => {
    this.uiTests = [
      ...this.uiTests,
      {
        label: name,
        actions: [
          {
            type: "GO_TO",
            url: "http://localhost:8080"
          }
        ]
      }
    ];
  };

  handleActionMoveUp = (testIdx: number, actionIdx: number) => {
    if (actionIdx === 0) {
      return;
    }
    const uiTests = this.uiTests;
    const a = uiTests[testIdx].actions[actionIdx - 1];
    uiTests[testIdx].actions[actionIdx - 1] =
      uiTests[testIdx].actions[actionIdx];
    uiTests[testIdx].actions[actionIdx] = a;
    this.uiTests = uiTests;
  };

  handleActionMoveDown = (testIdx: number, actionIdx: number) => {
    const uiTests = this.uiTests;
    if (actionIdx === uiTests[testIdx].actions.length - 1) {
      return;
    }
    const a = uiTests[testIdx].actions[actionIdx + 1];
    uiTests[testIdx].actions[actionIdx + 1] =
      uiTests[testIdx].actions[actionIdx];
    uiTests[testIdx].actions[actionIdx] = a;
    this.uiTests = uiTests;
  };

  handleActionDelete = (testIdx: number, actionIdx: number) => {
    const uiTests = this.uiTests;

    uiTests[testIdx].actions.splice(actionIdx, 1);

    if (!uiTests[testIdx].actions.length) {
      uiTests.splice(testIdx, 1);
    }

    this.uiTests = uiTests;
  };

  handleAddNewActionStep = (testIdx: number) => (action: WebUITestAction) => {
    const uiTests = this.uiTests;
    uiTests[testIdx].actions = [...uiTests[testIdx].actions, action];
    this.uiTests = uiTests;
  };

  handleSave = () => {
    if (this.isSaving) {
      return;
    }

    this.props.files
      .filter(f => !f.saved)
      .forEach(f => this.props.saveFile(f.path));
  };

  render() {
    const { editable } = this.props;

    if (this.isLoading) {
      return <div>Loading..</div>;
    }

    return (
      <div>
        <Pill>Web UI Tests</Pill>
        <Spacer />
        {editable && <CreateSequence onCreate={this.handleCreateSequence} />}
        <StatefulAccordion>
          {this.uiTests.map((test, testIdx) => (
            <Panel
              key={`test-${testIdx}`}
              className={styles.sequence}
              title={<H4>{test.label}</H4>}
              subtitle={
                <span>
                  {test.actions.length} step{test.actions.length !== 1 && "s"}
                </span>
              }
            >
              <List
                items={getActionListItems({
                  test,
                  testIdx,
                  editable,

                  onActionMoveUp: this.handleActionMoveUp,
                  onActionMoveDown: this.handleActionMoveDown,
                  onActionDelete: this.handleActionDelete
                }).concat(editable ? [this.getNewActionListItem(testIdx)] : [])}
              />
            </Panel>
          ))}
        </StatefulAccordion>
        {editable && (
          <ActionBar error={this.saveError}>
            <Button
              small
              secondary
              disabled={this.isSaving}
              onClick={this.handleSave}
            >
              {this.isSaving ? "Saving.." : "Save"}
            </Button>
          </ActionBar>
        )}
      </div>
    );
  }
}

export default WebUITestsContainer;
