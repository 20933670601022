// @flow
import * as React from "react";
import type { Course } from "../../lib/api/courses";
import styles from "./CreateCourseForm.module.css";
import { Error as ErrorBox, H3 } from "../style-guide/text";
import { Spacer, SpacerLg } from "../style-guide/spacer";
import {
  CardElement,
  CardElement as StripeCardElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import Button from "../style-guide/button";
import StudentSeatsEditor from "./student-seats-editor";
import * as CoursesAPI from "../../lib/api/courses";
import * as PaymentsAPI from "../../lib/api/payments";
import AppDataCacheCtx from "../../lib/data-cache";
import { getCourseTotalPrice } from "../../lib/course";
import type { Address } from "../style-guide/address-form";
import AddressForm from "../style-guide/address-form";

type Props = {|
  course: $Shape<Course>,
  onCreateCourse: (id: string, paymentID: string) => void
|};

const UpfrontPaymentForm = (props: Props) => {
  const stripe = useStripe();
  const stripeElements = useElements();
  const { cache } = React.useContext(AppDataCacheCtx);

  const [seats, setSeats] = React.useState(props.course.seats || 0);
  const [isCreating, setIsCreating] = React.useState(false);
  const [createErr, setIsCreateErr] = React.useState<?string>(null);
  const [address, setAddress] = React.useState<Address>({
    name: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipCode: "",
    countryISO2: "US"
  });

  const isValid = React.useCallback(
    () =>
      seats &&
      stripe &&
      stripeElements &&
      address.name &&
      address.line1 &&
      address.city &&
      address.state &&
      address.zipCode,
    [seats, stripe, stripeElements, address]
  );

  if (!cache.session) {
    throw new Error("session must be set in app cache");
  }

  const profile = cache.profiles[cache.session.profileID];

  const onSubmit = React.useCallback(
    async () => {
      if (!stripe || !stripeElements) {
        return;
      }

      setIsCreating(true);

      try {
        const amount = getCourseTotalPrice({ ...props.course, seats }) * 100;
        const secret = await PaymentsAPI.getStripeClientSecret(amount);
        const paymentResp = await stripe.confirmCardPayment(secret, {
          receipt_email: profile.email,
          payment_method: {
            card: stripeElements.getElement(CardElement),
            billing_details: {
              name: address.name,
              address: {
                line1: address.line1,
                line2: address.line2,
                city: address.city,
                state: address.state,
                postal_code: address.zipCode,
                country: address.countryISO2
              }
            }
          }
        });

        if (paymentResp.error) {
          setIsCreateErr(paymentResp.error.message);
          setIsCreating(false);
          return;
        }

        const paymentID = paymentResp.paymentIntent.id;

        const course = await CoursesAPI.createCourse({
          ...props.course,
          payment_method: "UPFRONT",
          payment_id: paymentID,
          seats
        });

        props.onCreateCourse(course.id, paymentID);
      } catch (e) {
        setIsCreateErr("Failed to create course. Please contact support.");
        setIsCreating(false);
      }
    },
    [stripe, stripeElements, seats, address]
  );

  return (
    <div className={styles.content}>
      <H3>{props.course.name}</H3>
      <SpacerLg />

      <div className={styles.form}>
        <StudentSeatsEditor course={props.course} onChange={setSeats} />

        <SpacerLg />

        <div className={styles.cardForm}>
          <StripeCardElement />
        </div>

        <Spacer />

        <AddressForm address={address} onChange={setAddress} />
      </div>

      <SpacerLg />
      <div className={styles.actionBar}>
        <Button wide disabled={!isValid() || isCreating} onClick={onSubmit}>
          {isCreating ? "Completing Payment.." : "Complete Payment"}
        </Button>
        {createErr && (
          <>
            <Spacer />
            <ErrorBox>{createErr}</ErrorBox>
          </>
        )}
      </div>
    </div>
  );
};

export default UpfrontPaymentForm;
