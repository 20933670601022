// @flow

import makeRequest from "../make-request";

export async function getStripeClientSecret(amount: number): Promise<string> {
  const url = `/api/payments/stripe/payment-intent`;
  const data = JSON.stringify({
    amount
  });
  const resp = await makeRequest(url, { method: "POST", body: data });

  return resp.client_secret;
}
