// @flow
import * as React from "react";

import styles from "./List.module.css";

export type ListItem = {|
  id: string,
  icon?: React.Node,
  label: string | React.Node,
  className?: string,
  actions: ((item: ListItem) => React.Node)[]
|};

type Props = {|
  items: ListItem[],
  noborder?: boolean
|};

class List extends React.Component<Props> {
  render() {
    const { items, noborder } = this.props;

    return (
      <div className={styles.list}>
        {items.map(item => (
          <div
            key={item.id}
            className={[
              styles.item,
              item.className,
              noborder ? styles.noBorder : ""
            ]
              .filter(Boolean)
              .join(" ")
              .trim()}
          >
            <div className={styles.labelContainer}>
              {item.icon && <div>{item.icon}</div>}
              <div className={styles.labelContent}>{item.label}</div>
            </div>
            <div className={styles.actions}>
              {item.actions.map((a, i) => (
                <span key={`action-${i}`}>{a(item)}</span>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default List;
