// @flow
import * as React from "react";

import type { Exercise } from "../api/exercises";
import type { ExerciseSet } from "../api/exercise-sets";
import type { TestCase } from "../api/test-cases";
import type { Attempt } from "../api/attempts";
import type { ResultSet } from "../api/attempts";
import type { Stack } from "../api/stacks";
import type { TestCaseTemplate } from "../api/test-case-templates";
import type { Course } from "../api/courses";
import type { Assignment } from "../api/assignments";
import type { Profile } from "../api/profile";
import type { IDE } from "../api/ides";

export type AppDataCache = {|
  session: ?{
    profileID: string
  },
  profiles: {
    [string]: Profile
  },
  assignments: {
    [string]: Assignment
  },
  courses: {
    [string]: Course
  },
  resultSets: {
    [string]: ResultSet
  },
  attempts: {
    [string]: Attempt
  },
  exerciseSets: {
    [string]: ExerciseSet
  },
  exercises: {
    [string]: Exercise
  },
  testCases: {
    [string]: TestCase
  },
  stacks: {
    [string]: Stack
  },
  testCaseTemplates: {
    [string]: TestCaseTemplate
  },
  ides: {
    [string]: IDE
  },
  stackIDsForExercise: {
    [string]: string[]
  }
|};

export type updateAppDataCacheFunc = (data: $Shape<AppDataCache>) => void;

export type AppDataCacheContainer = {|
  cache: AppDataCache,
  updateCache: updateAppDataCacheFunc
|};

export const emptyAppDataCache = (): AppDataCache => ({
  session: null,
  profiles: {},
  courses: {},
  assignments: {},
  resultSets: {},
  attempts: {},
  exerciseSets: {},
  exercises: {},
  testCases: {},
  stacks: {},
  ides: {},
  testCaseTemplates: {},
  stackIDsForExercise: {}
});

const AppDataCacheCtx = React.createContext<AppDataCacheContainer>({
  cache: emptyAppDataCache(),
  updateCache: () => {
    throw Error("[AppDataCacheContainer] updateData is not implemented");
  }
});

export const withAppDataCacheCtx = (Component: React.ComponentType<any>) => {
  return function WrapperComponent(props: {}) {
    return (
      <AppDataCacheCtx.Consumer>
        {({ cache, updateCache }) => (
          <Component {...props} cache={cache} updateCache={updateCache} />
        )}
      </AppDataCacheCtx.Consumer>
    );
  };
};

export default AppDataCacheCtx;
