// @flow
import * as React from "react";
import { Spacer, SpacerLg } from "../../modules/style-guide/spacer";
import { Error, RunningText } from "../../modules/style-guide/text";
import * as CoursesAPI from "../../lib/api/courses";
import type { Course } from "../../lib/api/courses";
import AddressForm from "../../modules/style-guide/address-form";
import type { Address } from "../../modules/style-guide/address-form";
import {
  CardElement,
  CardElement as StripeCardElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import Label from "../../modules/style-guide/label";
import Button from "../../modules/style-guide/button";
import styles from "./CourseEnroll.module.css";
import { getCoursePricePerStudent } from "../../lib/course";
import * as PaymentsAPI from "../../lib/api/payments";

type Props = {
  courseID: string,
  onEnroll: (courseID: string) => void
};

const CourseEnrollPaymentForm = (props: Props) => {
  const stripe = useStripe();
  const stripeElements = useElements();

  const [course, setCourse] = React.useState<?Course>(null);
  const [error, setError] = React.useState<?string>(null);
  const [address, setAddress] = React.useState<Address>({
    name: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipCode: "",
    countryISO2: "US"
  });
  const [isEnrolling, setIsEnrolling] = React.useState(false);

  React.useEffect(() => {
    async function load() {
      const course = await CoursesAPI.fetchCourse(props.courseID);
      setCourse(course);
    }

    load().catch(() => {
      setError("Failed to load course details");
    });
  }, []);

  const isValid = React.useCallback(
    () =>
      stripe &&
      stripeElements &&
      address.name &&
      address.line1 &&
      address.city &&
      address.state &&
      address.zipCode,
    [stripe, stripeElements, address]
  );

  const onSubmit = React.useCallback(
    async () => {
      if (!stripe || !stripeElements || !course) {
        return;
      }

      const amount = getCoursePricePerStudent(course) * 100;

      setIsEnrolling(true);

      try {
        const secret = await PaymentsAPI.getStripeClientSecret(amount);
        const paymentResp = await stripe.confirmCardPayment(secret, {
          payment_method: {
            card: stripeElements.getElement(CardElement),
            billing_details: {
              name: address.name,
              address: {
                line1: address.line1,
                line2: address.line2,
                city: address.city,
                state: address.state,
                postal_code: address.zipCode,
                country: address.countryISO2
              }
            }
          }
        });

        if (paymentResp.error) {
          setError(paymentResp.error.message);
          setIsEnrolling(false);
          return;
        }

        const paymentID = paymentResp.paymentIntent.id;

        await CoursesAPI.enrollInCourse(course.id, paymentID);

        props.onEnroll(course.id);
      } catch (e) {
        console.error(e);
        setError("Failed to enroll. Please contact support.");
        setIsEnrolling(false);
      }
    },
    [stripe, stripeElements, course, address]
  );

  if (!course && !error) {
    return null;
  } else if (!course) {
    return <Error>{error}</Error>;
  }

  return (
    <div>
      <RunningText className={styles.explainer}>
        Your course requires a payment of ${getCoursePricePerStudent(course)} to
        enroll. Enter your credit card details and your billing address to
        continue.
      </RunningText>
      <SpacerLg />

      <Label htmlFor="">Credit Card</Label>
      <div className={styles.cardForm}>
        <StripeCardElement />
      </div>

      <Spacer />

      <AddressForm address={address} onChange={setAddress} />

      <SpacerLg />
      <div className={styles.center}>
        <Button disabled={!isValid() || isEnrolling} onClick={onSubmit}>
          {isEnrolling
            ? `Paying $${getCoursePricePerStudent(course)}..`
            : `Pay $${getCoursePricePerStudent(course)}`}
        </Button>
      </div>

      {error ? (
        <>
          <Spacer />
          <Error>{error}</Error>
        </>
      ) : null}
    </div>
  );
};

export default CourseEnrollPaymentForm;
