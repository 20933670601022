// @flow
import * as React from "react";
import { keyBy } from "lodash";
import queryString from "query-string";
import WSConn from "../../lib/api/websockets";
import AppDataCacheCtx from "../../lib/data-cache";
import LoadingBar from "../../modules/style-guide/loading-bar";
import BaseDataPage from "../base-data";
import AttemptPage from "./attempt-page";

import * as TestCasesAPI from "../../lib/api/test-cases";
import * as ExerciseSetsAPI from "../../lib/api/exercise-sets";
import * as ExercisesAPI from "../../lib/api/exercises";
import * as AttemptsAPI from "../../lib/api/attempts";
import * as IDEsAPI from "../../lib/api/ides";
import * as AssignmentsAPI from "../../lib/api/assignments";

import type { updateAppDataCacheFunc } from "../../lib/data-cache";
import type { ResultSet } from "../../lib/api/attempts";
import getResultSetSimpleStatus from "../../lib/result-set-simple-status";
import type { TestCase } from "../../lib/api/test-cases";

type State = {|
  isSubmittingAttempt: boolean
|};

class AttemptPageContainer extends React.Component<{}, State> {
  ws: WSConn;

  constructor(props: {}, context: any) {
    super(props, context);

    this.ws = new WSConn();

    this.state = {
      isSubmittingAttempt: false
    };
  }

  static renderSkeleton(progress: number, error: ?string) {
    return (
      <div>
        {progress < 100 ? <LoadingBar progress={progress} /> : null}
        {error}
      </div>
    );
  }

  get isReadOnly() {
    let { read_only } = queryString.parse(
      // $FlowFixMe: add defs for react-router
      this.props.location.search
    );
    return read_only && read_only === "true";
  }

  fetchData = async () => {
    // $FlowFixMe: add defs for react-router
    const { history, match, location } = this.props;
    const { ideID, assignmentID } = match.params;
    let { attemptID } = queryString.parse(location.search);

    const [ide, [assignment]] = await Promise.all([
      IDEsAPI.fetchIDE(ideID),
      assignmentID
        ? AssignmentsAPI.fetchAssignmentWithExerciseSets(assignmentID)
        : Promise.resolve([null])
    ]);

    const [exerciseSet, exercises] = await Promise.all([
      ExerciseSetsAPI.fetchExerciseSet(ide.exercise_set_id),
      ExercisesAPI.fetchExercises(ide.exercise_set_id)
    ]);

    const selectedExercise = exercises.find(e => e.id === ide.exercise_id);

    if (!selectedExercise) {
      throw Error("selected exercise is undefined");
    }

    const testCaseSetID = selectedExercise.test_case_set_id;
    const [attempts, testCases] = await Promise.all([
      IDEsAPI.fetchAttempts(ideID),
      TestCasesAPI.fetchTestCases(testCaseSetID)
    ]);

    let resultSets = [];

    attemptID = attemptID || (attempts.length && attempts[0].id);
    if (attemptID) {
      resultSets = await AttemptsAPI.fetchResultSets(attemptID);
      history.replace({
        search: `?${queryString.stringify({
          ...queryString.parse(location.search),
          attemptID: attemptID
        })}`
      });
    }

    return {
      exercises: keyBy(exercises, e => e.id),
      testCases: keyBy(testCases, tc => tc.id),
      attempts: keyBy(attempts, a => a.id),
      resultSets: keyBy(resultSets, rs => rs.id),
      ides: {
        [ideID]: ide
      },
      exerciseSets: {
        [ide.exercise_set_id]: exerciseSet
      },
      assignments: assignment
        ? {
            [assignment.id]: assignment
          }
        : {}
    };
  };

  handleRunTests = (updateCache: updateAppDataCacheFunc) => async () => {
    // $FlowFixMe: add defs for react-router
    const { location, history, match } = this.props;
    const { ideID, assignmentID } = match.params;

    this.setState({ isSubmittingAttempt: true });

    try {
      const attempt = await IDEsAPI.makeAttempt(ideID, assignmentID);
      const resultSets = await AttemptsAPI.fetchResultSets(attempt.id);

      for (let i = 0; i < resultSets.length; i++) {
        const topic = `resultSets/${resultSets[i].id}`;
        await this.ws.subscribe(topic, (rs: ResultSet) => {
          updateCache({
            resultSets: {
              [rs.id]: rs
            }
          });
        });
      }

      updateCache({
        attempts: {
          [attempt.id]: attempt
        },
        resultSets: keyBy(resultSets, rs => rs.id)
      });

      history.replace({
        search: `?${queryString.stringify({
          ...queryString.parse(location.search),
          attemptID: attempt.id,
          view: "my_results"
        })}`
      });
    } finally {
      this.setState({ isSubmittingAttempt: false });
    }
  };

  render() {
    // $FlowFixMe: add defs for react-router
    const { ideID, assignmentID } = this.props.match.params;

    const { isSubmittingAttempt } = this.state;

    return (
      <BaseDataPage
        fetchData={this.fetchData}
        renderSkeleton={AttemptPageContainer.renderSkeleton}
      >
        <AppDataCacheCtx.Consumer>
          {({ cache, updateCache }) => {
            const ide = cache.ides[ideID];
            const exerciseSet = cache.exerciseSets[ide.exercise_set_id];
            const exercise = cache.exercises[ide.exercise_id];
            const testCases: TestCase[] = (Object.values(cache.testCases): any);
            const testCaseIDs: string[] = testCases
              .filter(tc => tc.test_case_set_id === exercise.test_case_set_id)
              .map((tc: TestCase) => tc.id);
            const resultSets: ResultSet[] = (Object.values(
              cache.resultSets
            ): any).filter(
              (rs: ResultSet) => testCaseIDs.indexOf(rs.test_case_id) >= 0
            );
            const isRunningTests =
              resultSets
                .map((rs: ResultSet) => getResultSetSimpleStatus(rs))
                .filter(s => ["QUEUED", "IN_PROGRESS"].indexOf(s) >= 0).length >
              0;

            return (
              <AttemptPage
                readOnly={this.isReadOnly}
                runningTests={isSubmittingAttempt || isRunningTests}
                ide={ide}
                exerciseSet={exerciseSet}
                exercise={exercise}
                runTests={this.handleRunTests(updateCache)}
                assignment={
                  assignmentID ? cache.assignments[assignmentID] : null
                }
              />
            );
          }}
        </AppDataCacheCtx.Consumer>
      </BaseDataPage>
    );
  }
}

export default AttemptPageContainer;
