// @flow
import * as React from "react";
import { keyBy, values } from "lodash";
import AppDataCacheCtx from "../../lib/data-cache";
import BaseDataPage from "../base-data";
import BaseLayoutPage, {
  renderSkeleton,
  PageContent,
  PageAsideContent
} from "../base-layout";
import { H3 } from "../../modules/style-guide/text";
import { SpacerLg, SpacerSm } from "../../modules/style-guide/spacer";
import AssignmentsList from "./assignments-list";
import CreateAssignment from "./create-assignment";

import * as CoursesAPI from "../../lib/api/courses";

import type { Assignment } from "../../lib/api/assignments";
import { getSessionProfile } from "../../lib/session";
import CourseStatusNotification from "../../modules/course-status-notification";
import MaintenanceNotification from "../../modules/maintenance-notification";

type Props = {||};

class CourseOverviewPage extends React.Component<Props> {
  fetchData = async () => {
    // $FlowFixMe: add defs for react-router
    const { match } = this.props;
    const { courseID } = match.params;

    const [course, assignmentsWithExerciseSets] = await Promise.all([
      CoursesAPI.fetchCourse(courseID),
      CoursesAPI.fetchAssignmentsWithExerciseSets(courseID)
    ]);
    const [assignments, exerciseSets] = assignmentsWithExerciseSets;

    return {
      courses: {
        [courseID]: course
      },
      assignments: keyBy(assignments, a => a.id),
      exerciseSets: keyBy(exerciseSets, es => es.id)
    };
  };

  render() {
    // $FlowFixMe: add defs for react-router
    const { match } = this.props;
    const { courseID } = match.params;

    return (
      <BaseDataPage fetchData={this.fetchData} renderSkeleton={renderSkeleton}>
        <AppDataCacheCtx.Consumer>
          {({ cache }) => {
            const allAssignments: Assignment[] = values(cache.assignments);
            const course = cache.courses[courseID];
            const assignments = allAssignments.filter(
              as => as.course_id === course.id
            );
            const currentAssignments = assignments.filter(
              as => !as.is_past_cutoff
            );
            const pastAssignments = assignments.filter(as => as.is_past_cutoff);

            return (
              <BaseLayoutPage>
                <PageContent title={course.name}>
                  <MaintenanceNotification />
                  <CourseStatusNotification courseID={courseID} />

                  {currentAssignments.length ? (
                    <>
                      <H3>Assignments</H3>
                      <SpacerSm />
                      <AssignmentsList assignments={currentAssignments} />
                      <SpacerLg />
                    </>
                  ) : null}

                  {getSessionProfile(cache).role === "STUDENT" &&
                  !assignments.length
                    ? "You don't have any assignments"
                    : null}

                  {getSessionProfile(cache).role === "INSTRUCTOR" ? (
                    <CreateAssignment courseID={courseID} />
                  ) : null}

                  {pastAssignments.length ? (
                    <>
                      <SpacerLg />
                      <H3>Past Assignments</H3>
                      <SpacerSm />
                      <AssignmentsList
                        assignments={pastAssignments.reverse()}
                      />
                    </>
                  ) : null}
                </PageContent>
                <PageAsideContent />
              </BaseLayoutPage>
            );
          }}
        </AppDataCacheCtx.Consumer>
      </BaseDataPage>
    );
  }
}

export default CourseOverviewPage;
