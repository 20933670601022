// @flow
import * as React from "react";
import Card from "../../modules/style-guide/card";
import { Error as ErrorMsg, H5 } from "../../modules/style-guide/text";
import { SpacerSm } from "../../modules/style-guide/spacer";
import styles from "./CourseRoster.module.css";
import Input from "../../modules/style-guide/input";
import Button from "../../modules/style-guide/button";
import AppDataCacheCtx from "../../lib/data-cache";
import { enrollInstructor } from "../../lib/api/courses";

type Props = {|
  courseID: string
|};

const Enroller = (props: Props) => {
  const [instructorEmail, setInstructorEmail] = React.useState("");
  const [isEnrolling, setIsEnrolling] = React.useState(false);
  const [enrollError, setEnrollError] = React.useState(null);
  const { cache, updateCache } = React.useContext(AppDataCacheCtx);

  if (!cache.session) {
    throw new Error("session is undefined");
  }

  const course = cache.courses[props.courseID];

  const onEnrollInstructor = React.useCallback(
    async () => {
      if (isEnrolling) {
        return;
      }

      setIsEnrolling(true);

      try {
        const instructor = await enrollInstructor(
          props.courseID,
          instructorEmail
        );

        setEnrollError(null);
        setInstructorEmail("");

        updateCache({
          courses: {
            [course.id]: {
              instructor_profile_ids: (
                course.instructor_profile_ids || []
              ).concat([instructor.id])
            }
          },
          profiles: {
            [instructor.id]: instructor
          }
        });
      } catch (e) {
        setEnrollError(e);
      } finally {
        setIsEnrolling(false);
      }
    },
    [isEnrolling, instructorEmail]
  );

  return (
    <Card className={styles.enroller}>
      <div className={styles.invite}>
        <H5>Course invite code</H5>
        <SpacerSm />
        <span className={styles.inviteCode}>{props.courseID}</span>
      </div>
      <div className={styles.enroll}>
        <H5>Enroll instructors</H5>
        <SpacerSm />
        <div className={styles.enrollForm}>
          <Input
            placeholder="Registered AutoGradr email"
            value={instructorEmail}
            onChange={setInstructorEmail}
            onEnter={onEnrollInstructor}
          />
          <SpacerSm />
          <Button small onClick={onEnrollInstructor}>
            {isEnrolling ? "Enrolling" : "Enroll"}
          </Button>
        </div>
        {enrollError && (
          <>
            <SpacerSm />
            <ErrorMsg>
              Failed to enroll instructor. Make sure the instructor is already
              registered on AutoGradr using this email.
            </ErrorMsg>
          </>
        )}
      </div>
    </Card>
  );
};

export default Enroller;
