// @flow
import { trimStart } from "lodash";
import makeRequest from "../make-request";

import type { Attempt } from "../attempts";

export type IDE = {|
  id: string,
  user_uuid: string,
  test_case_set_id: string,
  stack_id: string,
  exercise_set_id: string,
  exercise_id: string,
  workspace_id: string,
  assignment_id: ?string
|};

export async function fetchIDE(ideID: string): Promise<IDE> {
  const url = `/api/ides/${ideID}`;

  return makeRequest(url);
}

export async function fetchIDEs(testCaseSetID: string): Promise<IDE[]> {
  const url = `/api/test-case-sets/${testCaseSetID}/ides`;

  return makeRequest(url);
}

export async function createIDE(
  stackID: string,
  testCaseSetID: string,
  assignmentID: ?string
): Promise<IDE> {
  const url = `/api/ides`;
  const data = JSON.stringify({
    stack_id: stackID,
    test_case_set_id: testCaseSetID,
    assignment_id: assignmentID
  });

  return makeRequest(url, { method: "POST", body: data });
}

export async function fetchAttempts(ideID: string): Promise<Attempt[]> {
  const url = `/api/ides/${ideID}/attempts`;

  return makeRequest(url);
}

export async function makeAttempt(
  ideID: string,
  assignmentID: ?string
): Promise<Attempt> {
  const url = `/api/ides/${ideID}/attempts`;
  const data = JSON.stringify({
    assignment_id: assignmentID
  });

  return makeRequest(url, { method: "POST", body: data });
}

export async function deleteFile(ideID: string, filePath: string) {
  const sanitizedFilePath = trimStart(filePath, "/");
  const url = `/api/ides/${ideID}/files/${sanitizedFilePath}`;

  return makeRequest(url, { method: "DELETE" });
}

export async function uploadFile(
  ideID: string,
  filePath: string,
  data: string | Blob
) {
  const sanitizedFilePath = trimStart(filePath, "/");
  const url = `/api/ides/${ideID}/files/${sanitizedFilePath}`;

  return makeRequest(url, { method: "POST", body: data });
}
