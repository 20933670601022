// @flow
import * as React from "react";
import * as CoursesAPI from "../../lib/api/courses";
import { Error, RunningText } from "../../modules/style-guide/text";
import styles from "./CourseEnroll.module.css";
import { Spacer, SpacerLg } from "../../modules/style-guide/spacer";
import Input from "../../modules/style-guide/input";
import Button from "../../modules/style-guide/button";

type Props = {|
  onRequiresPayment: (courseID: string) => void,
  onEnroll: (courseID: string) => void
|};

type State = {|
  courseID: string,

  enrolling: boolean,
  enrollError: ?string
|};

class CourseEnrollForm extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      courseID: "",
      enrolling: false,
      enrollError: null
    };
  }

  handleEnroll = async () => {
    const { courseID } = this.state;

    try {
      this.setState({ enrolling: true, enrollError: null });
      await CoursesAPI.enrollInCourse(courseID);
      this.props.onEnroll(courseID);
    } catch (e) {
      if (e.message === "STUDENT_PAYMENT_REQUIRED") {
        this.props.onRequiresPayment(courseID);
        return;
      }

      this.setState({
        enrolling: false,
        enrollError: "Failed to enroll in course"
      });
    }
  };

  handleCourseIDChange = (courseID: string) => this.setState({ courseID });

  render() {
    const { courseID, enrolling, enrollError } = this.state;
    return (
      <>
        <RunningText className={styles.explainer}>
          Enter the course invite code to get started. If you don't have one,
          ask your instructor to provide you the invite code from the Course
          roster page.
        </RunningText>
        <SpacerLg />
        <Input
          label="Invite code"
          placeholder="ABC123"
          value={courseID}
          onEnter={this.handleEnroll}
          onChange={this.handleCourseIDChange}
        />
        <SpacerLg />
        <Button onClick={this.handleEnroll}>
          {enrolling ? "Enrolling.." : "Enroll"}
        </Button>
        {enrollError ? (
          <>
            <Spacer />
            <Error>{enrollError}</Error>
          </>
        ) : null}
      </>
    );
  }
}

export default CourseEnrollForm;
