// @flow
import * as React from "react";
import {
  FaCheck,
  FaChevronDown,
  FaChevronUp,
  FaCommentDots,
  FaExternalLinkAlt,
  FaLifeRing,
  FaPen,
  FaPlus,
  FaTasks,
  FaTimes,
  FaTrashAlt
} from "react-icons/fa";
import styles from "./Icons.module.css";

type Props = {
  className?: string,
  pro?: boolean
};

export const DeleteIcon = ({ className, ...props }: Props) => (
  <FaTrashAlt
    {...props}
    className={`${styles.delete} ${className ? className : ""}`}
  />
);

export const RightSidebarIcon = (props: Props) => (
  <svg {...props} viewBox="0 0 20 20" width={20} height={20}>
    <path
      d="M16 3a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V5a2 2 0 012-2h12zm-4 1.5H4a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h8v-11z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export const BottomSidebarIcon = (props: Props) => (
  <svg {...props} width={20} height={20} viewBox="0 0 20 20" fill="none">
    <path
      d="M18 15.25c0 .464-.24.91-.67 1.237-.428.329-1.01.513-1.616.513H4.286c-.606 0-1.188-.184-1.617-.513C2.241 16.16 2 15.714 2 15.25V4.75c0-.464.24-.91.67-1.237C3.097 3.184 3.68 3 4.285 3h11.428c.606 0 1.188.184 1.617.513.428.328.669.773.669 1.237v10.5zm-1.714-3.5v-7a.393.393 0 00-.168-.31.67.67 0 00-.404-.128H4.286a.67.67 0 00-.404.129.393.393 0 00-.168.309v7h12.572z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export const ExternalLinkIcon = (props: Props) => (
  <FaExternalLinkAlt {...props} />
);

export const EditIcon = (props: Props) => <FaPen {...props} />;

export const ListIcon = (props: Props) => (
  <FaTasks className={styles.list} {...props} />
);

export const AddIcon = (props: Props) => <FaPlus {...props} />;

export const CheckIcon = ({ pro, ...props }: Props) => (
  <FaCheck className={pro ? styles.pro : ""} {...props} />
);

export const CloseIcon = (props: Props) => <FaTimes {...props} />;

export const HelpIcon = (props: Props) => <FaLifeRing {...props} />;

export const ChatIcon = (props: Props) => <FaCommentDots {...props} />;

export const ChevronDown = (props: Props) => <FaChevronDown {...props} />;

export const ChevronUp = (props: Props) => <FaChevronUp {...props} />;
