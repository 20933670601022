// @flow
import * as React from "react";
import { Redirect } from "react-router-dom";
import { values } from "lodash";
import LoadingBar from "../../modules/style-guide/loading-bar";
import BaseDataPage from "../base-data";
import AppDataCacheCtx from "../../lib/data-cache";

import type { Course } from "../../lib/api/courses";

type Props = {||};

class DashboardPage extends React.Component<Props> {
  static renderSkeleton(progress: number, error: ?string) {
    return (
      <div>
        {progress < 100 ? <LoadingBar progress={progress} /> : null}
        {error}
      </div>
    );
  }

  fetchData = async () => ({});

  render() {
    return (
      <BaseDataPage
        fetchData={this.fetchData}
        renderSkeleton={DashboardPage.renderSkeleton}
      >
        <AppDataCacheCtx.Consumer>
          {({ cache }) => {
            if (!cache.session) {
              throw new Error("session is undefined");
            }
            const { role } = cache.profiles[cache.session.profileID];
            const courses: Course[] = values(cache.courses);

            if (role === "INSTRUCTOR") {
              return <Redirect to="/getting-started" />;
            } else if (!courses.length && role === "STUDENT") {
              return <Redirect to="/courses/enroll" />;
            }

            return <Redirect to={`/courses/${courses[0].id}/assignments`} />;
          }}
        </AppDataCacheCtx.Consumer>
      </BaseDataPage>
    );
  }
}

export default DashboardPage;
