// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import Button from "../../modules/style-guide/button";
import NullStateCard from "../../modules/style-guide/null-state-card";
import GirlOnDesktop from "../../modules/illustrations/girl-on-desktop";

type Props = {|
  courseID: string
|};

class CreateAssignment extends React.Component<Props> {
  render() {
    return (
      <NullStateCard
        backgroundEl={GirlOnDesktop}
        title="Create an assignment"
        content="Assign a question from your existing library to this course. Manage due dates, and plan ahead to make assignments available."
        cta={
          <Button
            el={Link}
            to={`/courses/${this.props.courseID}/assignments/create`}
          >
            Create Assignment
          </Button>
        }
      />
    );
  }
}

export default CreateAssignment;
